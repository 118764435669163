import React, { useState, useMemo, useEffect } from "react"
import { useMyContext, /*useAbilityContext*/ } from "../../store/useMyContext"
import useSWR, { mutate } from "swr"
import uuid from "uuid"
import _ from "lodash"

import { reqOptions, /*a, Can,*/ REQ, store, session, Emitter } from "../../store/psrs"
import { Keyboard, Box } from "grommet"
import { Button, Input, Tooltip, Popconfirm, notification, Icon, Switch, Tag } from "antd"
//import TimeEntriesTable from "./TimeEntriesTable"
//import LeaveEntriesTable from "../ShiftDrawer/LeaveEntriesTable"
//import SickEntriesTable from "../ShiftDrawer/SickEntriesTable"
//import { TagSelect, BusinessUnitSelect, DurationField, ReasonSelect } from "../Forms"
//import {  EquipmentSelect, AssignmentSelect,  } from "../Forms"
//import { PayTypeSelect } from "../Forms"

import { BusinessUnitSelect, ReasonSelect, SubLedgerSelect } from "../Forms"

import { Drawer } from "../Drawer"
import { EmployeeTransfer  } from "../Forms/EmployeeTransfer"


//import  moment from "moment-timezone"


//const jsonShift = require('../../__test__/shiftTemplates/shift.json')
//const jsonEntry = require('../../__test__/shiftTemplates/timeEntry.json')


///--do we even need this?  let shiftUpdates = []
//let jsonShiftUpdates = {} //require('../../__test__/shiftTemplates/shift.json')
//let jsonEntryUpdates = require('../../__test__/shiftTemplates/timeEntry.json')

//jsonShiftUpdates['timeEntries'] = [] //jsonEntryUpdates

const ButtonGroup = Button.Group

export const PicklistDrawer = props => {

  console.log('PicklistDrawer')

  const { listUrl, shiftJson, setNewPicklist, dataRec } = props
  //fix up time entires
  //jsonShiftUpdates.timeEntries.forEach(function(e){ e.shiftId = jsonShiftUpdates.id })

  // pull data into json

  const categoryColor = dataRec ? dataRec.categoryColor : 'aabbcc'
  
  if (shiftJson && shiftJson.shiftJson) { 
    shiftJson.shiftJson.name = shiftJson.name
    shiftJson.shiftJson.description = shiftJson.description
    shiftJson.shiftJson.isActive = shiftJson.isActive
    shiftJson.shiftJson.canEdit = shiftJson.canEdit
    shiftJson.shiftJson.isOT = shiftJson.isOT
  }
  
  //var originalShift= null
 //let requestApproval = false

  const context = useMyContext()
  //const ability = useAbilityContext()
  const { picklistDrawerId } = context
  const id = picklistDrawerId

  const empNum = session.get("CurrentUser").number
  const url = `${store.get("api")}/api/picklist/${picklistDrawerId}?include=employees`

  const [state, setState] = useState({
    picklistId: 0,
    loading: true, 
    currentUserNumber: empNum, 
    buttonsLocked: false,
    name: dataRec && dataRec.name ? dataRec.name : '',
    description: dataRec && dataRec.description ? dataRec.description : '',
    category: dataRec && dataRec.category ? dataRec.category : '',
    categoryColor: dataRec && dataRec.categoryColor ? dataRec.categoryColor : '',
    isActive: dataRec && dataRec.isActive ? dataRec.isActive : false,
    canEdit: dataRec && dataRec.canEdit ? dataRec.canEdit : false,
    employees: dataRec && dataRec.employees ? dataRec.employees : [],
    employeeNumbers: dataRec && dataRec.employees ? dataRec.employees : [],

  })

  //const [priorPayPeriod, setPriorPayPeriod] = useState(false)

  const [divStyle, setDivStyle] = useState({ width: '100%', height: '400px' });


  // eslint-disable-next-line
  //const [notesRequired, setNotesRequired] = useState(false)
  //const [jsonShiftUpdates, setJsonShiftUpdates] = useState( shiftJson.shiftJson ) 
  const [jsonShiftUpdates, setJsonShiftUpdates] = useState( shiftJson && shiftJson.shiftJson ? shiftJson.shiftJson : null ) 

  const fetcher = async url => {
    const options = { ...reqOptions }
    return fetch(url, options)
      .then(res => res.json())
      .catch(err => Emitter.emit("PicklistDrawer", { fn: "fetcher", error: err }))
  }

  const { data, error } = useSWR(url, fetcher, { refreshInterval: 0 })
  
  const picklistData = data && data.data && data.data.data ? data.data.data : null

  const drawerProps = useMemo(() => {
    const opts = {
      visible: "visible",
      open: true,
      className: "ShiftDrawer",
      closable: true,
      mask: true,
      maskClosable: true,
      title: "Picklist Employee Drawer",
      width: 800,
      height: 400,
      zIndex: 4,
      placement: "right",
      destroyOnClose: false,
      onClose: e => {
        context.setPicklistDrawerId(0)
        if (setNewPicklist) setNewPicklist(null)
      },
    }
    return opts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const employees = useMemo(() => { 
    return store.get("Employees").map(r => {      
        const employeeName = `${r.firstName} ${r.lastName}`
        return {
          key: r.number,
          title: r.jobTitle,
          titleSearch: r.jobTitle.toLowerCase(),
          employeeFullName: employeeName,
          description: employeeName.toLowerCase(),
          label: r.fullName,
          role: r.role
        }
    })
  })

  const filterOption = (inputValue, option) => option.description.indexOf(inputValue.toLowerCase()) > -1 || option.titleSearch.indexOf(inputValue.toLowerCase()) > -1


  /* const autoMutate = () => {
    mutate(window.location.href, { ...data })
  } */
  
  useEffect(() => {
    const resizeDiv = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 600) {
            setDivStyle({ width: '50%', height: '200px' });
        } else if (screenWidth >= 600 && screenWidth < 900) {
            setDivStyle({ width: '60%', height: '250px' });
        } else {
            setDivStyle({ width: '100%', height: '600px' });
        }
    };

    // Initial resize
    resizeDiv();

    // Resize div when window size changes
    window.addEventListener('resize', resizeDiv);

    // Cleanup listener on component unmount
    return () => {
        window.removeEventListener('resize', resizeDiv);
    };
}, []);


  useEffect(() => {
    if (!picklistDrawerId) mutate(url, null)
    if (!!picklistDrawerId && data) mutate(url, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picklistDrawerId, context.picklistDrawerId])

  useEffect(() => {
    if ( picklistData && picklistData.employees && picklistData.employees.length > 0 ) {
      const emps = picklistData.employees.map(e => e.number)
      setState({ ...state, employees: emps, employeeNumbers: emps })
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picklistData])
  
  
  if (error) return <Drawer {...drawerProps} title="error" />
  if (!picklistData) return <Drawer {...drawerProps} title="loading" />

/*   if (!jsonShiftUpdates) {
    // close the drawer if open.  if user refreshes, may not have picklist employee data
    context.setPicklistDrawerId(0)
    return <Drawer {...drawerProps} title="loading" />
  } */
  //if (picklistData) return <Drawer {...drawerProps} title={picklistData ? `Employee Picklist: ${picklistData.name}` : "Loading"} />

  if (picklistData) {
  //if (jsonShiftUpdates) {
    const Shift = picklistData //jsonShiftUpdates // data

    const title = picklistData ? `Employee Picklist: ${picklistData.name}` : "Loading"
    //const employeeNumber = session.get("CurrentUser").number
  
    const updateShift = async ({ field, value }) => {
		

      const update = field ? {} : value
      if (field) {
        update[field] = value
      }
      //---do we need this?  shiftUpdates.push(update)

      //jsonShiftUpdates = {...jsonShiftUpdates, ...update}

      //handle the unpaid lunch stuff.....
      if (field === 'paidLunch' && value === false ) addEntry(field)
      if (field === 'paidLunch' && value === true ) removeEntry(field)

      // if click ot add default ot time entry
      if (field === 'isOT' && value === true) {
        jsonShiftUpdates.timeEntries.push({
          id: uuid(),
          shiftId: jsonShiftUpdates.id,
          duration: 0,
          payTypeCode: '200',
          businessUnitCode: jsonShiftUpdates.businessUnitCode,
          subLedgerCode: jsonShiftUpdates.subLedgerCode,
          assignmentId: jsonShiftUpdates.assignmentId,
          })
      } 
      if (field === 'isOT' && value === false) {
         // reset isOT isSick or requestedOff shift flags
         for (let e of jsonShiftUpdates.timeEntries) {
          const payType = store.get('PayTypes').find(p => p.code === e.payTypeCode)
          if (payType.category === 'OVERTIME') {
            jsonShiftUpdates.timeEntries = _.reject(jsonShiftUpdates.timeEntries, function(el) { return el.id === e.id })
          } 
        }
      } 

      //const updated = await REQ(`/api/shifts/${Shift.id}`, "PATCH", update)
     
      //if (updated.isOT || updated.isSick || update.requestedOff) {
     //   requestApproval = true
     // } else {
       // requestApproval = false

        setJsonShiftUpdates({...jsonShiftUpdates, ...update})

        //update fields in Time Entries as well
        jsonShiftUpdates.timeEntries.forEach(function(e){if (e.shiftId === jsonShiftUpdates.id) e[field] = value })



        setState({...state})

     // }
     // mutate(url, updated)

    }

    const addEntry = async (field = null) => {
      // const updatedShift = await REQ(`/api/time_entries/create`, "POST", Shift).catch(err =>
      //   Emitter.emit("TimeEntriesTable.js", { fn: "addEntry", error: err }),
      // )
     
       // json needs to be an array.... 
       const timeEntries = jsonShiftUpdates.timeEntries
       timeEntries.push({
          id: uuid(),
          shiftId: jsonShiftUpdates.id,
          duration: field==='paidLunch'?  0.5 : 0,
          payTypeCode: field === 'paidLunch' ? '380' : '1',
          businessUnitCode: jsonShiftUpdates.businessUnitCode,
          subLedgerCode: jsonShiftUpdates.subLedgerCode,
          assignmentId: jsonShiftUpdates.assignmentId,
          })
        
        jsonShiftUpdates.timeEntries=timeEntries
        setState({...state})

        
       //mutate(url)
     }
   
/*     const updateEntry = async ({ field, value, id, payTypeCategory }) => {
      const update = field ? {} : value
      if (field) update[field] = value
      //console.log("updated timeEntry", update)
     // const updatedShift = await REQ(`/api/time_entries/${id}`, "PATCH", update).catch(err =>
     //   Emitter.emit("TimeEntriesTable.js", { fn: "updateEntry", error: err }),
     // )
  
      //timeEntryJSON = {...timeEntryJSON, ...update}
        if (field === 'payTypeCode') {
          const payType = store.get('PayTypes').find(p => p.code === value)

          if (payType && payType.category) {
            jsonShiftUpdates.isOT = payType.category.toUpperCase() === 'OVERTIME' ? true : false
            jsonShiftUpdates.isSick = payType.category.toUpperCase() === 'SICK' ? true : false
            jsonShiftUpdates.requestedOff = payType.category.toUpperCase() === 'LEAVE' ? true : false

            jsonShiftUpdates.isDive = payType.rename === '' ? payType.name.toUpperCase().includes('DIVE') ? true 
               : payType.rename.toUpperCase().includes('DIVE') ? true : false 
               : payType.rename.toUpperCase().includes('DIVE') ? true : false 

               jsonShiftUpdates.isFieldTraining = payType.rename === '' ? payType.name.toUpperCase().includes('FTO') ? true 
               : payType.rename.toUpperCase().includes('FTO') ? true : false 
               : payType.rename.toUpperCase().includes('FTO') ? true : false 
          }
          
        } 

        if (field === 'payTypeCode' && payTypeCategory !== 'alternate') {
          jsonShiftUpdates.payTypeCode = value
        }
        if (field === 'payTypeCode' && payTypeCategory === 'alternate') {
          jsonShiftUpdates.payTypeCodeAlt = value
        }

        if (field === 'duration') {
          jsonShiftUpdates.duration = value
          jsonShiftUpdates.durationOg = value
          jsonShiftUpdates.durationOt = value
        }
        

        //const entry = jsonShiftUpdates.timeEntries.find(a => a.id === id)
        //const _entry = {...entry, ...update}
  
        jsonShiftUpdates.timeEntries.forEach(function(e){if (e.id === id) e[field] = value })
  

        setState({...state})
    
      //console.log("updated timeEntry", updatedShift)
      //mutate(url, updatedShift)
    } */

    const removeEntry = async (id ) => {
      //  const updatedShift = await REQ(`/api/time_entries/${id}`, "DELETE").catch(err =>
      //    Emitter.emit("TimeEntriesTable.js", { fn: "removeEntry", error: err }),
      //  )
    
        //jsonShiftUpdates.timeEntries.forEach(function(e){if (e.id === id) e[field] = value })

        if (id === 'paidLunch') {
          jsonShiftUpdates.timeEntries = _.reject(jsonShiftUpdates.timeEntries, function(el) { return el.payTypeCode === '380' })

        } else {
          jsonShiftUpdates.timeEntries = _.reject(jsonShiftUpdates.timeEntries, function(el) { return el.id === id })

        }


        // reset isOT isSick or requestedOff shift flags
        for (let e of jsonShiftUpdates.timeEntries) {
          const payType = store.get('PayTypes').find(p => p.code === e.payTypeCode)

          jsonShiftUpdates.isOT = payType.category.toUpperCase() === 'OVERTIME' ? true : false
          jsonShiftUpdates.isSick = payType.category.toUpperCase() === 'SICK' ? true : false
          jsonShiftUpdates.requestedOff = payType.category.toUpperCase() === 'LEAVE' ? true : false

        }

        setState({...state})
      // remvoe from array of entries.... 
    
        //setShiftLocked(true)
        //if (shiftLocked) setShowMore(false)
    
       // mutate(url, updatedShift)
      }

    const confirmChangesClose = async (props) => {
      await confirmChanges(props)
      context.setPicklistDrawerId(0)
    }
    const confirmChanges = async (props) => {

      const picklistId = context.picklistDrawerId

      const { 
        name, 
        description, 
        category, 
        categoryColor, 
        isActive, 
        canEdit,
        employees } = state

      const updatePicklist = {
        id: picklistId,
        name: name,
        description: description,
        category: category,
        categoryColor: categoryColor,
        //calculation: calculation,
        //resetPicklist: resetPicklist,
        isActive: isActive,
        canEdit: canEdit,
        employees: employees,
        employeeCount: employees.length

      }
      //if (picklistId && (Array.isArray(employees) && employees.length > 0)) {
        const updatedlist = await REQ(`/api/picklist/update/${picklistId}`, "PUT", updatePicklist)

        // need to update count


        if (listUrl) mutate(listUrl)

        const statusCode = updatedlist.statusCode

        notification.open({
          message: statusCode === 200 ? "Changes Saved" : "Error Saving",
          description: statusCode === 200 ?  `Picklist: ${Shift.name} Saved` : `Picklist ${updatedlist.data.status} Error` ,
          placement: "topRight",
          duration: 3,
        })
      //} else {
       // notification.open({
       //   message: "Error Saving Picklist",
       //   description: `Picklist: ${Shift.name} Error NOT Saved` ,
       //   placement: "topRight",
       //   duration: 3,
        //})
      //}
    }
    
    const confirmDelete = async x => {
      const DeletedShift = await REQ(`/api/picklist/softDelete/${picklistDrawerId}`, "PUT")
      console.log("DeletedShift", DeletedShift)
      context.setPicklistDrawerId(0)
    }
    const cancelDelete = x => {
      console.log("cancelDelete", x)
    }
    
    const handleEmployeeChange = employeeNumbers => {
      setState({ ...state, employeeNumbers, employees: employeeNumbers, supervisorNumbers: [] })
    }
    
    const selectProps = {
      pk: "id",
      record: Shift,
      storeKey: "shifts",
      handleChange: updateShift,
      id: Shift ? Shift.id : uuid(),
    }

    const boxProps = {
      // align: 'center',
      // alignContent: 'stretch',
      // basis: "auto",
      className: "Box",
      direction: "row",
      // fill: false,
      // flex: 'grow',
      gap: "1.5rem",
      // justify: 'stretch',
      pad: "small",
    }

    // const canEditShift = ability.can("edit", a("Shift", Shift))
    //const cantEditShift = ability.cannot("edit", a("Shift", Shift))
    //const cantEditEmployee = !ability.can("edit:shift:Employee")
    //const cantEditSupervisor = ability.cannot("edit:shift:Supervisor")
    //const cantEditGroups = ability.cannot("edit:shift:Groups")
    //const cantEditAssignment = ability.cannot("edit:shift:Assignment")
    //const cantEditBusinessUnit = ability.cannot("edit:shift:BusinessUnit")
    //const cantEditSubLedger = ability.cannot("edit:shift:SubLedger")
    //const cantEditLunch = ability.cannot("edit:shift:paidLunch")

    /*
    let evidenceShift = Shift.payTypeCode === '250' || Shift.payTypeCode === '251' ? true : false
    if ('timeEntries' in Shift) {
      for (let t of Shift.timeEntries) {
        evidenceShift = t.payTypeCode === '250' || t.payTypeCode === '251' ? true : false
      }
    }
    */

    const leftTableColumns = [
      {
          dataIndex: 'label',
          title: 'Name',
      },
      {
          dataIndex: 'title',
          title: 'Title',
          render: tag => <Tag>{tag}</Tag>,
      }
    ]
    const rightTableColumns = [
      {
          dataIndex: 'label',
          title: 'Name',
      },
      {
          dataIndex: 'title',
          title: 'Title',
          render: tag => <Tag>{tag}</Tag>,
      }
    ]

  
    return (
      <Drawer {...drawerProps} title={title} >
        <Keyboard
          target="document"
          onEsc={() => {
            //console.log("ESCAPE")
            context.setPicklistDrawerId(0)
          }}
          onClose={() => {
            //console.log("CLOSE")
            context.setPicklistDrawerId(0)
          }}
        >
          {picklistData && (
            <div className="ShiftDrawerForm">
              <Box {...boxProps}>
                <div className="flex1">
                  <h4>Picklist Name</h4>
                  <Input
                    defaultValue={Shift.name}
                    placeholder="required"
                    onBlur={e => updateShift({ field: "name", value: e.target.value })}
                    disabled={false}
                  />
                </div>
                <div className="flex1">
                  <h4>Picklist Category</h4>
                    <div>
                      <Input
                      style={{width: '90%'}}
                      defaultValue={Shift.category}
                      placeholder="required"
                      onBlur={e => updateShift({ field: "category", value: e.target.value })}
                      disabled={false}
                    />
                    <div className="box-shift-drawer" style={{backgroundColor: categoryColor, overflowX: 'scroll !important'}} />
                  </div>
                </div>
                <div>
                  <h4>Active</h4>
                  <Switch
                    disabled={false}
                    checked={Shift.isActive}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "isActive", value: e })
                    }}
                  />
                </div>
                <div>
                  <h4>Allow User Edits</h4>
                  <Switch
                    disabled={false}
                    checked={Shift.canEdit}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "canEdit", value: e })
                    }}
                  />
                </div>
                {/*
                <div className="employeeSelect flex1">
                  <h4>Employee</h4>
                  <EmployeeSelect employee={Shift.employee} field="employeeNumber" {...selectProps} disabled={cantEditEmployee} />
                </div>
                <div className="supervisorSelect flex1">
                  <h4>Supervisor</h4>
                  <SupervisorSelect
                    supervisor={Shift.supervisor1}
                    field="superNumber1"
                    {...selectProps}
                    disabled={cantEditSupervisor}
                  />
                </div>
                */}
                
              </Box>
              
              <Box {...boxProps}>
                {/* REASONS */}
                {/* <Can I="see:shift:reasons" this={Shift}> */}
                <div className="flex1">
                  <h4>{Shift.isOT && !Shift.scheduledOT ? <b>Reason*</b> : 'Reason'}</h4>
                  <ReasonSelect  value={Shift.reasonId} field="reasonId" {...selectProps} disabled={true} />
                </div>
                {/* </Can> */}

                {/* BUSINESS UNIT */}
                <div className="businessUnitSelect flex1">
                  <h4>Business Unit Override</h4>
                  <BusinessUnitSelect field="costCenterChargeOutCode" {...selectProps} disabled={true } />
                </div>

                {/* SUBLEDGER */}
                <div className="subLedgerSelect flex1">
                  <h4>Sub Ledger</h4>
                  <SubLedgerSelect field="subLedgerCode" {...selectProps} disabled={true } />
                </div>
              </Box>
              
              <Box {...boxProps}>
              
                 {/* EMPLOYEE PICKLISTS */}
                 <div className="flex1">
                    <h4>Employees</h4>
                   {/*  <Transfer
                      titles={["Available", "In Picklist"]}
                      dataSource={_.sortBy(_.filter(employees, e => e.role !== "disabled"),['label'])}
                      showSearch
                      filterOption={filterOption}
                      targetKeys={state.employeeNumbers}
                      onChange={handleEmployeeChange}
                      render={item => item.title}
                      locale={{ itemUnit: "", itemsUnit: "" }}
                      listStyle={{
                        width: divStyle.width,
                        height: divStyle.height,
                      }}
                    /> */}
                    <EmployeeTransfer 
                      disabled = { false }
                      titles ={ ["Available", "In Picklist"] }
                      dataSource = { _.sortBy(_.filter(employees, e => e.role !== "disabled"),['label']) }
                      showSearch = { true } 
                      filterOption = { filterOption }
                      targetKeys = { state.employees || [] }
                      onChange = { handleEmployeeChange }
                      render = {item => item.title}
                      locale ={{ itemUnit: "", itemsUnit: "" }}
                      listStyle = {{
                        width: divStyle.width,
                        height: divStyle.height,
                      }}
                  
                      leftTableColumns = { leftTableColumns }
                      rightTableColumns = { rightTableColumns }
                    
                    />
                 </div>
               
               </Box>
              
              <section className="shiftActions">
                {!Shift.available && (
                  <ButtonGroup>
                    <div className="FlexBlock" >
                    {/*SAVE AND CLOSE */}
                    <Tooltip title={'Save Picklist and Close'}>
                        <Popconfirm
                          disabled={false}
                          title='Save Picklist and Close'
                          onConfirm={confirmChangesClose}
                          okText={'Save Picklist and Close'}
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={false}
                            icon='close'
                            size="large"
                            //style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>
                    {/*SAVE*/}
                    <Tooltip title={'Save Picklist'}>
                        <Popconfirm
                          disabled={false}
                          title='Save Picklist'
                          onConfirm={confirmChanges}
                          okText={'Save Picklist'}
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={false}
                            icon='save'
                            size="large"
                            //style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>
                      {/*DELETE*/}
                      <Tooltip title="Delete Picklist">
                        <Popconfirm
                          title="Are you sure you want to delete this Shift Template?"
                          onConfirm={confirmDelete}
                          onCancel={cancelDelete}
                          okText="Yes"
                          cancelText="No"
                          disabled={false}
                        >
                          <Button 
                          type="default" size="large" icon="delete" disabled={false}/>
                        </Popconfirm>
                      </Tooltip>
                      </div>
                  </ButtonGroup>
                )}
                {!!Shift.originalShiftId && (
                  <Button
                    disabled={false}
                    className="link"
                    type="primary"
                    ghost={true}
                    onClick={() => context.setPicklistDrawerId(Shift.originalShiftId)}
                  >
                    Original Shift
                  </Button>
                )}
                {!!Shift.sickShiftId && (
                  <Button
                    disabled={false}
                    className="link"
                    type="primary"
                    ghost={true}
                    onClick={() => {
                      context.setPicklistDrawerId(Shift.sickShiftId)
                    }}
                  >
                    Sick Shift
                  </Button>
                )}
              </section>
            </div>
            
          )}
        </Keyboard>
      </Drawer>
    )
  }
}

export default PicklistDrawer
