import React, { useState, useEffect, /*useMemo*/ } from "react"
import useMyContext from "../store/useMyContext"
import * as Model from "./RecordsViewer/Model"
import { DeleteModal } from "./RecordsViewer/Shifts/DeleteModal"
import { RestoreModal } from "./RecordsViewer/Shifts/RestoreModal"
import { RestoreTimeEntryModal } from "./RecordsViewer/TimeEntries/RestoreTimeEntryModal"
import { StatsModal } from "./RecordsViewer/TimeEntries/StatsModal"
import { ShiftDrawer } from "./ShiftDrawer"
import { NewShiftDrawer } from "./NewShiftDrawer"
import { store, REQ, Emitter, Can, getRole } from "../store/psrs"
import ReactTable from "react-table"
//import moment from "moment"
import moment from "moment-timezone"
import _ from "lodash"
import clean from "clean-deep"
import { /*Badge,*/ Select, Layout, Tabs, Button, Tag, /*Menu, Dropdown,*/ Progress, Pagination } from "antd"
import { renderSelectDropdown, filterOption } from "./Forms/_selectDefaults"
import { CheckBox } from "./Forms"
import { FilterOutlined, EditOutlined } from '@ant-design/icons'
//import uniqid from "uniqid"

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

const { Content, Sider } = Layout
const { Option } = Select
const { ShiftFilters, ShiftColumns, ShiftFields } = Model
const { TimeEntryFilters, TimeEntryColumns, TimeEntryFields } = Model
const { TabPane } = Tabs

export const RecordsViewer = props => {
  const context = useMyContext()

  const ss = store.session.get("RecordsViewer")

  if (ss && ss.filters.range) ss.filters.range = ss.filters.range.map(a => moment(a))
  if (ss && ss.filters.date) ss.filters.date = moment(ss.filters.date)

  // initial setup to filter by supervisor
   
  const me = store.session.get("CurrentUser")
  let mySupervisor = ''
  if (me.role === 'supervisor') {
    mySupervisor = me.number
  } 

  if (me.role === 'admin') {
    if (me.subordinates.length > 0 ) {
      mySupervisor = me.number
    } else {
      mySupervisor = me.supervisorNumber
    }
  }
  


  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [sup1, setSup1] = useState(true)
  const [sup2, setSup2] = useState(false)
  const [sup3, setSup3] = useState(false)
  
  // renamed to xtotal because was not happ with new pagination comppmemt
  const [xtotal, setTotal] = useState(0)
  const [update, setUpdate] = useState(ss && ss.update ? ss.update : {})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [restoreOpen, setRestoreOpen] = useState(false)
  const [statsOpen, setStatsOpen] = useState(false)
  const [restoreTimeEntryOpen, setRestoreTimeEntryOpen] = useState(false)

  const [recsUpdated, setRecsUpdated] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  
  //const [firstDisabled, setFirstDisabled] = useState(true)
  //const [prevDisabled, setPrevDisabled] = useState(true)
  //const [nextDisabled, setNextDisabled] = useState(false)
  //const [lastDisabled, setLastDisabled] = useState(false)

  const RecordsViewerExcluded = store.session.get("RecordsViewerExcluded") || []
  const ShiftRecsExcluded = store.session.get("ShiftRecsExcluded") || []
  const TimeEntryRecsExcluded = store.session.get("TimeEntryRecsExcluded") || []
  const SelectAll = store.session.get("SelectAll") || true
  const Indeterminate = store.session.get("Indterminate") || false
  const SelectAllShifts = store.session.get("SelectAllShifts") || false
  const IndeterminateShift = store.session.get("IndterminateShift") || false
  const SelectAllTimeEntry = store.session.get("SelectAllTimeEntry") || true
  const IndeterminateTimeEntry = store.session.get("IndterminateTimeEntry") || false
  const RefreshAnimate = store.session.get("refreshAnimate") || false
  const DownloadAnimate = store.session.get("downloadAnimate") || false

  const [excluded, setExcluded] = useState(new Set(RecordsViewerExcluded))
  const [excludedShifts, setExcludedShifts] = useState(new Set(ShiftRecsExcluded))
  const [excludedTimeEntries, setExcludedTimeEntries] = useState(new Set(TimeEntryRecsExcluded))
  const [selectAll, setSelectAll] = useState(SelectAll)
  const [indeterminate, setIndeterminate] = useState(Indeterminate)
  const [selectAllShifts, setSelectAllShifts] = useState(SelectAllShifts)
  const [indeterminateShift, setIndeterminateShift] = useState(IndeterminateShift)
  const [selectAllTimeEntry, setSelectAllTimeEntry] = useState(SelectAllTimeEntry)
  const [indeterminateTimeEntry, setIndeterminateTimeEntry] = useState(IndeterminateTimeEntry)
  const [refreshAnimate, setRefreshAnimate] = useState(RefreshAnimate)
  const [downloadAnimate, setDownloadAnimate] = useState(DownloadAnimate)
  const [recsExported, setRecsExported] = useState(0)

  const currentPayPeriod = store.get("PayPeriodToday")
  const { start, end } = currentPayPeriod

  const [filters, setFilters] = useState(ss ? ss.filters : { range: [moment(start), moment(end)] })

  const [stepDisplay, setStepDisplay] = useState('')

  const [paranoid, setParanoid] = useState(true)

  // --- future const [showAllColumns, setShowAllColumns] = useState(false)

  const [state, setState] = useState({
    mounted: false,
    model: ss ? ss.state.model : "timeEntry",
    tableName: ss ? ss.state.tableName : "timeentries",
    storeKey: ss ? ss.state.storeKey : "timeEntries",
    storeKeyArray: ss ? ss.state.storeKeyArray : "TimeEntries",
    modelTitle: ss ? ss.state.modelTitle : "Time Entries",
    modeTitle: ss && ss.modelTitle ? ss.modeTitle : "Filtering",
    tab: ss ? ss.state.tab : "filter",
    page: ss ? ss.state.page : 0,
    totalPages: ss ? ss.state.totalPages : 1,
    records: [],
		sendNotices: true,
    defaultPageSize: ss ? ss.state.defaultPageSize : 50,
  })

  const isShifts = state.model === "shift"
  const isTimeEntries = state.model === "timeEntry"

  const rangeQuery = () => {    
    if (!!filters.date) {
      // setFilters({ ...filters, range: null })
      const _start = moment(filters.date).utc().format("YYYY-MM-DD")
      const _end = moment(filters.date).utc().format("YYYY-MM-DD")
      //original -- const sql = `"start" >= '${_start}' AND "end" <= '${_end}'`
      
      //const sql = `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${_start}' AND '${_end}'`
      //const sql = `"start" BETWEEN '${_start}' AND '${_end}'`

      let sql=''
      if (state.model === 'timeEntry') {
        sql =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${_start}' AND '${_end}')`
      } else {
        sql = `"start"  BETWEEN '${_start}' AND '${_end}'`
      }

      return sql
    } else if (!!filters.range) {
      // setFilters({ ...filters, date: null })
      const startQ = moment(filters.range[0].format("YYYY-MM-DD") + ' 00:00:00').utc().format()
      let endQ
      // if no end date, assume one day selected and set end to start. 
      if (!!filters.range[1]) {
        endQ = moment(filters.range[1].format("YYYY-MM-DD") + ' 24:00:00').utc().format()
      }
      else {
        endQ = moment(filters.range[0].utc().format("YYYY-MM-DD") + ' 24:00:00').utc().format()
      }

      let sql=''
      if (state.model === 'timeEntry') {
        sql =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${startQ}' AND '${endQ}')`
      } else {
        sql = `"start" BETWEEN '${startQ}' AND '${endQ}'`
      }

      return sql

      //return `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${startQ}' AND '${endQ}'`
      //return `"start" BETWEEN '${startQ}' AND '${endQ}'`

    } else {
      setState({ currentPayPeriod: true, allYear: false })
      const range = store.get("PayPeriodToday").range
      const current = range.map(d => moment(d.value))
      const YYYMMDD = current.map(c => c.format("YYYY-MM-DD"))
      setFilters({ ...filters, range: current, date: null })

      const utcStartQ = moment(YYYMMDD[0]).utc().format();
      const utcEndQ = moment(YYYMMDD[1]).utc().format();

      //return `"start" BETWEEN '${YYYMMDD[0]}' AND '${YYYMMDD[1]}'`
      return `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      //-//const sql = `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
    }
  }

  const generalFilters = (query) => {
    //console.log('generalFilters....')
    const ss = store.session.get("RecordsViewer")
    const rangeFilter = { range: rangeQuery()}
    let timeEntryFilters = {}
    let shiftFilters = {}
    let commonFilters = {}
    let tagIdFilters=''

    if (ss && ss.filters) {

      // handle the tags
      if (ss.filters.tagIds !== null) {
        if (ss.filters.tagIds && ss.filters.tagIds.length > 0 ) {
          for (let tagId of ss.filters.tagIds) {
            tagIdFilters += tagId
          }
        }
      }

      let _tagSql =  `"id" IN (SELECT "shiftId" FROM "tagShifts" WHERE "tagShifts"."tagId" = ANY('{${tagIdFilters}}'::text[]))` 
      if (state.model === 'timeEntry') _tagSql = `"shiftId" IN (SELECT "shiftId" FROM "tagShifts" WHERE "tagShifts"."tagId" = ANY('{${tagIdFilters}}'::text[]))` 


      // unedited
      let _unedited =null
      if (typeof ss.filters.unedited !== 'undefined' ) {
        if (ss.filters.unedited) _unedited = `"updatedAt" = "createdAt"`
        if (!ss.filters.unedited) _unedited = `"updatedAt" != "createdAt"`
      }
     
      // handle supervisor levels
      // assume superNumber1
      let  supervisorSQL = ss.filters.superNumber ? `("superNumber1"='${ss.filters.superNumber}')` : `("superNumber1"='${mySupervisor}')`

      // superNumber1 & superNumber2
      if (sup1 && sup2 && !sup3) {
        supervisorSQL = ss.filters.superNumber ? `("superNumber1"='${ss.filters.superNumber}' OR "superNumber2"='${ss.filters.superNumber}')` : `("superNumber1"='${mySupervisor}')`
      }
      // superNumber1 & superNumber3
      if (sup1 && !sup2 && sup3) {
        supervisorSQL = ss.filters.superNumber ? `("superNumber1"='${ss.filters.superNumber}' OR "superNumber3"='${ss.filters.superNumber}')` : `("superNumber1"='${mySupervisor}')`
      }
      // superNumber2 & superNumber3
      if (!sup1 && sup2 && sup3) {
        supervisorSQL = ss.filters.superNumber ? `("superNumber2"='${ss.filters.superNumber}')` : `("superNumber1"='${mySupervisor}')`
      }
      // all supervisors
      if (sup1 && sup2 && sup3) {
        supervisorSQL = ss.filters.superNumber ? `("superNumber1"='${ss.filters.superNumber}' OR "superNumber2"='${ss.filters.superNumber}' OR "superNumber3"='${ss.filters.superNumber}')` : `("superNumber1"='${mySupervisor}')`

      }


      commonFilters = {
        employeeNumber: ss.filters.employeeNumber ? `"employeeNumber" IS NOT NULL AND "employeeNumber" = '${ss.filters.employeeNumber}'` : null,
        superNumber: supervisorSQL,

        //-new superNumber: ss.filters.superNumber ? `("superNumber1"='${ss.filters.superNumber}' OR "superNumber2"='${ss.filters.superNumber}' OR "superNumber3"='${ss.filters.superNumber}')` : `("superNumber1"='${mySupervisor}')`,
        //-orig superNumber: ss.filters.superNumber ? `("superNumber1"='${ss.filters.superNumber}' OR "superNumber2"='${ss.filters.superNumber}' OR "superNumber3"='${ss.filters.superNumber}')` : null,
        assignmentId: ss.filters.assignmentId ? `"assignmentId" IS NOT NULL AND "assignmentId" = '${ss.filters.assignmentId}'` : null,
        businessUnitCode: ss.filters.businessUnitCode ? `"businessUnitCode" IS NOT NULL AND "businessUnitCode" = '${ss.filters.businessUnitCode}'` : null,
        costCenterChargeOutCode: ss.filters.costCenterChargeOutCode ? `"costCenterChargeOutCode" is NOT NULL AND "costCenterChargeOutCode" = '${ss.filters.costCenterChargeOutCode}'` : null,
        subLedgerCode: ss.filters.subLedgerCode ? `"subLedgerCode" IS NOT NULL AND "subLedgerCode" = '${ss.filters.subLedgerCode}'` : null,
        needsApproved: ss.filters.needsApproved ? `"needsApproved" is ${ss.filters.needsApproved}` : null,
        approved: ss.filters.approved ? `"approved" is ${ss.filters.approved}` : null,
        rejected: ss.filters.rejected ? `"rejected" is ${ss.filters.rejected}` : null,
        //unedited: ss.filters.unedited ? `"updatedAt" = "createdAt"` : null,
        unedited: ss.filters.unedited ? _unedited: null,
        isFieldTraining: ss.filters.isFieldTraining ? `"isFieldTraining" is ${ss.filters.isFieldTraining}` : null,
        isDive: ss.filters.isDive ? `"isDive" is ${ss.filters.isDive}` : null,
        paidLunch: ss.filters.paidLunch ? `"paidLunch" is ${ss.filters.paidLunch}` : null,
        isOT: ss.filters.isOT ? `"isOT" is ${ss.filters.isOT}` : null,
        notes: ss.filters.notes ? `"notes" IS NOT NULL AND "notes" ILIKE '%${ss.filters.notes}%'` : null,
        //tagIds
        tagIds: ss.filters.tagIds && ss.filters.tagIds.length >0 ? _tagSql : null,
        //" \"shiftId\" IN (SELECT \"shiftId\" FROM \"tagShifts\" WHERE \"tagShifts\".\"tagId\" = ANY('{KcgbzgIxh}'::text[]))"
        //" \"shiftId\" IN (SELECT \"shiftId\" FROM \"tagShifts\" WHERE \"tagShifts\".\"tagId\" = ANY('{KcgbzgIxh,ICVVoZral}'::text[]))",
      }

       // eslint-disable-next-line react-hooks/exhaustive-deps
      //let sync = null
      //if (typeof ss.filters.status !== 'undefined' ) {
      //  if (ss.filters.status === 'synced') sync = true
      //  if (!ss.filters.status === 'sync' ) sync = false
      //}

      timeEntryFilters = {
        payTypeCode: ss.filters.payTypeCode ? `"payTypeCode" IS NOT NULL AND "payTypeCode" = '${ss.filters.payTypeCode}'` : null,
        submit: ss.filters.submit ? `"submit" is ${ss.filters.submit}` : null,
        process: ss.filters.process ? `"process" is ${ss.filters.process}` : null,
        //-status: sync !== null ? sync || !sync ?  `"status" = '${ss.filters.status}'` : null : null,
        locked: ss.filters.locked ? `"locked" is true` : null,
        deletedAt: ss.filters.deletedAt ? `"deletedAt" IS NOT NULL` : null,
        status: typeof ss.filters.sync === 'undefined' ? null : ss.filters.sync ? `"status" = 'synced'` : `"status" = 'sync'`,
      }
  
        let _unassignedEmployee = null
        if (typeof ss.filters.unassignedEmployee !== 'undefined') {
          if (ss.filters.unassignedEmployee) {
            _unassignedEmployee = `"employeeNumber" is null`
          } 
          if (!ss.filters.unassignedEmployee) {
            _unassignedEmployee = `"employeeNumber" is not null`
          } 
        }
        
  
      shiftFilters = {
        unassignedEmployee: _unassignedEmployee,
        isSick: ss.filters.isSick ? `"isSick" = '${ss.filters.isSick}'` : null,
        requestedOff: ss.filters.requestedOff ? `"requestedOff" = '${ss.filters.requestedOff}'` : null,
        imbalanced: ss.filters.imbalanced ? `"imbalanced" = '${ss.filters.imbalanced}'` : null,
        isCollision: ss.filters.isCollision ? `"isCollision" = '${ss.filters.isCollision}'` : null,
        available: ss.filters.available ? `"available" = '${ss.filters.available}'` : null,
        caseNumber: ss.filters.caseNumber ? `"caseNumber" = '${ss.filters.caseNumber}'` : null,
        locked: ss.filters.locked ? `"locked" is true` : null,
        deletedAt: ss.filters.deletedAt ? `"deletedAt" IS NOT NULL` : null,  
  
      }
    }

    let updatedQuery = {...rangeFilter, ...commonFilters}  
    if (state.model === 'shift') updatedQuery = {...updatedQuery, ...shiftFilters}
    if (state.model === 'timeEntry') updatedQuery = {...updatedQuery, ...timeEntryFilters}

    updatedQuery = clean(updatedQuery)
    return updatedQuery

  }

  //-//const [query, setQuery] = useState(ss ? ss.query : { range: rangeQuery() })
  //- old --//const [query, setQuery] = useState( { range: rangeQuery() })
  const [query, setQuery] = useState( generalFilters() )

  //console.log('this is it -> ', generalFilters(query)) 

  // set initial supervisor
   if (!ss ) {
    if ( !filters.superNumber ) {
      //const initSQL = `("superNumber1"='${mySupervisor}')`
      //const initSQL = `("superNumber1"='${mySupervisor}' OR "superNumber2" = '${mySupervisor}' OR "superNumber2" = '${mySupervisor}')`
      const initSQL = `("superNumber1"='${mySupervisor}')`


      setFilters({...filters, superNumber: mySupervisor})
      setQuery({...query, superNumber: initSQL})
    }
  } else if (ss && ss.query.superNumber) {
    console.log('do something here?  is superNumber correct?')
    if (!filters.superNumber && (ss && !ss.filters.superNumber)) setFilters({...filters, superNumber: mySupervisor})
  }

  const commonFilters = ["range", "employeeNumber", "superNumber1","superNumber", "tagIds", "businessUnitCode"]
  const commonQueries = ["employeeNumber", "superNumber1","superNumber", "tagIds", "businessUnitCode"]

  /*
  const modelChange2 = value => {
    const modelTitle = value === "shift" ? "Shifts" : "Time Entries"
    const storeKey = value === "shift" ? "shifts" : "timeEntries"
    const storeKeyArray = value === "shift" ? "Shifts" : "TimeEntries"
    const tableName = value === "shift" ? "shifts" : "timeentries"
    setState({ ...state, model: value, modelTitle, storeKey, storeKeyArray, tableName })
    const baseFilters = _.pick(filters, commonFilters)
    setFilters({ ...baseFilters })
    const baseQuery = _.pick(query, commonQueries)

    console.log('**** blw rangeQuery -> ', rangeQuery())

    const newQuery = { ...baseQuery, range: rangeQuery() }

    console.log('**** blw newQuery -> ', newQuery)

    //setQuery(clean(newQuery))
    setQuery(newQuery)
    totalCountQuery()
  }
  */

  const modelChange = value => {
    const modelTitle = value === "shift" ? "Shifts" : "Time Entries"
    const storeKey = value === "shift" ? "shifts" : "timeEntries"
    const storeKeyArray = value === "shift" ? "Shifts" : "TimeEntries"
    const tableName = value === "shift" ? "shifts" : "timeentries"
    //setState({ ...state, model: value, modelTitle, storeKey, storeKeyArray, tableName })

    const _newState = {...state, model: value, modelTitle, storeKey, storeKeyArray, tableName }
    setState(_newState) 
    
    state.model = value
    state.modelTitle = modelTitle
    state.storeKey = storeKey
    state.storeKeyArray = storeKeyArray
    state.tableName = tableName

    //setState({...state, ...{  model: value, modelTitle: modelTitle, storeKey: storeKey, storeKeyArray: storeKeyArray, tableName: tableName }} )
    const baseFilters = _.pick(filters, commonFilters)
    setFilters({ ...baseFilters })
    const baseQuery = _.pick(query, commonQueries)

    const newQuery = { ...baseQuery, range: rangeQuery() }

    setQuery(clean(newQuery))
    //setQuery(newQuery)
    totalCountQuery()
  }

  //useEffect(() => console.log('*** state really is -> ', state), [state])

  const calcTotals = () => {
    let ids = state.records.map(r => r.id)
    //console.log("calcTotals", ids)

    // console.log(state.records.map(d => d.id))
    
    // remove unselected before sending to backend
    const excludedIds = Array.from(excluded.values())

    ids = ids.filter( function( el ) {
      return !excludedIds.includes( el );
    } );

    // renamed to xtotal because was not happ with new pagination comppmemt
    return `${ids.length} of ${xtotal}`
    
  }

  const selectOptions = {
    className: "modelSelector",
    value: state.model,
    onChange: modelChange,
    filterOption,
    dropdownRender: menu => renderSelectDropdown(menu, props["data-cy"]),
  }

  const modelSelector = (
    <div>
      <Select data-cy="Select" {...selectOptions}>
        <Option data-cy="option-timeEntry" value="timeEntry">
          {isTimeEntries && <Tag>{calcTotals()}</Tag>} TIME ENTRIES
        </Option>
        <Option data-cy="option-shift" value="shift">
          {isShifts && <Tag>{calcTotals()}</Tag>} SHIFTS
        </Option>
      </Select>
    </div>
  )

  const tabChange = key => {
    const modeTitle = key === "filter" ? "Filtering" : "Modifying"
    setState({ ...state, tab: key, modeTitle })
  }

  const totalCountQuery = async () => {
    // fix this query for proper counts 
    //const filterOut = Array.from(excluded.values()).length >0 ? ` AND "id" not in ('${Array.from(excluded.values())}') `:''

    const _query = `SELECT id FROM ${state.tableName} WHERE "deletedAt" IS NULL AND "payTypeCode" != '380' AND ${rangeQuery()}`
    
    const records = await REQ("/api/raw_query", "POST", { query: _query, storeKey: state.storeKey, push: false }).catch(err =>
      Emitter.emit("RecordsViewer.js", { fn: "totalCountQuery", error: err }),
    )
    setTotal(records.length)
  }

  /* using session vs state may revisit later
  const pruneQuerySession = (ss) => {
		console.log(ss.query);

    console.log('**** blw -- pruneQuery.query -> ', ss.query)

		console.log("clean");
    const prunedQuery = clean(ss.query)
		console.log(prunedQuery);
    const sqlQuery = [`SELECT id FROM ${ss.state.tableName} WHERE "deletedAt" IS NULL`]
    for (let q in prunedQuery) {
      sqlQuery.push("AND")
      sqlQuery.push(prunedQuery[q])
    }
    sqlQuery.push("LIMIT 1000")
    const rawQuery = sqlQuery.join(" ")
    return rawQuery
  }
  */

  const pruneQuery = () => {
		//console.log(query);

		//console.log("clean");
    const prunedQuery = clean(query)
		//console.log(prunedQuery);

    const sqlQuery = []

    if (prunedQuery.deletedAt) {
      sqlQuery.push(`SELECT id FROM ${state.tableName} WHERE "deletedAt" IS NOT NULL `)
    } else {
      sqlQuery.push(`SELECT id FROM ${state.tableName} WHERE "deletedAt" IS NULL`)
    }

    for (let q in prunedQuery) {
      if (q !== 'deletedAt') {
        sqlQuery.push("AND")
        sqlQuery.push(prunedQuery[q])
      }
      
    }
    //sqlQuery.push("LIMIT 3000")
    const rawQuery = sqlQuery.join(" ")
    return rawQuery
  }

  /*  test for getting from session vs state.  may revisit later
  const queryRecordsOnDrawerClose = async () => {
		console.log("queryRecords");

    // state may not be updated yet and we need to stay where we came from
    const ss = store.session.get("RecordsViewer")

    
    setLoading(true)
    const rawQuery = pruneQuerySession(ss)
		console.log("queryRecords " + ss.state.storeKey);
		console.log(rawQuery);

    console.log("**** blw -- queryRecords state.storeKey ->" + ss.state.storeKey);
		console.log("**** blw -- rawQuery -> ", rawQuery);


    console.log(`**** blw -- calling /api/raw_query/${ss.state.storeKey} with ${rawQuery}   ...... `)
    const records = await REQ(`/api/raw_query/${ss.state.storeKey}`, "POST", { query: rawQuery }).catch(err =>
      Emitter.emit("RecordsViewer.js", { fn: "queryRecords", error: err }),
    )
    // console.log('queryRecords', records[12])
    // Filter out unpaid lunch.... may do this differenlty in the future.
    const filteredRecs = _.filter(records, t => t.name !== "Unpaid Lunch")

    setState({ ...state, mounted: true, page: 0, records: filteredRecs })
    console.log('place holder for debug')
    setLoading(false)
  }
  */

// csv export..


const preProcessShifts = (rec) => {
  return ({
    dateFilterStartDate: moment(store.session.get('RecordsViewer').filters.range[0]).format('MM/DD/YYYY'),
    dateFilterEndDate: moment(store.session.get('RecordsViewer').filters.range[1]).format('MM/DD/YYYY'),
    shiftId: rec.id,
    approved: rec.approved,
    approvedByNumber: rec.approvedByNumber || '',
    approvedByName: rec.approvedBy ? rec.approvedBy.fullName : '',
    assignmentId: rec.assignmentId === null ? '' : rec.assignmentId,
     operationalAssignment: rec.operationalAssignment === null ? '' : rec.operationalAssignment,
    businessUnitCode: rec.businessUnitCode,
    businessUnitName: rec.businessUnit ? rec.businessUnit.name : '',
    caseNumber: rec.caseNumber,
    costCenterChargeOutCode: rec.costCenterChargeOutCode === null ? '' : rec.costCenterChargeOutCode,
    costCenterCharegeOutName: rec.costCenterChargeOut? rec.costCenterChargeOut.name : '',
    createdAt: moment(rec.createdAt).format('MM/DD/YYYY HH:mm'),
    updatedAt: moment(rec.updatedAt).format('MM/DD/YYYY HH:mm'),
    userId: rec.userId,
    duration: rec.duration.toFixed(2),
    durationOg: rec.durationOg.toFixed(2),
    durationOt: rec.durationOt.toFixed(2),
    employeeNumber: rec.employeeNumber === null ? '' : rec.employeeNumber,
    employeeFullName: rec.employee ? rec.employee.fullName: '',
    start: moment(rec.start).format('MM/DD/YYYY HH:mm'),
    end: moment(rec.end).format('MM/DD/YYYY HH:mm'),
    isDive: rec.isDive,
    diveHours: rec.diveHours === null ? 0.00 : rec.diveHours.toFixed(2),
    diveHoursIncluded: rec.diveHoursIncluded,
    isFieldTraining: rec.isFieldTraining,
    ftoHours: rec.ftoHours === null ? 0.00 : rec.ftoHours.toFixed(2),
    ftoHoursIncluded: rec.ftoHoursIncluded,
    isOT: rec.isOT,
    isSick: rec.isSick,
    requestedOff: rec.requestedOff,
    jobCode: rec.jobCode === null ? '' : rec.jobCode,
    jobStepCode: rec.jobStepCode === null ? '' : rec.jobStepTimecardOverride,
    needsApproved: rec.needsApproved,
    UnPaidLunch: rec.paidLunch === null ? true : rec.paidLunch,
    payTypeCode: rec.payTypeCode,
    payTypeCodeName: rec.payType? rec.payType.name : '',
    process: rec.process,
    scheduledOT: rec.scheduledOT,
    subLedgerCode: rec.subLedgerCode === null ? '' : rec.subLedgerCode,
    subLedgerName: rec.subLedger ? rec.subLedger.name : '',
    superNumber1: rec.superNumber1 === null ? '' : rec.superNumber1,
    superNumber1FullName: rec.supervisor1 ? rec.supervisor1.fullName : '',
    superNumber2: rec.superNumber2 === null ? '' : rec.superNumber2,
    superNumber2FullName: rec.supervisor2 ? rec.supervisor2.fullName : '',
    superNubmer3: rec.superNumber3 === null ? '' : rec.superNumber3,
    superNumber3FullName: rec.supervisor3 ? rec.supervisor3.fullName : '',
    imbalanced: rec.imbalanced,
    isShortShift: rec.isShortShift,
    notes: rec.notes,
    rejected: rec.rejected,
    schdeuled: rec.scheduled,
    shortShiftEarly: rec.shortShiftEarly,
    shortShiftLate: rec.shortShiftLate,
  })

}

const preProcessTimeEnty = async (rec) => {

  // get notes from shift.  maybe others?
  const shift = await REQ(`/api/shifts/${rec.shiftId}`).catch(err =>
    Emitter.emit("RecordsViewer.js", { fn: "handleRowClick", error: err }),
  )
  
  if (shift) {
    const timeEntryRec = {
        dateFilterStartDate: moment(store.session.get('RecordsViewer').filters.range[0]).format('MM/DD/YYYY'),
        dateFilterEndDate: moment(store.session.get('RecordsViewer').filters.range[1]).format('MM/DD/YYYY'),
        shiftId: rec.shiftId,
        approved: rec.approved,
        approvedByNumber: rec.approvedByNumber || '',
        approvedByName: rec.approvedBy ? rec.approvedBy.fullName : '',
        assignmentId: rec.assignmentId === null ? '' : rec.assignmentId,
        assignment: rec.assignment ? rec.assignment.name : '',
        businessUnitCode: rec.businessUnitCode,
        businessUnitName: rec.businessUnit ? rec.businessUnit.name : '',
        caseNumber: rec.caseNumber,
        costCenterChargeOutCode: rec.costCenterChargeOutCode === null ? '' : rec.costCenterChargeOutCode,
        costCenterCharegeOutName: rec.costCenterChargeOut? rec.costCenterChargeOut.name : '',
        duration: rec.duration.toFixed(2),
        employeeNumber: rec.employeeNumber === null ? '' : rec.employeeNumber,
        employeeFullName: rec.employee ? rec.employee.fullName : '',
        start: moment(rec.start).format('MM/DD/YYYY HH:mm'),
        end: moment(rec.end).format('MM/DD/YYYY HH:mm'),
        isDive: rec.isDive,
        isFieldTraining: rec.isFieldTraining,
        isOT: rec.isOT,
        jobCode: rec.jobCode === null ? '' : rec.jobCode,
        jobStepCode: rec.jobStepCode === null ? '' : rec.jobStepCode,
        jobStepTimecardOverride: rec.jobStepTimecardOverride === null ? '' : rec.jobStepTimecardOverride,
        name: rec.name || '',
        needsApproved: rec.needsApproved,
        notes: rec.notes === null ? '' : rec.notes,
        UnPaidLunch: rec.paidLunch === null ? true : rec.paidLunch,
        payTypeCode: rec.payTypeCode,
        payTypeCodeName: rec.payType? rec.payType.name : '',
        process: rec.process,
        scheduledOT: rec.scheduledOT,
        status: rec.status,
        subLedgerCode: rec.subLedgerCode === null ? '' : rec.subLedgerCode,
        subLedgerName: rec.subledger ? rec.subledger.name : '',
        superNumber1: rec.superNumber1 == null ? '' : rec.superNumber1,
        superNumber1FullName: rec.supervisor1 ? rec.supervisor1.fullName : '',
        superNumber2: rec.superNumber2 === null ? '' : rec.superNumber2,
        superNumber2FullName: rec.supervisor2 ? rec.supervisor2.fullName : '',
        superNubmer3: rec.superNumber3 === null ? '' : rec.superNumber3,
        superNumber3FullName: rec.supervisor3 ? rec.supervisor3.fullName : '',
        createdAt: moment(rec.createdAt).format('MM/DD/YYYY HH:mm'),
        updatedAt: moment(rec.updatedAt).format('MM/DD/YYYY HH:mm'),
        userId: rec.userId,
        shiftStart: moment(shift.start).format('MM/DD/YYYY HH:mm'),
        shiftEnd: moment(shift.end).format('MM/DD/YYYY HH:mm'),
        shiftIsOT: shift.isOT,
        shiftRequestedOff: shift.requestedOff,
        shiftIsSick: shift.isSick,
        shiftDuration: shift.duration.toFixed(2),
        shiftDurationOg: shift.durationOg.toFixed(2),
        shiftDurationOt: shift.durationOt.toFixed(2),
        shiftFtoHours: shift.ftoHours.toFixed(2),
        shiftFtoHoursIncluded: shift.ftoHoursIncluded,
        shiftDiveHours: shift.diveHours.toFixed(2),
        shiftDiveHoursIncluded: shift.diveHoursIncluded,
        shiftAssignmentId: shift.assignmentId === null ? '' : shift.assignmentId,
        shiftAssignment: shift.assignment ? shift.assignment.name : '',
        shiftOperationalAssignment: shift.operationalAssignment === null ? '' : shift.operationalAssignment,
        shiftSuperNumber1: shift.superNumber1 === null ? '' : shift.superNumber1,
        shiftSuperNumber1FullName: shift.supervisor1 ? shift.supervisor1.fullName : '',
        shiftSuperNumber2: shift.superNumber2 === null ? '' : shift.superNumber2,
        shiftSuperNumber2FullName: shift.supervisor2 ? shift.supervisor2.fullName : '',
        shiftSuperNubmer3: shift.superNumber3 === null ? '' : shift.superNumber3,
        shiftSuperNumber3FullName: shift.supervisor3 ? shift.supervisor3.fullName : '',
        shiftRejected: shift.rejected,
        shiftSchdeuled: shift.scheduled,
        shiftShortShiftEarly: shift.shortShiftEarly,
        shiftShortShiftLate: shift.shortShiftLate,
        shiftCreatedAt: moment(shift.createdAt).format('MM/DD/YYYY HH:mm'),
        shiftUpdatedAt: moment(shift.updatedAt).format('MM/DD/YYYY HH:mm'),
        shiftUserId: shift.userId,
        shiftNotes: shift.notes !== null ? shift.notes : ''
    }
  
    return timeEntryRec
  }
  return {}
}
const JSONToCSVConvertor = async (JSONData, ReportTitle, ShowLabel) => {
  setDownloadAnimate(true)

  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrDataIn = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
  //const downloadAnimate = setTimeout(exitDownloadRefresh,18000)

  // preprocess the data
  let checkedRecs = []
  
  if (state.model === 'shift') {
    const excludedShifts = store.session.get('ShiftRecsExcluded') ? store.session.get('ShiftRecsExcluded').length : 0
    if (excludedShifts > 0) {
      checkedRecs = arrDataIn.filter(({id: id1}) => !store.session.get('ShiftRecsExcluded').some((id2) => (id2 === id1))) 
    }
  } 

  if (state.model === 'timeEntry') {
    const excludedTimeEntry = store.session.get('TimeEntryRecsExcluded') ? store.session.get('TimeEntryRecsExcluded').length : 0
    if (excludedTimeEntry > 0) {
      checkedRecs = arrDataIn.filter(({id: id1}) => !store.session.get('TimeEntryRecsExcluded').some((id2) => (id2 === id1))) 
    }
  }
  

  if (checkedRecs.length > 0) arrDataIn = checkedRecs

  var arrData = []
  let recCount = 0
  let recDisplay = 5
  const steps = {
    1: 'Extracting data',
    2: 'Preparing download',
    3: 'Downloading data',
    4: 'Download complete'
  }

  setStepDisplay(steps[1])

  for (let rec of arrDataIn) {
    //console.log('PreProcess records.....')

   let exportRec = {}
   if (state.model === 'shift') {
     exportRec = preProcessShifts(rec)
   } else {
     exportRec = await preProcessTimeEnty(rec)
   }

    arrData.push(exportRec)


    const perComplete = parseInt((recCount / arrDataIn.length)*100)
    recCount += 1
    if (perComplete >= recDisplay) {
      if (recCount < arrDataIn.length) {
        setRecsExported(perComplete)

        recDisplay += 13
        if (recDisplay >= 45) setStepDisplay(steps[2])
        if (recDisplay >= 90) setStepDisplay(steps[3])

      } else {
        setRecsExported(100)
        setStepDisplay(steps[4])
      } 

      

    }
   
     if (recCount >= arrDataIn.length)  setRecsExported(100)

  }

  // end preprocessor
  var CSV = "";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var row = "";

    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      //Now convert each value to string and comma-seprated

      // make these look better
      const firstPass = index.replace(/([A-Z])/g, " $1")
      const finalResult = firstPass.charAt(0).toUpperCase() + firstPass.slice(1)
      row += finalResult + ","
      //row += index + ","
    }

    row = row.slice(0, -1);

    //append Label row with line break
    //const titleStart =  moment(store.session.get('RecordsViewer').filters.range[0]).format('MM/DD/YYYY')
    //const titleEnd   =  moment(store.session.get('RecordsViewer').filters.range[1]).format('MM/DD/YYYY')
    //const titleDates = `Dates ${titleStart} to ${titleEnd}`
    //CSV += titleDates +"\r\n"
    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for ( index in arrData[i]) {
      row += '"' + arrData[i][index] + '",'
    }

    row.slice(0, row.length - 1)

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data")
    return;
  }

  setDownloadAnimate( false)
  //clearTimeout(downloadAnimate)

  //Generate a file name
  var fileName = "export_"
  //this will remove the blank-spaces from the title and replace it with an underscore
  //fileName += ReportTitle.replace(/ /g, "_");
  //_${moment().format('YYYYMMDD_HHMMSS')}

  let fileSuffix = moment().format('YYYYMMDD')
  if (store.session.get('RecordsViewer') && store.session.get('RecordsViewer').filters.range) {
    const dt1 = moment(store.session.get('RecordsViewer').filters.range[0]).format('YYYYMMDD')
    const dt2 = moment(store.session.get('RecordsViewer').filters.range[1]).format('YYYYMMDD')
    fileSuffix = `${dt1}_${dt2}`

  }

  fileName += `${ReportTitle}_${fileSuffix}`.replace(/ /g, "_")

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV)

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  
  //this trick will generate a temp <a /> tag
  var link = document.createElement("a")
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden"
  link.download = fileName + ".csv"

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link)
  link.click();
  document.body.removeChild(link)
  
 

  // keep 100% visible for 4 seconds
  //setRecsExported(0)
  let timeLeft = 4
  setStepDisplay(steps[4])
  let downloadTimer = setInterval(function() {
    if (timeLeft <= 0) {
      setRecsExported(0)
      clearInterval(downloadTimer)
    } 
    timeLeft -= 1
  }, 1000)

  //return CSV

}

// end csv export

  const queryRecords = async () => {
		//console.log("queryRecords");

    
    setLoading(true)
    const rawQuery = pruneQuery()
		//console.log("queryRecords " + state.storeKey);
		//console.log(rawQuery);

    let paranoidOn = paranoid

    if (rawQuery.includes('WHERE "deletedAt" IS NOT NULL')) {
      paranoidOn = false
      setParanoid(false)
    }

    const records = await REQ(`/api/raw_query/${state.storeKey}`, "POST", { query: rawQuery, paranoid: paranoidOn ? 'on' : 'off'}).catch(err =>
      Emitter.emit("RecordsViewer.js", { fn: "queryRecords", error: err }),
    )
    // console.log('queryRecords', records[12])
    // Filter out unpaid lunch.... may do this differenlty in the future.
    ////let filteredRecs = _.filter(records, t => t.name !== "Unpaid Lunch")
    //const filteredRecs = _.filter(records, t => t.payTypeCode !== "380")
    ////filteredRecs = _.filter(filteredRecs, t => t.payTypeCode !== '380')

    const filteredRecs = _.filter(records, t=>  t.name !== "Unpaid Lunch" || t.payTypeCode !== '380')

    if (state.model === 'shift') {
      if (records && records.length > 0 ) {
        let ids = records.map(r => r.id)    
        for (let id of ids) {
          excluded.add(id)
          excludedShifts.add(id)
        }
        store.session.set("SelectAllShifts", false)
        store.session.set('ShiftRecsExcluded', Array.from(excludedShifts.values()))
      }
     
    }

    setState({ ...state, mounted: true, page: 0, records: filteredRecs })
    //setTotal(filteredRecs.length)
    setLoading(false)
  }

  const handleUpdate = async () => {
    setLoading(true)
    let ids = state.records.map(r => r.id)
    //console.log("handleUpdate", update, ids)
    //console.log(state.records.map(d => d.id))
    
    // remove unselected before sending to backend
    //const excludedIds = Array.from(excluded.values())

    const excludedIds = state.tableName === 'timeentries'? Array.from(excludedTimeEntries.values()) : Array.from(excludedShifts.values())
    

    ids = ids.filter( function( el ) {
      return !excludedIds.includes( el );
    } );


    await REQ(`/api/resources/${state.model}?sendNotices=${state.sendNotices}`, "PATCH", { update, ids }).catch(err =>
      Emitter.emit("RecordsViewer.js", { fn: "handleUpdate", error: err }),
    )
    queryRecords()

    // cleanup after update
    setUpdate({})
    setRecsUpdated(true)

  }

  const setSendNotices = async () => {
		state.sendNotices = !state.sendNotices
	}

  useEffect(() => {
    console.log("update changed", update)
  }, [update])

  useEffect(() => {
    if (!state.mounted) setState({ ...state, mounted: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    Emitter.on("ShiftDrawerClose", function () {
			console.log("ShiftDrawerClose")
      ////
		  //-//queryRecords()
      //queryRecordsOnDrawerClose()
		})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    totalCountQuery()
		console.log("useEffect->query change")
    queryRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, sup1, sup2, sup3])

  useEffect(()=>{
      if (state.mounted) setSup1(sup1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sup1, sup2, sup3])

  useEffect(() => {
    if (state.mounted) {
      const viewerState = {
        state: { ...state },
        update: { ...update },
        filters: { ...filters },
        query: { ...query },
        //date: Date.now(),
      }

      try {
        store.session.set("RecordsViewer", clean(viewerState))
        //-//store.session.set("RecordsViewer", viewerState)
      } catch (e) {
        console.log('Error in local storage', e);
        store.session.set("RecordsViewer", clean(store.session.get("RecordsViewer")))
    }
    }
  }) 

  useEffect(() => {
    //console.log("EXCLUDED", excluded)
    if (state.model === 'shift') store.session.set("ShiftRecsExcluded", Array.from(excluded.values()))
    if (state.model === 'timeEntry') store.session.set("TimeEntryRecsExcluded", Array.from(excluded.values()))
    store.session.set("RecordsViewerExcluded", Array.from(excluded.values()))
    // eslint-disable-next-line
  }, [excluded,])

  useEffect(() => {
    //console.log("EXCLUDED SHIFTS", excludedShifts)
    if (state.model === 'shift') store.session.set("ShiftRecsExcluded", Array.from(excluded.values()))
    if (state.model === 'timeEntry') store.session.set("TimeEntryRecsExcluded", Array.from(excluded.values()))
    store.session.set("RecordsViewerExcluded", Array.from(excluded.values()))
    // eslint-disable-next-line
  }, [excludedShifts])

  useEffect(() => {
    //console.log("EXCLUDED TIME ENTRIES", excludedTimeEntries)
    if (state.model === 'shift') store.session.set("ShiftRecsExcluded", Array.from(excluded.values()))
    if (state.model === 'timeEntry') store.session.set("TimeEntryRecsExcluded", Array.from(excluded.values()))
    store.session.set("RecordsViewerExcluded", Array.from(excluded.values()))
    // eslint-disable-next-line
  }, [excludedTimeEntries])

  useEffect(() => {
    //console.log("SELECT ALL", selectAll)
    store.session.set("SelectAll", selectAll)
  }, [selectAll])

  useEffect(() => {
    //console.log("SELECT ALL", selectAllShifts)
    store.session.set("SelectAllShifts", selectAllShifts)
  }, [selectAllShifts])

  useEffect(() => {
    //console.log("SELECT ALL", selectAllTimeEntry)
    store.session.set("SelectAllTimeEntry", selectAllTimeEntry)
  }, [selectAllTimeEntry])

  useEffect(() => {
    //console.log("INDETERMINATE", indeterminate)
    store.session.set("Indeterminate", indeterminate)
    totalCountQuery()
    // eslint-disable-next-line
  }, [indeterminate])

  useEffect(() => {
    //console.log("INDETERMINATE", indeterminateShift)
    store.session.set("IndeterminateShift", indeterminateShift)
    totalCountQuery()
    // eslint-disable-next-line
  }, [indeterminateShift])

  useEffect(() => {
    //console.log("INDETERMINATE", indeterminateTimeEntry)
    store.session.set("IndeterminateTimeEntry", indeterminateTimeEntry)
    totalCountQuery()
    // eslint-disable-next-line
  }, [indeterminateTimeEntry])

  const filterTabProps = { data, setData, query, setQuery, filters, setFilters, rangeQuery, loading, setLoading, recsUpdated: recsUpdated, setRecsUpdated: setRecsUpdated, paranoid, setParanoid, sup1, setSup1, sup2, setSup2, sup3, setSup3 } //, showAllColumns, setShowAllColumns }
  const modifyTabProps = { update, setUpdate, loading, setLoading, state, excluded, recsUpdated: recsUpdated, setRecsUpdated: setRecsUpdated,paranoid, setParanoid } //, showAllColumns, setShowAllColumns }

  let _columns = isShifts ? ShiftColumns({ 
    excluded, setExcluded,
    selectAll, setSelectAll, 
    indeterminate, setIndeterminate, 
    excludedShifts, setExcludedShifts,
    selectAllShifts, setSelectAllShifts,
    indeterminateShift, setIndeterminateShift,
    //--future--showAllColumns, setShowAllColumns,
    records: state.records,
    paranoid,
  }) : 
  TimeEntryColumns({ 
    excluded, setExcluded, 
    selectAll, setSelectAll, 
    indeterminate, setIndeterminate, 
    excludedTimeEntries, setExcludedTimeEntries,
    selectAllTimeEntry, setSelectAllTimeEntry,
    indeterminateTimeEntry, setIndeterminateTimeEntry,
    //showAllColumns, setShowAllColumns,
    records: state.records,
    paranoid,
  })

  // let _columns2 = isShifts ? ShiftColumns({ excludedShifts, setExcludedShifts }) : TimeEntryColumns({ excludedTimeEntries, setExcludedTimeEntries })

  const _records = state.records.filter(r => !excluded.has(r.id))

  /*
  const onNewPageChange = page => {
    console.log(page);
    setState({...state, page: page })
    console.log(page);

  }

  const handleNavButtonClick = (val) => {
    if (state.mounted) {

      if (val === 'first') {
        setFirstDisabled(true)
        setPrevDisabled(true)
        setNextDisabled(false)
        setLastDisabled(false)
        setState({ ...state, page: 0 })
      }

      if (val === 'last') {
        setFirstDisabled(false)
        setPrevDisabled(false)
        setNextDisabled(true)
        setLastDisabled(true)
        setState({ ...state, page: Math.ceil(state.records.length/tableProps.pageSize)-1 })
      }

      if (val === 'prev' && !prevDisabled ) {
        setState({ ...state, page: state.page - 1 })
        if (state.page <= 0) {
          setPrevDisabled(true)
          setFirstDisabled(true)
          setNextDisabled(false)
          setLastDisabled(false)
          setState({ ...state, page: 0 })
        } else {
          setNextDisabled(false)
          setPrevDisabled(false)
          setFirstDisabled(false)
          setLastDisabled(false)
        }

      } 
      if (val === 'next' && !nextDisabled ) {
        if ( state.page+1 < Math.ceil(state.records.length/tableProps.pageSize) ) {
          setState({ ...state, page: state.page + 1 })
          setNextDisabled(false)
          setPrevDisabled(false)
          setFirstDisabled(false)
          setLastDisabled(false)
        } else {
          setNextDisabled(true)
          setPrevDisabled(false)
          setFirstDisabled(true)
          setLastDisabled(false)
          setState({ ...state, page: Math.ceil(state.records.length/tableProps.pageSize)-1 })

        }

      }
  }

  }
*/
  const handleRowClick = async (rowInfo, evt) => {
		const viewerState = {
        state: { ...state },
        update: { ...update },
        filters: { ...filters },
        query: { ...query },
        //date: Date.now(),
      }

    try {
      store.session.set("RecordsViewer", clean(viewerState))
    }
    catch (e) {
      console.log('seems like local storage may be full.....')
      store.session.set("RecordsViewer", clean(store.session.get("RecordsViewer")))

    }

    const record = rowInfo.original
    const shiftId = record.shiftId ? record.shiftId : record.id
    if (paranoid) {
      if (evt.target.type !== "checkbox") {
        const shift = await REQ(`/api/shifts/${shiftId}`).catch(err =>
          Emitter.emit("RecordsViewer.js", { fn: "handleRowClick", error: err }),
        )
        // context.shifts.set(shift.id, shift)
        store.set("Shift", shift)
        context.setShiftDrawerId(shiftId)
      }
    }
  }

  /*
  const handleCreateShift = () => {
    context.setNewShiftDrawerPayTypeCode(1)
  }
  */
  /*
  const handleCreateShift = selection => {
    const value = selection.item.props.value
    let payTypeCode
    if (value === "overtime") payTypeCode = '200'
    if (value === "dive") payTypeCode = '217'
    if (value === "jury") payTypeCode = '17'
    if (value === "court") payTypeCode = '261'
    if (value === "leave") payTypeCode = '30'
    if (value === "callout") payTypeCode = '210'
    if (value === "evidence2hr") payTypeCode = '250'
    if (value === "evidence4hr") payTypeCode = '251'
    if (value === "blank") payTypeCode = '1'
    context.setNewShiftDrawerPayTypeCode(payTypeCode)
  }
  */

  //<Menu.Item key={uniqid()} value="jury"> Jury Duty </Menu.Item>
  //<Menu.Item key={uniqid()} value="leave"> Leave </Menu.Item>

  // eslint-disable-next-line react-hooks/exhaustive-deps
  /*
  const createShiftMenu = useMemo(() => (
    <Menu onClick={handleCreateShift}>
      <Menu.Item key={uniqid()} value="overtime"> Overtime </Menu.Item>
      <Menu.Item key={uniqid()} value="dive"> Dive </Menu.Item>
      <Menu.Item key={uniqid()} value="court"> Court </Menu.Item>
      <Menu.Item key={uniqid()} value="callout"> Call Out/Back </Menu.Item>
      <Menu.Item key={uniqid()} value="evidence2hr"> Evidence Pay (2hr) </Menu.Item>
      <Menu.Item key={uniqid()} value="evidence4hr"> Evidence Pay (4hr) </Menu.Item>
      <Menu.Item key={uniqid()} value="blank"> Blank </Menu.Item>
    </Menu>
  ))
  */

  //refresh


  useEffect(() => {
     console.log('*** refreshAnimate -> ', refreshAnimate)
    //setTimeout(exitLoading(),3000)

  }, [refreshAnimate])

  useEffect(() => {
    console.log('*** downloadAnimate -> ', downloadAnimate)
   //setTimeout(exitLoading(),3000)

 }, [downloadAnimate])
  
  const enterRefresh = () => {
      let RefreshAnimate = true
      setTimeout(exitRefresh,2500)
      queryRecords()


      setRefreshAnimate( RefreshAnimate )
      
  };

  const exitRefresh = () => {
    let RefreshAnimate = false
    setRefreshAnimate( RefreshAnimate )
  }

  //const exitDownloadRefresh = () => {
  //  let DownloadAnimate = false
  //  setDownloadAnimate( DownloadAnimate )

  //}

  const tableProps = {
    loading,
    data: state.records,
    defaultPageSize: state.defaultPageSize, // 50,
    /////pageSize: collapsed ? undefined : 50,
    pageSize: state.defaultPageSize, //50,
    page: state.page,
    //showPagination: collapsed,
    showPagination: false,
    //showPaginationTop: false,
    //showPaginationBottom: collapsed,
    className: "-striped -highlight flex-1",
    columns: _columns,
    multiSort: true,
    //style:{
    //  height: "400px", // This will force the table body to overflow and scroll, since there is not enough room
    //},
   onPageChange: (pageIndex) => { // Called when the page index is changed by the user
      let currPage = pageIndex - 1
      setState({ ...state, page: currPage })},
    defaultSorted: [
      { id: "start", asc: true },
      { id: "employee.fullName", asc: true },
    ],
    getTrProps: (tableInfo, rowInfo) => {
      if (!rowInfo) return false
      const { id } = rowInfo.original
      const opts = { "data-cy": "RecordRow", "data-uid": id, "data-id": id, onClick: e => handleRowClick(rowInfo, e) }
      if (excluded.has(id)) opts.disabled = true
      return opts
    },
  }

  // new pagination 
  const onShowSizeChange = (current, pageSize) => {
    setState( {...state, defaultPageSize: pageSize } )
  }

  const showTotal = (total) => {
  return `Total Records: ${total} `
}

  const deleteModalProps = { deleteOpen, setDeleteOpen, records: state.records, excluded: excludedShifts, queryRecords }
  const restoreModalProps = { restoreOpen, setRestoreOpen, setParanoid, records: state.records, excluded: excludedShifts, queryRecords, filters, setFilters, query,setQuery }
  const restoreTimeEntryModalProps = { restoreTimeEntryOpen, setRestoreTimeEntryOpen, setParanoid, records: state.records, excluded: excludedTimeEntries, queryRecords, filters, setFilters, query, setQuery }
  const statsModalProps =  { statsOpen, setStatsOpen, filters: filters, okText: 'Download',cancelText: 'Ok' }

	//-//console.log(deleteModalProps);

  /* -- future 
  const showAllGridCols = () => {
    if (showAllColumns) setShowAllColumns(false)
    if (!showAllColumns) setShowAllColumns(true)
  }
  */


  const toggle = () => {
    setCollapsed( !collapsed );
    //console.log('collapsed -> ', collapsed)
  };

  const allowAccess = getRole() === 'admin' || getRole() === 'supervisor' ? true : false


  return (
    <React.Fragment>
      <div className={`view_title ${state.model} ${state.tab}`}>
        <h1 data-cy="page-title"> {`${state.modeTitle} ${state.modelTitle}`} </h1>
        <div style={{ width: 600, display: recsExported ? 'block' : 'none', justifyContent: 'flex-end' }} >
          <label fontSize={'10px'}>{stepDisplay}</label>
          <Progress percent={recsExported} size="small" steps={4} showInfo={recsExported ? true : false}  />
        </div>
        <div className="actions">
         <Button loading={refreshAnimate} onClick={enterRefresh}>Refresh</Button>
          <Can I="create" a="Shift">
           {/*
            <Dropdown overlay={createShiftMenu}>
              <Button type="dashed" icon="plus-square" onClick={e => context.setShiftDrawerId(0)}>
                Create Shift
              </Button>
            </Dropdown>
           */}
           <Button type="dashed" icon="plus-square" onClick={e => {
                  context.setShiftDrawerId(0)
                  context.setNewShiftDrawerPayTypeCode('1')
                } }   
              >
                Create Shift
              </Button>
          </Can>
         {/* <Badge count={recsExported} overflowCount={999}> */}
          <Button 
             loading={downloadAnimate} 
             type="default" 
             icon="download" 
             /*disabled={state.model !== 'timeEntry' }*/ 
             onClick={() => JSONToCSVConvertor(JSON.stringify(state.records), `${state.model}`, true)}
          > 
          Download CSV
        </Button>
        {/* </Badge>*/}
            {/*
            <Button
              className="ml-4"
              size="small"
              type="dashed"
              onClick={showAllGridCols}
            > 
              Show All Columns
            </Button>
            */}
        </div>
      </div> 

      { allowAccess &&
      <div className={`view_content ${state.model} ${state.tab}`}>
      <div>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
        </div>
        <Layout className="RecordsViewer">
          <Sider className="Sider tabColumn" width="460" theme="light"
             trigger={null} collapsible collapsed={collapsed} collapsedWidth="0">
              <Tabs type="card" tabBarExtraContent={modelSelector} tabPosition = {'top'} onChange={tabChange} activeKey={state.tab} >
                <TabPane tab= { <span> <FilterOutlined />Filter</span> } key="filter">
                  {isShifts && <ShiftFilters {...filterTabProps} />}
                  {isTimeEntries && <TimeEntryFilters {...filterTabProps} />}
                </TabPane>
                <TabPane tab={ <span><EditOutlined />Modify</span> } key="modify" style={{ background: isShifts ? '#cee7f2': 'PapayaWhip'}}>
                  {isShifts && <ShiftFields {...modifyTabProps} />}
                  {isTimeEntries && <TimeEntryFields {...modifyTabProps} />}
                </TabPane>
              </Tabs>
            <div className="sideBarFooter">
              <div className="recordsInfo">
                {state.tab === "modify" && isShifts && paranoid && (
                  <span style={{ float: "left" }}>
                       <Button onClick={() => setDeleteOpen(true)} type="danger">{`Delete ${_records.length} Shifts`}</Button>
                  </span>
                )}
                {state.tab === "filter" && isShifts && !paranoid && (
                  <span style={{ float: "left" }}>                    
                    <Button onClick={() => setRestoreOpen(true)} type="default">{`Restore ${_records.length} Shifts`}</Button>
                  </span>
                )}
                {state.tab === "filter" && isTimeEntries && !paranoid && (
                  <span style={{ float: "left" }}>
                    <Button onClick={() => setRestoreTimeEntryOpen(true)} type="default">{`Restore ${_records.length} Time Entries`}</Button>
                  </span>
                )}
                 
                 {isTimeEntries && (
                  <Button onClick={() => setStatsOpen(true)} type="default">Time Entry Stats</Button>
                )}
                {state.tab === "modify" && (
									<span>
									<span>&nbsp;</span>
                  <Button onClick={handleUpdate}>
                    Update
                    <span>&nbsp;</span>
                    <b>
                    {calcTotals()}
                    </b>
                    <span>&nbsp;</span>
                    Records
                  </Button>
									</span>
                )}
                {state.tab === "modify" && ( //&& isShifts && (
									<span>
									<span>&nbsp;</span>
                  <CheckBox 
									value={state.sendNotices}
									handleChange={e => {
                		setSendNotices()
              		}}>
                  </CheckBox>
									<span>&nbsp;</span>
									<label>Notify</label>
									</span>
                )}
              </div>
            </div>
          </Sider>
          <Content className="Content">
            <ReactTable {...tableProps} />
            {/*{(!collapsed) && ( */}
            <div className="pagination" >
              {/*<Button className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" } onClick={() => setState({ ...state, page: state.page - 1 })}> Prev </Button>
              <Button className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" } onClick={() => setState({ ...state, page: state.page + 1 })}>Next</Button>*
              <Button shape="circle" icon="fast-backward" disabled={ firstDisabled } className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" } onClick={() => { handleNavButtonClick('first') }}></Button>
              <Button shape="circle" icon="step-backward"disabled={ prevDisabled } className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" } onClick={() => { handleNavButtonClick('prev') }}></Button>
              <Button shape="circle" icon="step-forward"disabled={ nextDisabled } className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" } onClick={() => { handleNavButtonClick('next') }}></Button>
              <Button shape="circle" icon="fast-forward"disabled={ lastDisabled } className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" } onClick={() => { handleNavButtonClick('last') }}></Button>
              
              <div className="info"></div><div className="info-pages"><strong>Page</strong> {state.page +1 } <strong>of</strong> {Math.ceil(state.records.length/tableProps.pageSize) }</div>
              <div className="info-total"><strong>Total Records</strong> {state.records.length}</div>
              */}
              <Pagination 
                 size='small'
                 showLessItems={true}
                 showSizeChanger 
                 onShowSizeChange={onShowSizeChange} 
                 showTitle={true}
                 showQuickJumper 
                 defaultCurrent={ state.page } 
                 pageSize={tableProps.pageSize} 
                 total={ state.records.length } 
                 onChange={ tableProps.onPageChange } 
                 pageSizeOptions={['10','20','30','40','50','100']}
                 showTotal={showTotal}
                 hideOnSinglePage={false}
                 //className={ isTimeEntries ? "-btn-timeEntry" : "-btn-shift" }
              />

            </div>
              
            {/*})}*/}
          </Content>
          <DeleteModal {...deleteModalProps} />
          <RestoreModal {...restoreModalProps} />
          <RestoreTimeEntryModal {...restoreTimeEntryModalProps} />
          <StatsModal {...statsModalProps} />
        </Layout>
      </div>
      }
      {!!context.shiftDrawerId && <ShiftDrawer />}
      {!!context.newShiftDrawer && <NewShiftDrawer payTypeCode={context.newShiftDrawer} />}
    </React.Fragment>
  )
}

export default RecordsViewer
