import React, { useState, useEffect } from "react"
import { store, reqOptions } from "../../store/psrs"
import uniqid from "uniqid"
import { Select } from "antd"
//import useSWR, { mutate } from "swr"
//import { mutate } from "swr"
import _ from "lodash"

import { renderSelectDropdown, filterOption } from "./_selectDefaults"

import Localbase from 'localbase'

let db = new Localbase('db')
db.config.debug = false

const Option = Select.Option

export const LocationSelect = props => {
  const { value, handleChange } = props

  //console.log("LocationSelect", value)

  const [state, setState] = useState({
    selected: [],
    Locations: [],
  })

  const [LocationIds, setLocationIds] = useState([]) //useState(data && data.length ? data.map(l => l.id ? l.id : l) : [])

   const getLocationIds = async () => {
    //const Locations = store.get("Locations")

    // new indexedDB --- WIP
    db.collection('Locations').delete()
    let initialRecords = [] // await db.collection("Locations").get()
    let Locations = []

    if (initialRecords.length === 0) {
      try {
        const url = `${store.get("api")}/api/resources/location`
        const options = { ...reqOptions }
  
        Locations = await fetch(url, options).then(res => res.json())

        if (_.isArray(Locations) && Locations.length) {
          for (let [index, val] of Locations.entries()) {
            db.collection('Locations').add(val,index.toString())
          }
        }

        const sortedRecs = Locations.sort((a, b) => a.name.localeCompare(b.name))

        const ids = Locations ? Locations.map(g => g.id ? g.id : g) : []
        setLocationIds(ids)
        setState({ ...state, Locations: sortedRecs, selected: LocationIds ? LocationIds : [] })
 

      } catch (e) {
        console.log('error -> ', e)
      }
    } else {
      const ids = initialRecords ? initialRecords.map(g => g.id ? g.id : g) : []
      setLocationIds(ids)
      const sortedRecs = initialRecords.sort((a, b) => a.name.localeCompare(b.name))

      setState({ ...state, Locations: sortedRecs, selected: LocationIds ? LocationIds : [] })
    }
  }
  
//  initRecs('Assignments')

// end indexedDB -- WIP

    // indexedDB - WIP 
    //db.collection('Locations').delete()
  /*
  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())
    //setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    const ids = data ? data.map(l => l.id ? l.id : l) : []
    setLocationIds(ids)
    setState({ ...state, Locations : data, selected: LocationIds ? LocationIds : [] })

    return data
  }*/
  //const key = `${store.get("api")}/api/resources/location`
  //const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })

  //const _props = { state, setState, mutate, url: key }


  const options = () =>
      state.Locations.map(location => (
        <Option data-cy={`option-${location.id}`} key={uniqid()} value={location.id}>
          {location.name}
        </Option>
      ))

  const selectOptions = {
    isDisabled: props.disabled,
    disabled: props.disabled,
    className: "GroupsSelector",
    dropdownClassName: "GroupsSelectorDropdown",
    allowClear: true,
    showSearch: false,
    mode: "default", // "multiple",
    onChange: e => {
      //console.log(e)
      setLocationIds(e)
      //setState({ ...state, LocationIds: e })
      setState({ ...state, selected: e })
      handleChange({ field: "LocationIds", value: e })
      // handleChange
    },
    value: value, //LocationIds,
    filterOption,
    dropdownRender: menu => renderSelectDropdown(menu, props["data-cy"]),
  }

   useEffect(() => {
    getLocationIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  return (
    <Select data-cy="Select" {...selectOptions}>
      { state.Locations.length > 0 ? options() : []}
    </Select>
  )
}

export default LocationSelect
