import React, { useState } from "react"
import { store } from "../../store/psrs"
import Select from "react-select"
// import {ReasonSelectOptions} from "./_SelectOptions"
import _ from "lodash"
//import { PlayCircleFilled } from "@ant-design/icons"

export const ReasonSelect = props => {
  const { id } = props

  let value = props.value

  const Reasons = store.get("Reasons") || []

	var ar = [];
	Reasons.forEach(function (r) {
		//console.log(JSON.stringify(r));
		if( !props.disabled ) { 
			if( r.category.toLowerCase() === "overtime" ) {
				ar.push(r);
			}
		}
    else if (props.disabled && value) {
      if( r.category.toLowerCase() === "overtime" ) {
				ar.push(r);
			}
    }
		else if( r.category.toLowerCase() !== "overtime" ) {
			ar.push(r);
		}
	});

  const ReasonSelectOptions = ar.map(r => ({ value: r.id, label: r.description }))

  const options = ReasonSelectOptions

  const selected = _.find(options, {value: value})

  // console.log('TagSelect values', selected, values)

  const [state, setState] = useState({
    options,
    selected,
    sql: null,
    loading: false
  })

  const placeholder = props.placeholder ? props.placeholder :  props.disabled ? "Not Required" : "Reason for Overtime"

  const selectProps = {
    disabled: props.disabled,
    isDisabled: props.disabled,
    id: "ReasonSelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    //placeholder: props.disabled ? "Not Required" : "Reason for Overtime",
    placeholder: placeholder,
    isClearable: true,
    isMulti: false,
    options: ReasonSelectOptions,
    value: selected, //state.selected,
    onChange: (select, action) => {
      const val = select ? select.value : null
      setState({ ...state, selected: select })
      props.handleChange({ ...props, value: val, field: "reasonId", id, sql: "" })
    },
  }

	//console.log("isOvertime: " + props.isOvertime);

  return <Select {...selectProps} />
}

export default ReasonSelect
