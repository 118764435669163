import React from "react"
import useMyContext from "../../store/useMyContext"
import { Button, List } from "antd"
import {store} from "../../store/psrs"

import { PicklistAttachDrawer } from "../PicklistAttachDrawer"

export const BidsTable = props => {
  const { shift, handleUpdate } = props
  const { biddersInCollision } = shift
  const context = useMyContext()
  const employeeNumbers = shift.bids || []
  const employees = employeeNumbers.map(e => {
    const employee = context.getRecord("Employees", e, "number")
    return employee
  })

  const bids = employees.map(employee => {
    const name = employee ? employee.fullName : "NAME"
    const collision = biddersInCollision.includes(employee.number)
    const label = collision ? `${name} (collision)` : name
    const buttonProps = {
      type: collision ? "danger" : "default",
      style: { margin: "0 1rem" },
      onClick: () => handleUpdate({ field: "employeeNumber", value: employee.number }),
    }
    return (
        <List.Item key={employee.number} className={collision ? "collision" : ""}>
          <Button {...buttonProps}> {label} </Button>
        </List.Item>
    )
  })
  const picklistProps = {
    type: "default",
    style: { margin: "0 1rem" },
    //onClick: () => handleUpdate({ field: "employeeNumber", value: employee.number }),
  }
  bids.push(<List.Item className="" key="plist1" >
     <Button {...picklistProps} type="dashed" icon="plus-square" 
       onClick={e => {
          context.setPicklistAttachDrawerId(1)
       }}
     >PICKLIST</Button></List.Item>)
     
     let jsxReturn = <List header={<h3 style={{padding: '10px'}}>  Bids for this Shift</h3>}>{bids}</List>

     const cachedId = store.get("picklistAttachDrawerId")
     if (context.picklistAttachDrawerId !== 0 || cachedId !== 0) {
      jsxReturn = <PicklistAttachDrawer payTypeCode={context.newShiftDrawer} />
      /* jsxReturn = <Modal
      title="Basic Modal"
      visible={context.picklistAttachDrawerId !== 0 }
      onOk={() => context.setPicklistAttachDrawerId(0)}
      onCancel={() => context.setPicklistAttachDrawerId(0)} 
    ><p>Some contents...</p><p>Some contents...</p><p>Some contents...</p></Modal>*/
     } 

     
     return jsxReturn
      //<List header={<h3 style={{padding: '10px'}}>  Bids for this Shift</h3>}>{bids}</List>
     
}

export default BidsTable
