import React, { useEffect, /*useState*/ } from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import { fetch, headers, store, Emitter, REQ } from "../store/psrs"
import { useMyContext, useAbilityContext } from "../store/useMyContext"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"
import columns from "./Shifts/_columns"
import {Layout} from "antd"
import _ from "lodash"
import { Search } from "./Admin/fields"

const { Content } = Layout

export const Shifts = props => {
  const context = useMyContext()
  const ability = useAbilityContext()
  const payPeriod = store.get("PayPeriod")
  const payPeriodId = payPeriod.id
  const myRole = store.session.get("role")
  //const  query = props.query(payPeriodId)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
  let query = props.page === 'AvailableShifts' ? props.query(userTimeZone) :  props.query(payPeriodId)

  if (myRole === 'admin' && props.adminQuery) query = props.adminQuery(payPeriodId)
    
  const [loading, setLoading] = useStateIfMounted(false)
 
  const [state, setState] = useStateIfMounted({
    selection: [],
    timeEntrySelected: [],
    shiftSelected: [],
    selectAll: false, 
    pk: "id",
    model: "shift",
    ids: [],
    initialRecords: undefined,
  })

  const [records, setRecords] = useStateIfMounted(state.initialRecords || [])

  const _props = { state, setState, mutate, url: props.url, width: 300}

  // const initial = session.get(page)
  const fetcher = async url => {

    const method = props.page === 'AvailableShifts' || props.page === "XXAvailableShiftsWB" ? 'GET' : 'POST'
    const body = props.page === 'AvailableShifts' || props.page === "XXAvailableShiftsWB" ? JSON.stringify({}) : JSON.stringify({ query })
    const options = {
      method: method,
      headers: headers(),
      body: body //JSON.stringify({ query }),
    }

    if (method === 'GET') delete options.body

    //console.log('Shifts -> supervisorSQL -> ', supervisorSQL)
    setLoading(true)

    const data = await fetch(props.url, options)
    
    setLoading(false)
    
    if (data && data.length > 0) Emitter.emit("ChangedLockStatus", { locked: data[0].locked })

    const filteredData = state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data
    setRecords(filteredData)

    return filteredData
    //return data
  }


  const updateShift = async ({ field, value, shiftId }) => {
   // console.log("Shifts.js updateShift", field, value, shiftId)
    const body = { id: shiftId }
    body[field] = value

    if (field === 'approved' && value === true ) {
      body['isCollision'] = false
    }

    const updated = await REQ(`/api/shifts/${shiftId}`, "PATCH", body)

    mutate(query)
    console.log("updated shift", updated)
  }

  // const swrOpts = {revalidateOnFocus: false, initialData: initial && initial.length ? initial : null }
  const swrOpts = { revalidateOnFocus: false }
  const { data } = useSWR(query, fetcher, swrOpts)

  useEffect(() => {
    Emitter.on("ShiftDrawerUpdate", () => {
      //console.log("Emitter.on('ShiftDrawerUpdate', ()", query)
      mutate(query)
    })
    Emitter.on("ShiftDrawerClose", () => {
      //console.log("Emitter.on('ShiftDrawerClose', ()", query)
      mutate(query)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (state.ids) {
      //const matching = _.filter(data, r => state.ids.includes(r.id))
      setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    } else {
       //mutate()
    }
    
   // eslint-disable-next-line
  }, [state.ids, data])

  const tableSort = props.page === 'AvailableShiftsWB' ?
    [
      { id: "payPeriod.end", desc: true },
      //{ id: "employee.fullName", asc: true },
      { id: "supervisor1.fullName", asc: true, desc: false },
      { id: "start", asc: true },
    ] 
  :
    [
      { id: "employee.fullName", asc: true },
      { id: "supervisor1.fullName", asc: true, desc: false },
      { id: "start", asc: true },
    ]
    
  const tableOptions = {
    columns: columns({ ...props, context, ability, updateShift }),
    multiSort: true,
    defaultPageSize: 100,
    className : "-striped -highlight",
    /* defaultSorted: [
      { id: "employee.fullName", asc: true },
      { id: "supervisor1.fullName", asc: true, desc: false },
      { id: "start", asc: true },
    ], */
    defaultSorted: tableSort,
    filterable: true,
    //data: data ? data : [],
    data: records ? records : [],
    loading,
    noDataText: "",
  }

  // added layout/content for scrolling on small screens
  return (
    <>
      <Layout className="RecordsViewer">
        <div>
          <Search {..._props} />
        </div>
        <Content className="Content">
          <ReactTable {...tableOptions} />
        </Content>
      </Layout>
    </>)
}

export default Shifts
