import React from "react"
import { Select } from "antd"

const { Option } = Select;

export const GroupPivotSelect = props => {
  
   const  {
       allowClear,
       placeholder,
       mode,
       pivot,
       handleSelectPivot 
    } =  props

    const selOpts = { 
        allowClear: allowClear || true,
        placeHolder: placeholder  || 'Select Group', 
        mode: mode || 'multiple',
        onChange: handleSelectPivot,
        style: {width: 500},
        defaultValue: pivot
    }

    return (
        <Select {...selOpts} >
            <Option key="category">Category</Option>
            <Option key="isActive">Active</Option>
            <Option key="isOT">Overtime</Option>

        </Select>
        )      

}
export default GroupPivotSelect