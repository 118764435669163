import React from "react"
import Creatable from 'react-select/creatable';

import { REQ } from "../../store/psrs"

export const CategorySelect = (props) => {
 
  const { 
      url, 
      //state, 
      selectedCategory, 
      id,
      //setState,
      mutate,
      BuildCategories,
  } = props

  const model=props.model ? props.model : 'shiftTemplate'


  const opts = {
    id: "cyCategorySelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    dropdownClassName: "cyCategorySelectDropdown categorySelectDropdown",
    placeholder: props.placeholder || "Select a Category",
    allowCreateWhileLoading: false,
    createOptionPosition: 'first', //last

  }
  
  const categories = BuildCategories()

  //console.log('Category Select')
  const handleChange = async (props) => {
      //if (shiftJson) {  
        //shiftJson["businessUnitCode"] = color
        const field = 'category'
        const value = props.label

        const newColor = categories.find(e => e.label === props.label)

        //const id = shiftJson.id
        //const model = "shiftTemplate"

        const update = {} 
        update[field] = value[0].toUpperCase() + value.slice(1)
        update['shiftJson.category'] = value[0].toUpperCase() + value.slice(1)
        update['categoryColor'] = newColor ? newColor.color : '#aabbcc'
        
        await REQ(`/api/resources/${model}/${id}`, "PATCH", update)
        mutate(url)
          
      //}
      
      //handleClose()
  }
  

    if (props.selectedCategory) {
      
       return ( 
          <Creatable
              name="form-field-name"
              value={{value:selectedCategory.toLowerCase(), label: selectedCategory   }}
              options={categories}
              onChange={handleChange}
              {...opts}
          />
        )
    }
  
}

export default CategorySelect

