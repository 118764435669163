import React, { useMemo, useState, useEffect } from "react"
import { Emitter, store, session, REQ, reqOptions } from "../store/psrs"
import { useMyContext, useAbilityContext}  from "../store/useMyContext"
import { Button, notification, Collapse, Card } from "antd"
import { Col, Row, Switch, Tooltip, Modal} from "antd"

//import { Search, InPut, Toggle } from "./Admin/fields"

import { Keyboard } from "grommet"
//import useSWR, { mutate } from "swr"
import useSWR from "swr"
import uniqid from "uniqid"
//import uuid from "uuid"



//import { BusinessUnitSelect } from "./Forms/BusinessUnitSelect"
import { EmployeeSelect } from "./Forms/EmployeeSelect"
//import { AssignmentSelect } from "./Forms/AssignmentSelect"
//import { DateTimePicker } from "./Forms/DateTimePicker"
import { SupervisorSelect } from "./Forms/SupervisorSelect"
//import { SubLedgerSelect } from "./Forms/SubLedgerSelect"
import { DurationField } from "./Forms/DurationField"
//import { GroupSelector } from "./Forms/GroupSelector"
import { DateFilter } from "./Forms/DateFilter"
import { StartTimeFieldT } from "./ShiftTemplate/StartTimeField"
//import { StartTimeField } from "./Forms/StartTimeField"



import _ from "lodash"
import { Drawer } from "./Drawer"
import moment from "moment-timezone"
import { isArray } from "core-js/fn/array"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';


//let initialDuration = undefined
//let initialPaidLunch = false

const ButtonGroup = Button.Group

export const NewShiftDrawer = props => {
  const context = useMyContext()
  const ability = useAbilityContext()

  const [state, setState] = useState({
    pk: "id",
    model: "shiftTemplate",
    ids: [],
    initialRecords: store.get("_ShiftTemplates") || undefined,
    loading: true,
    payType: undefined,
    title: undefined,
    shiftType: undefined,
    payTypeCategory: undefined,
    justDive: props.payTypeCode === 217 ? true : false,
    assignmentId: undefined,
    tags: undefined,
    tagIds: undefined,
    //initialDuration: undefined,
    //initialPaidLunch: undefined,
    evidenceDisabledField: false,
  })

  const [records, setRecords] = useState(state.initialRecords || [])
  const [categories, setCategories] = useState([])
  const [openPanel, setOpenPanel] = useState(0)
  //const [useCompPayType, setUseCompPayType] = useState(false)

  const [useCompPayType, setUseCompPayType] = useState({})

  const [isInfoVisible, setIsInfoVisible] = useState(false)

  const [showMore, setShowMore] = useState(false)

  //const [shiftJson, setShiftJson] = useState([])

  const [newShift, setNewShift] = useState( {
      startDate: moment().format('YYYY-MM-DD'),
      startTime: moment().format('HH:mm'),
      start: moment().format(),
      duration: 0,
      payTypeCode: '',
      employeeNumber: store.session.get("CurrentUser").number,
      employee: store.session.get("CurrentUser"),
      userRole: store.session.get("role"),
      available: false,
      generateTimeEntry: true,
      superNumber1: _.find(store.get("Employees"), { number: store.session.get("CurrentUser").supervisorNumber }).number,
      supervisor1: _.find(store.get("Employees"), { number: store.session.get("CurrentUser").supervisorNumber }),
      end: null,
      isValid: false,
  })

  // turn off shift drawer save
  session.set("changeDetected", false)

  const cantEditEmployee = !ability.can("edit:shift:Employee")

  const fetcher = async url => {
    const options = { ...reqOptions, method: 'POST',body: JSON.stringify({options: { paginate: 1000}}) }
    const data = await fetch(url, options).then(res => res.json())
   
    // get unique categors for Collapse Groups
    const categories  = [...new Map(data.data.data.data.filter( (value, index, self) => self[index].isActive)
      .map(item => 
         [item['category'], {category: item.category, color: item.categoryColor, isActive: item.isActive, canEdit: item.canEdit}]))
      .values()]

    setCategories(categories)

    // records
    setRecords(state.ids.length > 0 ? _.filter(data.data.data.data, r => state.ids.includes(r.id)) : data.data.data.data)

    return data.data.data.data
  }
  const key = `${store.get("api")}/api/shifttemplate/list`
  const { data, mutate } = useSWR(key, fetcher, { initialData: state.initialRecords })

  const [shift, setShift] = useState({
    employeeNumber: store.session.get("CurrentUser").number,
    employee: store.session.get("CurrentUser"),
    userRole: store.session.get("role"),
    available: false,
    generateTimeEntry: true,
    superNumber1: _.find(store.get("Employees"), { number: store.session.get("CurrentUser").supervisorNumber }).number,
    supervisor1: _.find(store.get("Employees"), { number: store.session.get("CurrentUser").supervisorNumber }),
    end: null,
    isValid: false,
  })

  
  const updateCard = async (props) => {


    if (props.field === 'duration') {

      const existingFields = newShift[props.id] 
      const updates = existingFields ? {...existingFields, duration: props.value, validDuration: true  } : {duration: props.value, validDuration: true}
      const merged = {[props.id]:updates }
      setNewShift({...newShift, ...merged})

    }
    if (props.field === 'date') {

      const existingFields = newShift[props.id] 
      const updates = existingFields ? {...existingFields, startDate: props.value, validStartDate: true } :{startDate: props.value, validStartDate: true }
      const merged = {[props.id]:updates }

      setNewShift({...newShift, ...merged})

    }
    if (props.field === 'startTime') {
     
      const existingFields = newShift[props.id]
      const updates= existingFields ? {...existingFields ,startTime: props.value.format('HH:mm'), validStartTime: true } : { [props.id]: {startTime: props.value.format('HH:mm'),validStartTime: true }}
      const merged = {[props.id]:updates }

      setNewShift({...newShift, ...merged  })
    }

    if (props.field === 'start') {
    
      const existingFields = {...[props.id], start: props.value.format()}
      const updates = existingFields ? {...existingFields ,start: props.value.format(), validStartDate: true } : { [props.id]: {start: props.value.format(), validStartDate: true}}
      const merged = {[props.id]:updates}

      setNewShift({...newShift, ...merged })
    }

    if (props.field === 'employeeNumber') {
      // get employee record from
        const employee = await REQ(`/api/employees/${props.value}`, "GET")
        const existingFields = newShift[props.id] 
        const updates = existingFields ? 
          {...existingFields ,employeeNumber: props.value, employee: employee, validEmployee: true } 
          : 
          { employeeNumber: props.value, employee: props.employee, validEmployee: true }
        const merged = {[props.id]:updates }

      setNewShift({...newShift, ...merged })
    }

    if (props.field === 'superNumber1') {
    // get supervisor1 record from
     const  supervisor1 = await REQ(`/api/employees/${props.value}`, "GET")   
     const existingFields = newShift[props.id] 
     const updates = existingFields ? 
       {...existingFields ,superNumber1: props.value, supervisor1: supervisor1, validSupervisor1: true } 
       : 
       { superNumber1: props.value, supervisor1: supervisor1, validSupervisor1: true }
     const merged = {[props.id]:updates}

     setNewShift({...newShift, ...merged })
   }

  }

  const createshift = async (props) => {
   
    //console.log("createshift", shift)
    // props.target.id - this is the template id..... 
    // newShift.id is data for shift create.....

    const templateId = props.target.id
    const evidenceShift =  records.find( r => r.id === templateId).shiftJson.category.toLowerCase().includes('evidence') ? true : false

     // check for defuault duration on template update
     const shiftDuration = records.filter( r => r.id === templateId)[0].shiftJson
     const validShiftDuration = shiftDuration.timeEntries.filter( t => parseFloat(t.duration) > 0).length > 0 ? true : false

    const validStartDate = newShift[templateId] ? typeof newShift[templateId].startDate  === 'undefined' ? false : true : false
    const validStartTime = evidenceShift ? true : newShift[templateId] ? typeof newShift[templateId].startTime === 'undefined' ? false : true : false
    const validDuration = evidenceShift || validShiftDuration ? true : newShift[templateId] ? typeof newShift[templateId].duration === 'undefined' ? false : true : false

    const existingFields = newShift[templateId]
    const updates= existingFields ? {...existingFields , validStartTime: validStartTime, validStartDate: validStartDate, validDuration: validDuration} : 
    { validStartTime: validStartTime, validStartDate: validStartDate, validDuration: validDuration}
    const merged = {[templateId]: updates }

    setNewShift({...newShift, ...merged})

   

    //if (newShift[templateId].duration > 24) validDuration = false 

    if (!newShift[templateId] || !validStartDate || !validStartTime || !validDuration ) {

        const descStartDate = validStartDate ? '' : 'Start Date Required'
        const descStartTime = validStartTime ? '' : 'Start Time Required - must press BLUE OK button'
        const descDuration = validDuration ? '' : 'Duration/Hrs Required or more than allowed'

        notification.open({
          message: "Missing Required Fields",
          description: <><p>{descStartDate}</p><p>{descStartTime}</p><p>{descDuration}</p></>,
          placement: "topRight",
          duration: 4,
        })

        return false

    } 

    //const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const startTime = evidenceShift ? '08:00' : newShift[templateId].startTime 
    let localDateTime = `${newShift[templateId].startDate}T${startTime}`
    let localDateTimeUTC = moment(localDateTime).utc().format()
    
    newShift[templateId].start = localDateTimeUTC

    //shift.start = moment(_shift.start).utc().format("YYYY-MM-DDTHH:mm")
    //shift.end  = moment(_shift.end).utc().format("YYYY-MM-DDTHH:mm")

    const templateData = newShift[templateId]
    
    // merge with user input with temaplate
    const templateJson = records.find( r => r.id === templateId).shiftJson

    // use alternate pay type from template
    if (useCompPayType[templateId]) {
      templateJson.payTypeCode = templateJson.payTypeCodeAlt ? templateJson.payTypeCodeAlt : templateJson.payTypeCode
    }

    if (templateData && !templateData.employeeNumber) {
      templateData.employeeNumber = newShift.employeeNumber
      templateData.employee = newShift.employee ? newShift.employee : []
    }
    if (templateData && !templateData.superNumber1) {
      templateData.superNumber1 = newShift.superNumber1
      templateData.supervisor1 = newShift.supervisor1 ? newShift.supervisor1 : []
    }


    let mergedShift = {...templateJson, ...templateData}

    // get user data merged
    //mergedShift = {...mergedShift, ...shift}
    mergedShift = {...shift, ...mergedShift }

    // fix durations
    mergedShift.durationOg = mergedShift.payTypeCode === '250' || mergedShift.payTypeCode === '251'? parseFloat(0).toFixed(2) : parseFloat(mergedShift.duration).toFixed(2)
    mergedShift.durationOt = parseFloat(0).toFixed(2)
    mergedShift.duration = mergedShift.payTypeCode === '250' || mergedShift.payTypeCode === '251'? parseFloat(0).toFixed(2) : parseFloat(mergedShift.duration).toFixed(2)


    // clean up temp id's etc we were using
    delete mergedShift.i
    delete mergedShift.id
    delete mergedShift.startDate
    delete mergedShift.startTime

    setShift({...shift, ...templateData})

    // check if shift is locked
    const _checkLockDate = moment(mergedShift.start).utc().format('YYYY-MM-DDTHH:MM')
    //const payPeriodLocked = await REQ(`/api/pay_period/locked/${shift.start}`, "GET")
    const payPeriodLocked = await REQ(`/api/pay_period/locked/${_checkLockDate}`, "GET")

    const isLocked = payPeriodLocked.payPeriodLocked ? payPeriodLocked.payPeriodLocked : false

    if (isLocked) {
      // notify user
      notification.open({
        message: "Date chosen is in a LOCKED Pay Period",
        description: `Date ${mergedShift.start} is in a CLOSED or LOCKED Pay Period.  Please select a future date. `,
        placement: "topRight",
        duration: 5,
      })

      mergedShift.isValid = false
      setState({ ...state, loading: false })

    } else {

        setState({ ...state, loading: true })
    
        if (!mergedShift.end && mergedShift.start) {
          mergedShift.end = moment(mergedShift.start).add(mergedShift.duration, "hours")
          //shift.end = moment(shift.start).tz('utc').add(shift.duration, "hours")
        }

        mergedShift.start = moment(mergedShift.start).utc().format("YYYY-MM-DDTHH:mm")
        mergedShift.end  = moment(mergedShift.end).utc().format("YYYY-MM-DDTHH:mm")

        if (!mergedShift.paidLunch) {
          //shift.duration = parseInt(shift.duration) + .50
          //shift.durationOg = parseInt(shift.duration)
          let resultLunch = parseFloat(mergedShift.duration) + parseFloat('.5');
          mergedShift.duration = parseFloat(resultLunch).toFixed(2)
          mergedShift.durationOg = parseFloat(resultLunch).toFixed(2)
          mergedShift.isOT ? mergedShift.durationOt = parseFloat(resultLunch).toFixed(2) : mergedShift.durationOt = 0      

          mergedShift.paidLunch = false
          mergedShift.end = moment(mergedShift.end).add(.5, "hours").format("YYYY-MM-DD HH:mm")
        }

        // handle assignmnet
        //const assignments = 
        if (mergedShift.assignmentId) {
          const _assignmentId = mergedShift.assignmentId.toLowerCase().replace(/[yY]/g,'i')
          REQ(`/api/search/assignment?search=${_assignmentId}&returning=payTypeCode&returning=name&returning=id&returning=jobStepCode&returning=jobStepTimecardOverride&returning=payTypeCode`, "GET")
          .then(assignments => {
            if (assignments) {
              for (let a of assignments) {
                if ( a.payTypeCode === props.payTypeCode) {
                  setShift({ ...shift, assignmentId: a.id, jobStepCode: a.jobStepCode, jobCode: a.jobCode })
                  mergedShift={...mergedShift, assignmentId: a.id, jobStepCode: a.jobStepCode, jobCode: a.jobCode }
                  //return a.id
                }
              }
            }
      
          })
        }

        // pre-fill resaon code
      const reasons = await REQ("/api/search/reason?search=Evidence&returning=id&returning=description&returning=category&returning=payTypeCode", "GET")
      if (reasons) {
        for (let r of reasons) {
          if ( r.payTypeCode === props.payTypeCode) {
            shift.reasonId = r.id
            mergedShift.reasonId = r.id
            setShift({ ...shift, reasonId: r.id })
          }
        }
      }
   

        // give it a unique name for handling how we created.
        //shift.name = 'NEW SHIFT BUTTON'
        mergedShift.name = `NEW SHIFT BUTTON ${templateId}`
        mergedShift.shiftTemplateId = templateId

        // need to check if isDive or isFTO
        if (mergedShift.isDive) {
          mergedShift.diveHoursIncluded = true
        }

        if (mergedShift.isFieldTraining) {
          mergedShift.ftoHoursIncluded = true
        }

        const created = await REQ("/api/shifts/create", "POST", mergedShift)

        setState({ ...state, loading: false })
        created && context.setNewlyCreatedShift(created)
      }
  }

  useEffect(() => {

    const payType = context.getPayTypeRecord("PayTypes", props.payTypeCode, "code")
    const shiftType = payType.description
    const payTypeCategory = payType.category.toLowerCase()
    const payTypeDesription = payType && payType.rename ? payType.rename : payType.description
    //const title = `New ${payType ? payType.description : ""} shift`
    //const title = `New ${payTypeDesription ? payTypeDesription : ""} shift`
    setNewShift({...newShift, payTypeCategory: payTypeCategory, payTypeCode: props.payTypeCode, payTypeDesription: payTypeDesription})
    const title = 'Create New Shifts'

    // start a
    
    let allTags = state.tagIds

    //const payTypeCodeName  = payType.code === '250' ? 'Evidence Standby 2hr' : 'Evidence Standby 4hr'

    let initialTagId
    let evidenceDisabledField = false
    if (payType.code === '250' || payType.code === '251') {
      let payTypeDesc = ''
      if (payType.code === '251') {
        payTypeDesc = 'Evidence Standby 4hr' 
        evidenceDisabledField = true
      }
      if (payType.code === '250') {
        payTypeDesc = 'Evidence Standby 2hr'
        evidenceDisabledField = true
      }

      const tags = context.getRecords('Tags')
      initialTagId =  _.find(tags, r => r['name'] === payTypeDesc)

      if (isArray(allTags)) {
        if (initialTagId.id in allTags) {
          // nothing
        } else {
          allTags.push(initialTagId.id)
        }         
      } else {
        allTags = [initialTagId.id]
      }
   
    }
    
    let _initialAssignmentId
    //const assignments = 
    REQ("/api/search/assignment?search=Evidence&returning=payTypeCode&returning=name&returning=id", "GET")
    .then(assignments => {
      if (assignments) {
        for (let a of assignments) {
          if ( a.payTypeCode === props.payTypeCode) {
             _initialAssignmentId= a.id
             setShift({ ...shift, assignmentId: a.id })
            //return a.id
          }
        }
      }

    })

    setState({ ...state, title, payType, shiftType, payTypeCategory, assignmentId: _initialAssignmentId, tagIds: allTags, tags: allTags, evidenceDisabledField: evidenceDisabledField, loading: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payTypeCode])

  useEffect(() => {
    // records
    if (data) {
      setRecords(state.ids.length > 0 ? _.filter(data, r => state.ids.includes(r.id)) : data)
      mutate()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

useEffect(() => {
  //console.log('useCompTime -> ',useCompPayType)
}, [useCompPayType])
  
//useEffect(() => {
 //   console.log('xx')
 //   fetch(key)
 //   setState({...state})
 // }, [])

  useEffect(() => {
    Emitter.on("TemplateDrawerClose", () => {
      mutate()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //console.log('newShift')
  }, [newShift])

  const { Panel } = Collapse;

  const CollapseGroups = () => {

    const panels = []
    const sortedCategories = _.sortBy(categories.filter(c => c.isActive), 'category')


    sortedCategories.forEach((c,i) => panels.push(
      <Panel header={ <><div className="site-collapse-custom-panel box" style={{backgroundColor: c.color, overflowX: 'scroll !important'}}></div>&nbsp;&nbsp;{c.category}</>} key={i+1} >
        <div key={uniqid()} className="site-card-border-less-wrapper" style={{backgroundColor: c.color, overflowX: 'scroll' }} >
            <Row key={uniqid()} gutter={16}>  
              <BuildCards key={uniqid()} group={c.category} />
            </Row>
        </div>
      </Panel>
     ))

    return (
      <Collapse key={uniqid()} accordion  defaultActiveKey={openPanel} onChange={(key)=> setOpenPanel(key)} style={{overflowX: 'scroll !important' }} >
        {panels}
      </Collapse>
    )

  }

  const showMoreFields = () => {
    setShowMore(!showMore)
  }

  const BuildCards = (props) => {
  
    const { group } = props
    const cards = [] 

    let groupRecs = []
    if (Array.isArray(records)) {
      groupRecs = _.sortBy(records.filter(r => r.category === group && r.isActive), ['name','payTypeCode'])
    }


    const getPayTypes = (p) => {
      const payType = context.getPayTypeRecord("PayTypes", p, "code")
      //const payTypeCategory = payType.category.toLowerCase()
      const payTypeDescription = payType && payType.rename ? payType.rename : payType.description
      return payTypeDescription

    }

     const info = (props) => {

        const {title, value } = props.currentTarget
        Modal.info({
          title: `New ${title}  Shift Info`,
          content: (
            <div>
              <p>{value} </p>
            </div>
          ),
          onOk() {},
        })
     }

    groupRecs.forEach( (t) => { 
      if (t.isActive) {

       
        const evidenceShift = () => {
          let isEvidenceShift = false
          if (t && t.shiftJson.timeEntries) {
            isEvidenceShift = t.category.toLowerCase().includes('evidence')  || ['250','251'].some(e => t.shiftJson.payTypeCode.includes(e)) ? true : false 
          }
          return isEvidenceShift
        }
      
        const durationFromTemplate = () => {
          
          let hasDuration = false

          if (t && t.shiftJson.timeEntries) {
            hasDuration = t.shiftJson.timeEntries.filter( t => parseFloat(parseFloat(t.duration).toFixed(2)) > 0 ).length > 0 ? true : false 
            if (hasDuration && newShift[t.id] && newShift[t.id].validStartDate && newShift[t.id].validStartTime) {
              hasDuration = true
            }
          }
          return hasDuration
        }

        const buttonDisabled = () => {
          if (newShift[t.id]) {
            if (t.category.toLowerCase().includes('evidence')) {
              if (newShift[t.id].validStartDate) {
                return  false
              } else {
                return true
              }
            } else if ((newShift[t.id].validDuration && newShift[t.id].validStartDate && newShift[t.id].validStartTime) 
               || (durationFromTemplate() && newShift[t.id].validStartDate && newShift[t.id].validStartTime)) {
           // } else if ( durationFromTemplate() && newShift[t.id].validStartDate && newShift[t.id].validStartTime) {
                return  false
            } else {
            return true
           } 
          } else {
            return true
          }
        }
        const buttonType = () => {
          if (newShift[t.id]) {
            if (t.category.toLowerCase().toLowerCase().includes('evidence')) {
              if (newShift[t.id].validStartDate) {
                return  'primary'
              } else {
                return 'default'
              }
           } else if ((newShift[t.id].validDuration && newShift[t.id].validStartDate && newShift[t.id].validStartTime) || durationFromTemplate()) {
           // } else if ( durationFromTemplate() && newShift[t.id].validStartDate && newShift[t.id].validStartTime) {
                return  'primary'
            } else {
            return 'default'
           } 
          } else {
            return 'default'
          }
          
        }
        cards.push(
          <Col key={uniqid()} span={8}>
            <Card 
              bordered={false}
              hoverable={false}
              key={uniqid()}
              //style={{ width: 300, marginTop: 16 }}
              style={{  marginTop: 16, padding: 0 }}
              size="small" 
              title={t.name} 
              loading={state.loading}
              extra={
                <div>
                  <ButtonGroup>
                  <Tooltip title='New Shift Wiki Help' >
                    <Modal
                      title={`New ${t.name} Shift Info`}
                      visible={isInfoVisible}
                      onOk={() => setIsInfoVisible(false) }
                      onCancel={()=> setIsInfoVisible(false)}
                    >
                      {t.description}
                    </Modal>
                    <Button
                      disabled={false}
                      icon="question-circle"
                      type={"default"}
                      size="small"
                      //onClick={()=> setIsInfoVisible(true)}
                      onClick={() => window.open("https://psrswiki.enterprisetechnologies.com/en/Operations/Daily#e-creating-unscheduled-shifts-callback-overtime-court-etc", "_blank") }
                    />
                  </Tooltip>
                  <Button
                    disabled={false}
                    icon="info-circle"
                    type={"default"}
                    size="small" 
                    id={t.id}
                    value={t.description}
                    title={t.name}
                    onClick={info}
                    />
                  </ButtonGroup>
                </div>
              }          
            >
              <Row key={uniqid()}> 
                  {showMore && < >
                  <Row key={uniqid()}>
                      <Col key={uniqid()} span={30}>
                        <div className="supervisorSelect flex1">
                          <h4>Employee</h4>
                          <EmployeeSelect 
                            id={t.id}
                            //disabled={ store.session.get("role") === 'employee' ? true : false }
                            disabled={cantEditEmployee}
                            employee={newShift && newShift[t.id] && newShift[t.id].employee ? newShift[t.id].employee 
                              : t && t.shiftJson && t.shiftJson.employeeNumber ? t.shiftJson.employeeNumber : shift.employee }
                            field="employeeNumber" 
                            handleChange={updateCard} />
                        </div>
                      </Col>
                      </Row>
                      <Row key={uniqid()}>
                        <br />
                        <Col key={uniqid()} span={30}>
                          <div className="supervisorSelect flex1">
                            <h4>Supervisor</h4>
                            <SupervisorSelect 
                              //supervisor={ t.supervisor1 } 
                              id={t.id}
                              supervisor={newShift && newShift[t.id] && newShift[t.id].supervisor1 ? newShift[t.id].supervisor1 
                                : t && t.shiftJson && t.shiftJson.superNumber1 ? t.shiftJson.superNumber1 : shift.supervisor1 }
                              field="superNumber1" 
                              handleChange={updateCard} />
                          </div>
                        </Col>
                    </Row>
                    <br />
                  </>}
            </Row>
            <Row key={uniqid()}>
                <Col key={uniqid()} span={6}> 
                    <strong>Pay Type: </strong>
                </Col>
                <Col key={uniqid()} span={14}>
                  <div>
                    {useCompPayType[t.id] ? t.payTypeCodeAlt : t.payTypeCode} 
                  </div>
                </Col>
                <Col key={uniqid()} span={18}>
                  <div key={uniqid()}>
                    <small>{useCompPayType[t.id] ? getPayTypes(t.payTypeCodeAlt) : getPayTypes(t.payTypeCode)}</small>
                  </div>
                  <div key={uniqid()}>
                    <strong>Comp Time{' '}</strong>
                    <Switch 
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={useCompPayType[t.id]} 
                      checked={useCompPayType[t.id]} 
                      disabled={t.payTypeCodeAlt ? false : true}
                      onChange={(checked)=>setUseCompPayType({...useCompPayType, [t.id]: checked })}
                      //onClick={(checked, event)=>setUseCompPayType(checked) }
                    />  
                </div>
                </Col>
              </Row>
              <Row key={uniqid()}>
                <Col key={uniqid()} span={10}>
                  <div key={uniqid()} className="dateTimePicker flex1">
                    <br />
                    <label className={newShift && newShift[t.id] ? newShift[t.id].validStartDate ? 'new-shift-label' : 'new-shift-error-label' :'new-shift-label' }>Start Date</label>
                    <DateFilter 
                      field="startTime" 
                      payTypeCode={useCompPayType[t.id] ? t.payTypeCodeAlt : t.payTypeCode}
                      id={t.id} 
                      format='MM-DD-YYYY'
                      value={newShift && newShift[t.id] ? newShift[t.id].startDate: moment().format()}
                      //value={newShift && newShift ? newShift.startDate : moment().format('MM-DD-YYYY')}
                      handleChange={updateCard} />
                  </div>  
                </Col>
                <Col key={uniqid()} span={10}>
                  <div key={uniqid()}> 
                  <br />
                    <label className={ t.category && t.category.toLowerCase().includes('evidence') ? 'new-shift-evidence' : newShift && newShift[t.id] ? newShift[t.id].validStartTime ? 'new-shift-label' : 'new-shift-error-label' : 'new-shift-label'}>Start Time</label>
                    {/*<TimePicker defaultValue={moment('12:08', 'HH:mm')} format={'HH:mm'} onBlur={updateCard}/>*/}
                    <StartTimeFieldT
                      field="startTime"
                      payTypeCode={useCompPayType[t.id] ? t.payTypeCodeAlt : t.payTypeCode}
                      id={t.id}
                      format='HH:mm'
                      allowClear={true}
                      //--orginal-- value={newShift && newShift[t.id] && newShift[t.id].startTime ? moment(newShift[t.id].startTime, 'HH:mm') : moment()}
                      value={newShift && newShift[t.id] && newShift[t.id].startTime ? moment(newShift[t.id].startTime, 'HH:mm') :t.payTypeCode.includes('250') || t.payTypeCode.includes('251') ? moment('T08:00', 'HH:mm') : moment()}
                      //value={newShift && newShift && newShift.startTime ? moment(newShift.startTime, 'HH:mm') : moment()}
                      handleChange={updateCard}
                      disabled={t.shiftJson && t.shiftJson.payTypeCode && ['250','251'].some(e => t.shiftJson.payTypeCode.includes(e)) ? true : false}
                    />
                     {/*<StartTimeField
                        field="start"
                        id={t.id}
                        payTypeCode={useCompPayType[t.id] ? t.payTypeCodeAlt : t.payTypeCode}
                        //value={{ start: Shift.start, end: Shift.end }}
                        value={
                          newShift && newShift[t.id] && newShift[t.id].start ? 
                          {
                            start: moment(newShift[t.id].start),
                            //end: moment().format('HH:mm') //newShift[t.id].end 
                          } : {
                                 start: moment(), 
                                 //end: moment() 
                              }
                        }
                        handleChange={updateCard}
                        disabled={false}
                      />*/}
                  </div>
                </Col>
              </Row>
              <Row key={uniqid()}>
                <Col key={uniqid()} span={8}>
                  <br />
                  {/* <div key={uniqid()} className="durationField flex1" ><label className={t.category.toLowerCase() === 'evidence' ? 'new-shift-evidence'  : newShift[t.id] ? newShift[t.id].validDuration ? 'new-shift-label' : 'new-shift-error-label' : 'new-shift-label' }>Duration</label> */}
                  <div key={uniqid()} className="durationField flex1" ><label className={durationFromTemplate() || evidenceShift() ? 'new-shift-label' :  newShift[t.id] ? newShift[t.id].validDuration ? 'new-shift-label' : 'new-shift-error-label' : 'new-shift-label' }>Duration</label>
                      <DurationField
                        field="duration"
                        payTypeCode={useCompPayType[t.id] ? t.payTypeCodeAlt : t.payTypeCode}
                        id={t.id}
                        //orig -- value={newShift && newShift[t.id] && newShift[t.id].duration ? parseFloat(newShift[t.id].duration).toFixed(2) : parseFloat('0').toFixed(2)}
                        // evidence only -- value={newShift && newShift[t.id] && newShift[t.id].duration ? parseFloat(newShift[t.id].duration).toFixed(2) 
                        //   : t && t.shiftJson && t.shiftJson.payTypeCode && ['250','251'].some(e => t.shiftJson.payTypeCode.includes(e)) ? parseFloat(t.shiftJson.duration).toFixed(2) : parseFloat('0').toFixed(2)}
                        value={newShift && newShift[t.id] && newShift[t.id].duration ? parseFloat(newShift[t.id].duration).toFixed(2) 
                          : t && t.shiftJson && t.shiftJson.payTypeCode ? parseFloat(t.shiftJson.duration).toFixed(2) : parseFloat('0').toFixed(2)}
                        handleChange={updateCard}
                        //disabled={t.shiftJson && t.shiftJson.payTypeCode && ['250','251'].some(e => t.shiftJson.payTypeCode.includes(e)) ? true : false}
                        disabled={evidenceShift()}
                      />
                    </div>
                </Col>
              </Row>
              <Row key={uniqid()}>               
                <Col key={uniqid()} span={10}>
                  <br />
                    <Button 
                       onClick={createshift}
                       id={t.id} 
                       type={buttonType()}
                       //openShift={false} 
                       //disabled={newShift[t.id] ? newShift[t.id].validDuration && newShift[t.id].validStartDate && newShift[t.id].validStartTime ? false : true : true}
                       disabled={buttonDisabled()}
                    >
                      Create Shift
                    </Button>
                </Col>
                <Col key={uniqid()} span={10}>
                <br />
                <Button  type="primary"  icon="more" ghost={true} onClick={showMoreFields}>
                  {!showMore ? 'Show More ' : 'Show Less '}     
                </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )
      }
    })

    return cards
}

  const CardContents = () => {
    return <CollapseGroups key={uniqid()}></CollapseGroups>
  }


  const drawerProps = useMemo(() => {
    const opts = {
      open: context.newShiftDrawer === 0 ? false : true,
      visible: "visible",
      className: "ShiftDrawer NewShiftDrawer",
      closable: true,
      mask: true,
      maskClosable: true,
      title: false,
      width: 800,
      height: 400,
      zIndex: 4,
      placement: "right",
      destroyOnClose: true,
      onClose: e => context.setNewShiftDrawerPayTypeCode(0),
    }
    return opts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.newShiftDrawer])

  return (
    <React.Fragment>
      <Drawer {...drawerProps} title={state.title}>
        <Keyboard
          target="document"
          onEsc={() => {
            //console.log("ESCAPE")
            context.setShiftDrawerId(0)
          }}
        >
          <CardContents />
        </Keyboard>
      </Drawer>
    </React.Fragment>
  )
}

export default NewShiftDrawer
