import { log, store, session } from "./store/psrs"
export const routes = () => {
  const user = session.get("CurrentUser")
  const employeeNumber = user ? user.number : "12345"
  const empNum = employeeNumber
  const list = [
    {
      path: "/admin/monitor",
      title: "Monitoring",
      page: "Monitor",
      roles: ["admin"],
      initialData: [],
      exact: true,
      component: "Monitor",
      url: `${store.get("api")}/monitor?isAdmin = ${user && user.role && user.role === 'admin' ? true : false}`,
    },
    {
      path: "/search",
      title: "Search",
      page: "SearchView",
      roles: ['all'],
      initialData: [],
      exact: true,
      component: "SearchView",
      url: `${store.get("api")}/api/search`,
    },
    {
      path: "/approvals/shifts",
      title: "Shifts to Approve",
      page: "ShiftApprovals",
      roles: ['all'],
      initialData: [],
      exact: true,
      //component: "Approvals",
      //url: `${store.get("api")}/api/approvals/shift`,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      //query: ppID =>
      //  `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "needsApproved" = true AND "employeeNumber" is not null AND ("employeeNumber"='${empNum}' OR "superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`,
      query: ppID =>
      `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("needsApproved" = true OR "submit" = true) AND "employeeNumber" is not null AND ("employeeNumber"='${empNum}')`,

    },
    {
      path: "/approvals/timeEntries",
      title: "Time Entries to Approve",
      page: "TimeEntryApprovals",
      roles: ['all'],
      initialData: [],
      exact: true,
      //component: "Approvals",
      //url: `${store.get("api")}/api/approvals/timeEntry`,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/timeEntries`,
      //query: ppID =>
      //  `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "needsApproved" = true AND ("employeeNumber"='${empNum}' OR "superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`,
      query: ppID =>
      `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "needsApproved" = true AND ("employeeNumber"='${empNum}' )`,

    },
    {
      path: "/admin/assignments",
      title: "Assignments",
      page: "Assignments",
      roles: ['admin','supervisor'],
      initialData: [],
      exact: true,
      component: "Assignments",
      url: `${store.get("api")}/api/resources/assignments`,
    },
    {
      path: "/admin/locations",
      title: "Locations",
      page: "Locations",
      roles: ['admin'],
      initialData: [],
      exact: true,
      component: "Locations",
      url: `${store.get("api")}/api/resources/locations`,
    },
    {
      path: "/admin/b_units",
      title: "Business Units",
      page: "BusinessUnits",
      roles: ['admin'],
      initialData: [],
      exact: true,
      component: "BusinessUnits",
      url: `${store.get("api")}/api/resources/businessUnit`,
    },
    { 
      path: "/admin/employees/:num", 
      title: "Employee", 
      page: "employee", 
      roles: ['all'],
      initialData: {}, 
      exact: true, 
      component: "Employee" },
    {
      path: "/admin/employees",
      title: "Employees",
      page: "Employees",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Employees",
      url: `${store.get("api")}/api/employees`,
    },
    {
      path: "/admin/equipment",
      title: "Equipment",
      page: "Equipment",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Gizmos",
      url: `${store.get("api")}/api/resources/gizmo`,
    },
    {
      path: "/admin/groups",
      title: "Groups",
      page: "Groups",
      roles: ['admin'],
      initialData: [],
      exact: true,
      component: "Tags",
      url: `${store.get("api")}/api/resources/tag`,
    },
    {
      path: "/admin/paytypes",
      title: "Pay Types",
      page: "PayTypes",
      roles: ['admin'],
      initialData: [],
      exact: true,
      component: "PayTypes",
      url: `${store.get("api")}/api/resources/payType`,
    },
    {
      path: "/admin/picklists",
      title: "Picklist",
      page: "Picklists",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Picklists",
      url: `${store.get("api")}/api/picklist/list`,
    },
    {
      path: "/admin/reasons",
      title: "Reasons",
      page: "Reasons",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Reasons",
      url: `${store.get("api")}/api/resources/reason`,
    },
    {
      path: "/admin/specialties",
      title: "Specialties",
      page: "Specialties",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Specialties",
      url: `${store.get("api")}/api/resources/specialty`,
    },
    {
      path: "/admin/shift_templates",
      title: "Shift Templates",
      page: "ShiftTemplates",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "ShiftTemplates",
      url: `${store.get("api")}/api/resources/specialty`,
    },
    {
      path: "/admin/sub_ledgers",
      title: "Sub Ledgers",
      page: "SubLedgers",
      roles: ['admin'],
      initialData: [],
      exact: true,
      component: "SubLedgers",
      url: `${store.get("api")}/api/resources/subLedger`,
    },
    {
      path: "/payperiodstats",
      title: "Pay Periods Stats",
      page: "PayPeriodStats",
      roles: ['all'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/time_entries/stats`,
      query: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "needsApproved" IS TRUE AND "employeeNumber"='${empNum}'`,
    },
    {
      path: "/reports",
      title: "Reports",
      page: "Reports",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Reports",
      url: `${store.get("api")}/api/shifts`,
    },
    {
      path: "/schedule/timeline",
      title: "Schedule Timeline",
      page: "Timeline",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "ScheduleTimeline",
      url: `${store.get("api")}/api/shifts`,
    },
    {
      path: "/schedule/test",
      title: "Schedule Timeline",
      page: "Timeline",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "ScheduleTest",
      url: `${store.get("api")}/api/shifts`,
    },
    {
      path: "/shifts/requests",
      title: "My Shift Approvals",
      page: "UnapprovedShifts",
      roles: ['all'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/shifts`,
      query: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "needsApproved" IS TRUE AND "employeeNumber"='${empNum}'`,
    },
    {
      path: "/shifts/available",
      title: "Available Shifts",
      page: "AvailableShifts",
      roles: ['all'],
      initialData: [],
      exact: true,
      component: "PowerView",
      //url: `${store.get("api")}/api/raw_query/shifts`,
      url: `${store.get("api")}/api/shifts/available`,
      //query: userTimeZone => `SELECT id FROM "shifts" WHERE "available" IS TRUE AND "start" >= (now() - INTERVAL '30 DAY') at time zone '${userTimeZone}'`,
      //query: userTimeZone => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "available" IS TRUE AND "start" >= (now()::date) and start <=(now()::date + INTERVAL '30 DAY') at time zone '${userTimeZone}'`,
      //query: userTimeZone => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "available" IS TRUE `,
      query: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "available" is TRUE and "takingBids" is TRUE AND "employeeNumber" IS NULL AND "payPeriodId"='${ppID}' AND "locked" IS NOT TRUE`,

    },
    {
      path: "/shifts/bidderworkbench",
      title: "Available Shifts Workbench",
      page: "AvailableShiftsWB",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      //url: `${store.get("api")}/api/shifts/supervisor_available`,
      //query: userTimeZone => `SELECT id FROM "shifts" WHERE "available" IS TRUE AND "start" >= (now() - INTERVAL '30 DAY') at time zone '${userTimeZone}'`,
      //query: userTimeZone => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "available" IS TRUE AND "start" >= (now()::date) and start <=(now()::date + INTERVAL '30 DAY') at time zone '${userTimeZone}'`,
      query: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "employeeNumber" IS NULL AND "payPeriodId"='${ppID}' AND ("superNumber1" = '${empNum}' OR "superNumber2" = '${empNum}' OR "superNumber3" = '${empNum}') AND "start"::date >= now()::date AND "locked" IS NOT TRUE`,
      adminQuery: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "employeeNumber" IS NULL AND "payPeriodId"='${ppID}' AND "start"::date >= now()::date AND "locked" IS NOT TRUE`,
    },
    {
      path: "/shifts/mine",
      title: "My Shifts",
      page: "MyShifts",
      roles: ['all'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      query: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "employeeNumber"='${empNum}'`,
    },
    {
      path: "/shifts/supervised",
      title: "All Supervised Shifts",
      page: "SupervisedShifts",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      query: ppID =>
        `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`,
    },
    {
      path: "/shifts/direct",
      title: "Direct Supervised Shifts",
      page: "DirectSupervisedShifts",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      query: ppID =>
        `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("superNumber1"='${empNum}')`,
    },
    {
      path: "/timeentries/supervised",
      title: "All Supervised Time Entries",
      page: "SupervisedTimeEntries",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/timeEntries`,
      query: ppID =>
        `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`,
    },
    {
      path: "/timeentries/direct",
      title: "Direct Supervised Shifts",
      page: "DirectSupervisedTimeEntries",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/timeEntries`,
      query: ppID =>
        `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("superNumber1"='${empNum}')`,
    },
    {
      path: "/timeentries/mine",
      title: "My Time Entries",
      page: "MyTimeEntries",
      roles: ['all'],
      initialData: [],
      exact: true,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/timeEntries`,
      query: ppID => `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND  "payPeriodId"='${ppID}' AND "employeeNumber"='${empNum}' `,
    },
    {
      path: "/test/formComponents",
      title: "Form Components",
      page: "formComponents",
      roles: ['admin', 'supervisor'],
      initialData: {},
      exact: true,
      component: "FormComponents",
    },
    { 
      path: "/settings/:num", 
      title: "Settings", 
      page: "settings", 
      roles: ['all'],
      initialData: {}, 
      exact: true, 
      component: "Employee" },
    {
      path: "/teams/all",
      title: "All Teams",
      page: "allTeams",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Teams",
      url: `${store.get("api")}/api/teams`,
    },
    {
      path: "/teams/mine",
      title: "My Teams",
      page: "myTeams",
      roles: ['admin', 'supervisor'],
      initialData: [],
      exact: true,
      component: "Teams",
      url: `${store.get("api")}/api/teams?supervisorNumber=${employeeNumber}`,
    },
    { 
      path: "/team/:teamId", 
      title: "Team", 
      page: "team", 
      roles: ['admin', 'supervisor'],
      initialData: {}, 
      exact: true, 
      component: "Team" },
    {
      path: "/team/:teamId/schedule/:templateId",
      title: "Schedule Template",
      page: "schedule",
      roles: ['admin', 'supervisor'],
      initialData: {},
      exact: true,
      component: "Template",
    },
    { path: "/records", title: "Records Viewer", page: "records",  roles: ['admin', 'supervisor'], initialData: [], exact: true, component: "RecordsViewer" },
    { path: "/logout", title: "Logout", page: "logout",  roles: ['all'],exact: true, component: "Home" },
    { path: "/nav", title: "App Nav", page: "nav",  roles: ['admin', 'supervisor'], initialData: [], exact: true, component: "AppNav" },
    { path: "/login", title: "Home", page: "login",  roles: ['all'], exact: true, component: "Home" },
    //{ path: "/faq", title: "FAQ", page: "faq", initialData: [], exact: true, component: "PSRS_FAQ.pdf"},
    //{ path: "/wiki", title: "Wiki", page: "Wiki", exact: true, component: "Wiki" },
    { path: "/admin/sessions", title: "Active Sessions", page: "sessions",  roles: ['admin', 'supervisor'], exact: true, component: "Sessions" },
    { path: "/", title: "Home", page: "home",  roles: ['all'],exact: true, component: "Home" },
    {
      path: "/approvalsV2/shifts",
      title: "Approval Workbench",
      page: "ShiftApprovalsV2",
      roles: ['all'],
      initialData: [],
      exact: true,
      //component: "Approvals",
      //url: `${store.get("api")}/api/approvals/shift`,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      //query: ppID =>
      //  `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("needsApproved" = true OR "approved" is true OR "rejected" is true) AND "employeeNumber" is not null AND ("superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`,
      query: ppID => `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "employeeNumber" is not null `
    },
    {
      path: "/approvalsV2/timeEntries",
      title: "Time Entries to Approve",
      page: "TimeEntryApprovalsV2",
      roles: ['all'],
      initialData: [],
      exact: true,
      //component: "Approvals",
      //url: `${store.get("api")}/api/approvals/timeEntry`,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/timeEntries`,
      query: ppID =>
        `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "needsApproved" = true AND ("superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`,
    },
    {
      path: "/approvalsV2/myShifts",
      title: "My Shifts to be Approve",
      page: "MyShiftApprovalsV2",
      roles: ['all'],
      initialData: [],
      exact: true,
      //component: "Approvals",
      //url: `${store.get("api")}/api/approvals/shift`,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/shifts`,
      query: ppID =>
        `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND ("needsApproved" = true OR "approved" = true OR "rejected" = true) AND "employeeNumber" is not null AND ("employeeNumber"='${empNum}' )`,
      },
    {
      path: "/approvalsV2/myTimeEntries",
      title: "My Time Entries to be Approve",
      page: "MyTimeEntryApprovalsV2",
      roles: ['all'],
      initialData: [],
      exact: true,
      //component: "Approvals",
      //url: `${store.get("api")}/api/approvals/timeEntry`,
      component: "PowerView",
      url: `${store.get("api")}/api/raw_query/timeEntries`,
      query: ppID =>
        `SELECT id FROM "timeentries" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "needsApproved" = true AND ("employeeNumber"='${empNum}')`,
    },
  ]
  log.g(`routes: ${list.length}`)
  return list
}
