import React, { useState, useEffect } from "react"
import { reqOptions, store, Can, /*Emitter*/ } from "../store/psrs"
import { useMyContext } from "../store/useMyContext"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"
import treeTableHOC from "react-table/lib/hoc/treeTable";
import { Button, Popconfirm, Tooltip, Icon, Layout, Tag, /*Select, Typography,*/} from "antd"

import _ from "lodash"
import uuid from "uuid"


import { Search, Toggle, InPut /*updateResources*/ } from "./Admin/fields"
import { deleteResource, createResource, createTemplateResource, updateResource, /*EditParagraph*/ } from "./Admin/fields"
//import { PayTypeSelect } from "./Forms/PayTypeSelect"
import { PicklistDrawer} from "./Picklists/PicklistDrawer" //<-- will be employee selector
import { GroupPivotSelect} from "./Picklists/GroupPivotSelect" //<-- will be a new group for 
import {NewShiftDrawer} from "./NewShiftDrawer" //<-- won't need this
import {ShiftDrawer} from "./ShiftDrawer" //<-- won't need this
import CategoryColorPicker from "./Forms/CategoryColorPicker"
import { CategorySelect } from "./Forms/CategorySelect"
import   ColorPickButton   from "./Forms/ColorPickButton"
//import { EmployeeSelect  } from "./Forms/EmployeeSelect"
import { ReasonSelect } from "./Forms/ReasonSelect"
import { LocationSelect } from "./Forms/LocationSelect"

//import _SelectOptions from "./Forms/_SelectOptions"

//const { Paragraph } = Typography;
const TreeTable = treeTableHOC(ReactTable);

const {Content} = Layout;
//const { Option } = Select;


export const Picklists = props => {
  
  const context = useMyContext()
  //const ability = useAbilityContext()

  const { picklistDrawerId } = context

  const pageTitle = "Picklist"
  const createTitle = "Create New Picklist"
  const isPivotSet = store.get("_pivot")

  const [state, setState] = useState({
    pk: "id",
    model: "picklist",
    ids: [],
    initialRecords: undefined, // store.get("_ShiftTemplates") || undefined,
  })

  const [records, setRecords] = useState(state.initialRecords || [])
  //const [newTemplate, setNewTemplate] = useState(null)
  const [newPicklist, setNewPicklist] = useState(null)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [color, setColor] = useState("#aabbcc")
  const [colorRowId, setColorRowId] = useState(0)
  const {shiftDrawerId, newShiftDrawer} = context

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  const [pivot, setPivot] = useState(isPivotSet !== null ? 'length' in isPivotSet ? isPivotSet.length > 0 ? isPivotSet : [] : [] : [])
  //const [pivot, setPivot] = useState([])


  const [pivotLoading, setPivotLoading] = useState(false)
  const [expandedIds, setExpandedIds] = useState({})

  //const MemoGroupPivotSelect = React.memo(GroupPivotSelect)

  // eslint-disable-next-line react-hooks/exhaustive-deps
/*   const employees = useMemo(() => { 
    return store.get("Employees").map(r => {      
        const employeeName = `${r.firstName} ${r.lastName}`
        return {
          key: r.number,
          title: employeeName,
          description: employeeName.toLowerCase(),
          label: r.fullName,
          role: r.role
        }
    })
    
  }) */

  //const filterOption = (inputValue, option) => option.description.indexOf(inputValue.toLowerCase()) > -1


  const createNewPicklist = async (props) => {
    ///const newPicklist = 
    await createResource(_props)
    //setNewTemplate(newPicklist)
    //context.setTemplateDrawerId(newPicklist.id)
  } 

  

  /* const buildCopyUpdate = async (field, value ) => {
     
    return { value: value, field: field }

  } */
  const copyNewPicklist = async (props) => {

    const {
      canEdit, 
      category, 
      categoryColor,
      description, 
      //isActive, 
      isOT, 
      name, 
      payTypeCode, 
      payTypeCodeAlt,
      shiftJson,  
    } = props.original

    // create new
    //const newTemplate = await createTemplateResource(_props)


    //const updateFields = []

    const copyPicklist = {
      name: `${name} - Copy`,
      description: description,
      category: category,
      categoryColor: categoryColor,
      isactive: false,
      canEdit: canEdit,
      isOT: isOT,
      payTypeCode: payTypeCode,
      payTypeCodeAlt: payTypeCodeAlt,
      shiftJson: shiftJson
    }
    /* updateFields.push(await buildCopyUpdate("canEdit", canEdit ))
    updateFields.push(await buildCopyUpdate("category", category ))
    updateFields.push(await buildCopyUpdate("categoryColor", categoryColor ))
    updateFields.push(await buildCopyUpdate("description", description ))
    updateFields.push(await buildCopyUpdate("isActive", false ))
    updateFields.push(await buildCopyUpdate("isOT", isOT ))
    updateFields.push(await buildCopyUpdate("name",`${name} - Copy` ))
    updateFields.push(await buildCopyUpdate("payTypeCode", payTypeCode ))
    updateFields.push(await buildCopyUpdate("payTypeCodeAlt", payTypeCodeAlt ))
    updateFields.push(await buildCopyUpdate("shiftJson", shiftJson ))

 */
    //const newTemplate = 
    await createTemplateResource({..._props, attributes: copyPicklist})

    // update shiftJson
    
    //await updateResources({..._props, id: newTemplate.id, updateFields: updateFields })
    //mutate(_props.url, [])
  } 

  const fetcher = async url => {
    setLoading(true)
    const options = { ...reqOptions, method: 'POST', body: JSON.stringify({options: { paginate: 1000}})}
    const data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length > 0 ? _.filter(data.data.data.data, r => state.ids.includes(r.id)) : data.data.data.data)
    setLoading(false)
    return data.data.data.data
  }

  const swrOpts = { revalidateOnFocus: false, revalidate: false, initialData: state.initialRecords }

  const key = `${store.get("api")}/api/picklist/list`
  //const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })
  const { data } = useSWR(key, fetcher, swrOpts)


  const _props = { 
    state, 
    setState, 
    mutate, 
    url: key,
    model: 'picklist',
    checkedChildren: <Icon type="check" />,
    unCheckedChildren: <Icon type="close" />,
   }


   //test here
  /* move to admin screens to refine edits by row */
  //const zrenderEditable = (value) => {
  //  return renderEditable.bind(value)
 // }
  
 /*
  const [zData, setZData] = useState(null)
  const renderEditable = (cellInfo) => {
    console.log('try it out')
   // const theData = makeData()
    setZData(cellInfo.original)

    return (
      '   ' && <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          //const data = [...zData];
          const data = zData;
          //data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          data[cellInfo.column.id] =  e.target.innerHTML;
          setZData({ data });
        }}
        dangerouslySetInnerHTML={{
          __html: cellInfo.original[cellInfo.column.id]
        }}
      />
    );
  }
  */
    // end test

   const handleClose = () => {
    setShowColorPicker(false)
    
  }

  const columns = [
    { Header: "Updated", accessor: "updatedAt", show: false, sortable: false },
    { Header: "ID", accessor: "id", className: "id_column", show: false, sortable: false },
    {
      Header: "",
      accessor: "i",
      sortable: false,
      show: true,
      width: 10,
      style: {
        color: 'white',
      }
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
      width: 200,
      Cell: row => <InPut disabled={false} value={row.value} field="name" id={row.original.id} {..._props} />,
    },
    {
      Header: "Description",
      accessor: "description",
      width: 250,
      sortable: true,
      Cell: row => <InPut value={row.value} field="description" id={row.original.id} {..._props} />,
      //Cell: row  => <EditParagraph ellipsis={true} field='description' id={row.original.id} value={row.original.description} {..._props}/>

      
    },
    {
      Header: "Category",
      accessor: "category",
      width: 150,
      sortable: true,
      //good -- Cell: row => <InPut value={row.value} field="category" id={row.original.id} {..._props} />,
      //Cell: row  => <EditParagraph ellipsis={true} field='category' id={row.original.id} value={row.original.category} {..._props}/>
      //Cell: cell => renderEditable(cell)
      //??? Cell: row => <CategorySelect id={row.original.id} selectedCategory = {row.original.category} {..._props} />
      
      Cell: row => <CategorySelect color={row.original.categoryColor} id={row.original.id} BuildCategories= { BuildCategories} selectedCategory = {row.original.category} {..._props} />
    },
    {
      Header: "Color",
      accessor: "categoryColor",
      width: 90,
      sortable: true,

      //Cell: row => <Toggle disabled={false} toValue={true} unValue={false} value={row.value} field='isActive' id={row.original.id} {..._props}  />,
     
      /* Cell: row  =>{
        return (
          <div className="row_actions" style={{textAlign: 'center'}}>
            <Button 
              className="cat-color-box" 
              style={{textAlign: "center", backgroundColor: row.original.categoryColor,  border: row.original.categoryColor}}
              value={row.value} 
              onClick={() => {
                setColorRowId(row.original.id)
                setShowColorPicker(!showColorPicker)

              }}
              {..._props}
            />
        </div>
        ) 
      } */
    
      Cell: row => 
            <ColorPickButton
              className="cat-color-box" 
              style={{textAlign: "center", backgroundColor: row.original.categoryColor,  border: row.original.categoryColor}}
              value={row.value} 
              category={row.original.category}
              showColorPicker={showColorPicker}
              handleClose={handleClose}
              model="picklist"
              //mutate={mutate}
              color={color}
              setColor={setColor}
              categoryColor={row.original.categoryColor}
              data={data}
              //url={_props.url}
              id={row.id}
              shiftJson = {newPicklist ? newPicklist : data ? data.find( t => t.id === colorRowId ) : {} } 
              onClick={() => {
                setColorRowId(row.original.id)
                setShowColorPicker(!showColorPicker)
              }}
              {..._props}  
            />
    },

    {
      Header: "Location",
      accessor: "locationId",
      sortable: true,
      //width: 280,
      Cell: row => {
        const record = row.original
       /*  const opts = {
          id: record.id,
          record,
          value: row.value,
          optional: true,
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "locationId", id: record.id })
          }
        } */

        const selectProps = {
          pk: "id",
          record: row,
          storeKey: "Locations",
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "locationId", id: record.id })
          },
          id: row.value ? row.value : uuid(),
        }

        return <LocationSelect placeholder="Location" value={row.value} field="locationId" {...selectProps} disabled={false} />

        //return <PayTypeSelect placeholder="Select a Calculation" data-cy="PayTypeSelect" {...opts} disabled={false}/>
      },
    },

    {
      Header: "Rules",
      accessor: "payTypeCode",
      sortable: true,
      //width: 280,
      Cell: row => {
        const record = row.original
        /* const opts = {
          id: record.id,
          record,
          value: row.value,
          optional: true,
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCode", id: record.id })
          }
        } */

        const selectProps = {
          pk: "id",
          record: row,
          storeKey: "shifts",
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCode", id: record.id })
          },
          id: row.value ? row.value : uuid(),
        }

        return <ReasonSelect  placeholder="Rule" value={row.value} field="reasonId" {...selectProps} disabled={false} />

        //return <PayTypeSelect placeholder="Select a Calculation" data-cy="PayTypeSelect" {...opts} disabled={false}/>
      },
    },
    {
      Header: "Reset Picklist",
      accessor: "payTypeCode",
      sortable: true,
      show: false,
      //width: 280,
      Cell: row => {
        const record = row.original
       /*  const opts = {
          id: record.id,
          record,
          value: row.value,
          optional: true,
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCode", id: record.id })
          }
        } */

        const selectProps = {
          pk: "id",
          record: row,
          storeKey: "shifts",
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCode", id: record.id })
          },
          id: row.value ? row.value : uuid(),
        }

        return <ReasonSelect  placeholder="Picklist Reset" value={row.value} field="reasonId" {...selectProps} disabled={false} />

        //return <PayTypeSelect placeholder="Select a Calculation" data-cy="PayTypeSelect" {...opts} disabled={false}/>
      },
    },
    {
      Header: "Active",
      accessor: "isActive",
      width: 80,
      sortable: true,
      Cell: row => <Toggle disabled={false} toValue={true} unValue={false} value={row.value} field='isActive' id={row.original.id} {..._props} />,
    },
    {
      Header: "Editable",
      accessor: "canEdit",
      width: 80,
      sortable: true,
      Cell: row => <Toggle disabled={false} toValue={true} unValue={false} value={row.value} field='canEdit' id={row.original.id} {..._props}  />,
    },
    {
      Header: "Emp Cnt",
      accessor: "employeeCount",
      width: 80,
      sortable: true,
      //Cell: row => <Toggle disabled={false} toValue={true} unValue={false} value={row.value} field='canEdit' id={row.original.id} {..._props}  />,
      //Cell: row =>  <InputNumber min={0} max={1000} disabled={true} defaultValue={row.value} /> 
      Cell: row => <Tag color="#DAA520"> <div className="row_actions" style={{textAlign: 'center'}}>{row.value}</div></Tag>

    },
    /*{
      Header: "Overtime",
      accessor: "isOT",
      width: 80,
      sortable: true,
      Cell: row => <Toggle disabled={true} toValue={true} unValue={false} value={row.value} field='isOT' id={row.original.id} {..._props}  />,
    },
     {
      Header: "Pay Type",
      accessor: "payTypeCode",
      sortable: true,
      width: 280,
      Cell: row => {
        const record = row.original
        const opts = {
          id: record.id,
          record,
          value: row.value,
          optional: true,
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCode", id: record.id })
          },
        }
        return <PayTypeSelect data-cy="PayTypeSelect" {...opts} disabled={true}/>
      },
    },
    {
      Header: "Pay Type Alt",
      accessor: "payTypeCodeAlt",
      sortable: true,
      width: 280,
      Cell: row => {
        const record = row.original
        const opts = {
          id: record.id,
          record,
          value: row.value,
          optional: true,
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCodeAlt", id: record.id })
          },
        }
        return <PayTypeSelect data-cy="PayTypeSelect" field='payTypeCodeAlt' {...opts} disabled={true}/>
      },
    }, 
    */
    {
      Header: "Actions",
      sortable: false,
      width: 250,
      Cell: row => (
        <div className="row_actions">
          <Can I="edit:ShiftTemplates">
            <Tooltip title="Add Employees">
              <Button 
                type="primary"
                icon='usergroup-add'
                shape="circle" 
                value={row.value} 
                onClick={e => {
                  //console.log(row)
                  context.setPicklistDrawerId(row.original.id)} 

                }
              />
            </Tooltip>
          </Can>
          <Can I="copy:ShiftTemplates">
            <Tooltip title="Copy">
              <Button 
                icon="copy" 
                shape="circle" 
                value={row.value} 
                onClick={e => {
                  //console.log(row)
                  copyNewPicklist(row)
                  //context.setTemplateDrawerId(row.original.id)} 
                  }
                }
              />
            </Tooltip>
          </Can>
          <Can I="delete:ShiftTemplates">
            <Popconfirm
              title={`Are you sure you want to DELETE this Entry?`}
              onConfirm={() => deleteResource({ ..._props, id: row.original.id })}
            >
              <Tooltip title="Delete">
                <Button icon="delete" shape="circle" type="danger" />
              </Tooltip>
            </Popconfirm>
          </Can>
        </div>
      ),
    },
  ]

  //useEffect(() => {
  //  console.log('changed active')
//
 // }, [data.isActive])
  
  useEffect(() => {
    if (newPicklist !== null ) setNewPicklist(null)
    if (!picklistDrawerId) mutate(_props.url, null)
    if (!!picklistDrawerId && data) mutate(_props.url, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picklistDrawerId])


//  useEffect(() => {

//    mutate(_props.url, [])
//  }, [pivot])
  /* c useEffect(() => {
    //if (data && data.length) store.set("_ShiftTemplates", data)
    //if (data && data.length) store.set("ShiftTemplates", data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) */

  /* 02/18/22 */
  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

  /* 02/18/22 useEffect(() => {
    Emitter.on("TemplateDrawerClose", () => {
      mutate()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) */

  const BuildCategories = () => {  
    const categories  = data ? [...new Map(data.map(item => 
      [item['category'], {value: item.category.toLowerCase(), label: item.category, color: item.categoryColor}])).values()] : []
    return categories
  }

 /*  
  const oBuildCategories = () => {  
    const categories  = data ? [...new Map(data.map(item => 
      [item['category'], {value: item.category ? item.category.toLowerCase() : null, label: item.category}])).values()] : []
    return categories
  }
 */

  const debugTable = []
  if (records ) {
    records.forEach( r =>  debugTable.push({name: r.name, category: r.category, color: r.categoryColor, payTypeCode: r.payTypeCode})) 
  }

  
  /* const GroupSelect = (props) => {
   return (
      <Select 
        allowClear={true}
        mode="multiple"
        placeholder="Select group field"
        //defaultValue={null}
        style={{width: 500}} 
        //optionLabelProp="label"
        onChange={handleSelectPivot}
      >
        <Option key="category">Category</Option>
        <Option key="payTypeCode">Pay Type Code</Option>
        <Option key="payTypeCodeAlt">Pay Type Code Alt</Option>
      </Select>
     )
    

  } */


  const handleSelectPivot = (value) => {
    setPivot(value)
    setPivotLoading(false)
    store.set("_pivot", value)
    return mutate(_props.url)
  }

  /* const handlePivot = (field) => {
    setPivotLoading(true)
    let p = pivot
    if (pivot.includes(field)) {
      p = p.filter(function(item) {
        return item !== field
    } )
      setPivot(p)
    } else {
      if (!p.includes(field)) {
        p.push(field)
      }
      setPivot(p)
    }
    setPivotLoading(false)
    return mutate(_props.url)
    
  } */


  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          Group by {' '}
          <GroupPivotSelect
            loading={pivotLoading}
            allowClear={true}
            mode="multiple"
            placeholder="Select group field"
            style={{width: '100%'}} 
            pivot={pivot}
            handleSelectPivot={handleSelectPivot}
          />
           
          {/* <Switch 
            disabled={false} 
            loading={pivotLoading}
            defaultChecked={pivot.includes('category')} 
            checked={pivot.includes('category')} 
            checkedChildren={ <Icon type="check" /> }
            unCheckedChildren={ <Icon type="close" /> }
            id='category'
            onChange={(e ) => {
              handlePivot('category')
            }}  
          /> {' '}Category
          <Switch 
            disabled={false} 
            loading={pivotLoading}
            defaultChecked={pivot.includes('payTypeCode')} 
            checked={pivot.includes('payTypeCode')} 
            checkedChildren={ <Icon type="check" /> }
            unCheckedChildren={ <Icon type="close" /> }
            onChange={(e ) => {
              handlePivot('payTypeCode')
            }}  
          /> {' '}Pay Type
          <Switch 
            disabled={false} 
            loading={pivotLoading}
            defaultChecked={pivot.includes('payTypeCodeAlt')} 
            checked={pivot.includes('payTypeCodeAlt')} 
            checkedChildren={ <Icon type="check" /> }
            unCheckedChildren={ <Icon type="close" /> }
             onChange={(e ) => {
              handlePivot('payTypeCodeAlt')
            }}  
          /> {' '}Pay Type Alt */}
          <Search {..._props} />
          <Can I="create:ShiftTemplates">
            <Tooltip title="Add Template">
              <Button data-cy="CreateResourceButton" type="dashed" icon="plus-square" onClick={() => createNewPicklist(_props)}>
                {createTitle}
              </Button>
            </Tooltip>
            <Tooltip title="Test Add">
              <Button type="ghost" icon="play-square" onClick={e => {
                    context.setShiftDrawerId(0)
                    context.setNewShiftDrawerPayTypeCode('1')
                  } }   
                >
                  Create Shift
                </Button>
              </Tooltip>
          </Can>
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
      <Layout className="ShiftTemplates">
        <Content className="Content">
          <TreeTable
            keyField="id"
            columns={columns}
            loading={loading}
            data={records}
            defaultPageSize={200}
            pageSizeOptions={[25, 50, 100, 150, 200, 300]}
            filterable={true}
            multiSort={true}
            pivotBy={pivot}
            className="-striped -highlight flex-1"
            defaultSorted={[{id: "category", desc: false},{id: "name", desc: false}]}
            expanded={expandedIds}
            onExpandedChange={(newExpanded, index, event) => {
              setExpandedIds(newExpanded)
            }}

            //defaultSorted={[{ id: "i", desc: true }]}
            //SubComponent={row => {
            //  return (
            //    <div style={{ padding: "20px" }}>
            //       <label>tSub Component.  Can be a table</label>
            //    </div>
            //  )
            //}}
          />
        </Content>
      </Layout>
      </div>
      {!!showColorPicker && <CategoryColorPicker 
          showColorPicker={showColorPicker}
          handleClose={handleClose}
          mutate={mutate}
          color={color}
          setColor={setColor}
          data={data}
          url={_props.url}
          shiftJson = {newPicklist ? newPicklist : data ? data.find( t => t.id === colorRowId )
          : null } 
          />
      }
      {/*{!!showColorPicker && <Modal title="Cateogy Color" visible={showColorPicker} onOk={handleClose} onCancel={()=> setShowColorPicker(false)} ><HexColorPicker color={color} onChange={setColor} /></Modal>}*/}
      {!!picklistDrawerId && <PicklistDrawer 
        shiftJson = {newPicklist ? newPicklist : data ? data.find( t => t.id === context.picklistDrawerId ) : null } 
        setNewPicklist = {newPicklist ? setNewPicklist : null}
        dataRec = { data ? data.find( t => t.id === context.picklistDrawerId ) : null  }
        listUrl = {window.location.href} />
        }
      {!!newShiftDrawer && <NewShiftDrawer payTypeCode={newShiftDrawer} />}
      {!!shiftDrawerId && <ShiftDrawer />}
    
    </React.Fragment>
  )
}

export default Picklists
