import React from "react"
//import React, { useState } from "react"
//import { store } from "../../store/psrs"
//import Select from 'react-select'
//import useMyContext from "../../store/useMyContext"
import _ from "lodash"

import { Transfer, Table } from 'antd';

//import difference from 'lodash/difference';

export const EmployeeTransfer = props => {
    console.log('EmployeeTransfer')

    const disabled = props.disabled
    const dataSource = props.dataSource
    const titles = props.titles
    const showSearch = props.showSearch || false
    const filterOption = props.filterOption
    const targetKeys = props.targetKeys //? props.targetKeys.map(e => e.number) : []
    const onChange = props.onChange
    //const render = props.render
    //const locale = props.locale
    //const listStyle = props.listStyle || {}

    const leftTableColumns = props.leftTableColumns
    const rightTableColumns = props.rightTableColumns

    //const [employeeIds, setEmployeeIds] = useState(value && value.length ? value.map(e => e.id ? g.id : g) : [])


    const mockTags = ['Cpt', 'LT', 'FF'];
  
    const mockData = [];
    for (let i = 0; i < 20; i++) {
        mockData.push({
            key: i.toString(),
            title: `content${i + 1}`,
            description: `description of content${i + 1}`,
            disabled: i % 4 === 0,
            tag: mockTags[i % 3],
        });
    }
  
    //const originTargetKeys = mockData.filter(item => +item.key % 3 > 1).map(item => item.key);
        
   // const originTargetKeys = dataSource.filter(e => +e.key % 3 > 1).map(e => e.key);


    /* const [state, setState] = useState({
        //targetKeys: originTargetKeys,
        targetKeys: props.targetKeys ? props.targetKeys.map(e => e.number) : [],
        disabled: false,
        showSearch: false,

    });
      
    const xonChange = nextTargetKeys => {
        setState({ targetKeys: nextTargetKeys });
    };
      
    const triggerDisable = disabled => {
        setState({ disabled });
    };
    
    const triggerShowSearch = showSearch => {
        setState({ showSearch });
    };
 */
    // Customize Table Transfer
    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer {...restProps} showSelectAll={false}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;
  
                const rowSelection = {
                getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                    .filter(item => !item.disabled)
                    .map(({ key }) => key);
                    const diffKeys = selected ? _.difference(treeSelectedKeys, listSelectedKeys) : _.difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
                };
  
            return (
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={filteredItems}
                size="small"
                pagination={{ pageSize: 500 }} scroll={{ y: 240, x: true }}
                style={{ pointerEvents: listDisabled ? 'none' : null }}
                onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                    if (itemDisabled || listDisabled) return;
                    onItemSelect(key, !listSelectedKeys.includes(key));
                },
                })}
            />
            );
         }}
      </Transfer>
    );
  
  return (
      <div>
        <TableTransfer
          dataSource={dataSource}
          targetKeys={targetKeys}
          disabled={disabled}
          showSearch={showSearch}
          titles={titles}
          filterOption={filterOption}
          onChange={onChange}
          //filterOption={(inputValue, item) =>
          //  item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
          //}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
        />
        {/* <Switch
          unCheckedChildren="disabled"
          checkedChildren="disabled"
          checked={disabled}
          onChange={triggerDisable}
          style={{ marginTop: 16 }}
        />
        <Switch
          unCheckedChildren="showSearch"
          checkedChildren="showSearch"
          checked={showSearch}
          onChange={triggerShowSearch}
          style={{ marginTop: 16 }}
        /> */}
      </div>
    );
}

export default EmployeeTransfer
