import React, { useState, useEffect } from "react"
//import { _, store, Can, reSeedData } from "../store/psrs"
import { _, store, session, Can, REQ, Emitter, employeeNumber } from "../store/psrs"
import { NavLink, useLocation } from "react-router-dom"
import {useStateIfMounted} from "use-state-if-mounted"
import useMyContext from "../store/useMyContext"
import PageHelp from "./Help/PageHelp"
//import { message, Layout, Icon, Badge, Tag, Modal, Tooltip } from "antd"
import { Layout, Icon, Badge, Tag, Modal, Button, Tooltip } from "antd"
import { ReactComponent as TimelineSvg } from "../svg/V2/TIMELINE.svg"
import { ReactComponent as DashboardSvg } from "../svg/V4/DASHBOARD.svg"
import { ReactComponent as LogoutSvg } from "../svg/V3/logout.svg"
import { ReactComponent as ApprovalsSvg } from "../svg/V3/approvals.svg"
//import { ReactComponent as SettingsSvg } from "../svg/V3/settingsFilled.svg"
import { ReactComponent as ProfileSvg } from "../svg/V3/profile.svg"
import { ReactComponent as ShiftsSvg } from "../svg/V3/shifts.svg"
import { ReactComponent as TimeSvg } from "../svg/V3/time.svg"
import { ReactComponent as NavSvg } from "../svg/V3/navigation.svg"
import { ReactComponent as PSRS } from "../svg/V1/PSRS-logo.svg"
import { ReactComponent as RecordsSvg } from "../svg/V2/RECORDS.svg"
import { ReactComponent as TeamsSvg } from "../svg/V3/teams.svg"
//import { ReactComponent as TourSvg } from "../svg/V4/TRAFFIC.svg"
//import { ReactComponent as DocSvg } from "../svg/V4/DOCUMENTS.svg"
import { ReactComponent as FaqSvg } from "../svg/V4/FAQ.svg"
// iframe WIP import { ReactComponent as NodeRed } from "../svg/V4/RulesEngine.svg"

//import { ReactComponent as psrsHelp } from "../svg/help-svgrepo-com.svg"

// FAQ PDF 
//import FaqPdf  from '../Documents/PSRS_FAQ.pdf';
//-test - const FaqPdf = 'https://psrswiki.ddns.net'
const FaqPdf = process.env.REACT_APP_WIKI_URL ||  'https://psrswiki.enterprisetechnologies.com'
//console.log('FaqPdf -> ', FaqPdf)
//console.log('process.env.REACT_APP_WIKI_URL ->', process.env.REACT_APP_WIKI_URL )


// import toggle from "toggle-in-array"

const { Sider } = Layout

export const SideNavigation = props => {
  const context = useMyContext()
  const location = useLocation()
  const user = store.session.get("CurrentUser")
  const role = store.session.get("role")

  const [mounted, setMounted] = useState(false)
  const [approvalCount, setApprovalCount] = useStateIfMounted("0")
  const [notificationCount, setNotificationCount] = useStateIfMounted("0")
  
  const [view, setView] = useStateIfMounted({
    page: props.page,
    visible: false,
    title: `${props.page}`,
    onOk: () => setView({ ...view, visible: false }),
    onCancel: () => setView({ ...view, visible: false }),
    footer: null,
    // cancelButtonProps: { disabled: true },
  })

  const superPath = () => {
    // if (isSuper) return "supervised"
    // else return "mine"
    return "mine"
  }
  const shiftsLinkTo = lo => ({ ...lo, pathname: `/shifts/${superPath()}`, state: { basePath: "/shifts/" } })
  const timeLinkTo = lo => ({ ...lo, pathname: `/timeentries/${superPath()}`, state: { basePath: "/timeentries/" } })
  const approvalsLinkTo = lo => ({ ...lo, pathname: `/approvals/shifts`, state: { basePath: "/approvals/" } })
  const approvalsLinkToV2= lo => ({ ...lo, pathname: `/approvalsV2/shifts`, state: { basePath: "/approvalsV2/" } })

  const shiftsLabel = "Shifts"
  const timeLabel = "Time Entries"
  const approvalLabel = "Approvals"
  const approvalLabelV2 = "Approvals Workbench"
  
  // eslint-disable-next-line 
  const setNotifications = async () => {
    setNotificationCount("0")

    //const ppID = store.get("PayPeriod").id
    let noticationBadgeCnt = 0

    const notificationQuery = `SELECT count(1) from notifications WHERE "deletedAt" is null AND "employeeNumber" is not null and "employeeNumber" = '${employeeNumber}' and "notificationSent" is false  and "shift"->>'payPeriodId' in (select id from payperiods where "end" >= now()) `

    //const shiftQuery = `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "employeeNumber" is not null AND ("needsApproved" = true OR "submit" = true) AND ("approved" = false AND "rejected" = false) AND ("superNumber1"='${empNum}')`

    await REQ(`/api/raw_query`, "POST", {query: notificationQuery} )
    .then( notifications => {
      //console.log('approvalCount -> ', shifts)
      if (notifications.length > 1) {
        noticationBadgeCnt = notifications[0][0].count
      }

    })
    .catch(err => { 
      console.log('badge count error -> ', err)
    })
    setNotificationCount(noticationBadgeCnt)

  }

  const setApprovals = async () => {
    
    setApprovalCount("0")
    // TODO fix me
    // const Shifts = context.shifts.size ? context.Shifts.filter(s => !!s.needsApproved) : []
    // const TimeEntries = context.timeEntries.size ? context.TimeEntries.filter(t => !!t.needsApproved) : []
    // const Approvals = [...Shifts, ...TimeEntries]
    // setApprovalCount(`${Approvals.length}`)

    // try this
    //const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
    //const badgeQuery = `SELECT id FROM "shifts" WHERE "available" IS TRUE AND "start" >= (now() - INTERVAL '30 DAY') at time zone '${userTimeZone}'`

    const ppID = store.get("PayPeriod").id
    const empNum = session.get("CurrentUser").number

    let shiftBadgeCount = 0
    let timeEntryBadgeCount = 0

    if (props.page === 'ShiftApprovals') {
      //const shiftQuery = `SELECT id FROM "shifts" WHERE "payPeriodId"='${ppID}' AND "employeeNumber" is not null AND "needsApproved" = true AND ("employeeNumber"='${empNum}' OR "superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`
      const shiftQuery = `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "employeeNumber" is not null AND ("needsApproved" = true OR "submit" = true) AND ("approved" = false AND "rejected" = false) AND ("superNumber1"='${empNum}')`
      await REQ(`/api/raw_query/shifts`, "POST", {query: shiftQuery} )
      .then( shifts => {
        //console.log('approvalCount -> ', shifts)
        shiftBadgeCount = shifts.length
      })
      .catch(err => { 
        console.log('badge count error -> ', err)
      })
    } else if (props.page === 'ShiftApprovalsV2') {
      //const shiftQuery = `SELECT id FROM "shifts" WHERE "payPeriodId"='${ppID}' AND "employeeNumber" is not null AND "needsApproved" = true AND ("employeeNumber"='${empNum}' OR "superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`
      const shiftQuery = `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${ppID}' AND "employeeNumber" is not null AND ("needsApproved" = true OR "submit" = true) AND ("approved" = false AND "rejected" = false) AND ("superNumber1"='${empNum}')`
      await REQ(`/api/raw_query/shifts`, "POST", {query: shiftQuery} )
      .then( shifts => {
        //console.log('approvalCount -> ', shifts)
        shiftBadgeCount = shifts.length
      })
      .catch(err => { 
        console.log('badge count error -> ', err)
      })
    } else {
    //  const timeEntryQuery = `SELECT id FROM "timeentries" WHERE "payPeriodId"='${ppID}' AND "needsApproved" = true AND "payTypeCode" != '380' AND ("employeeNumber"='${empNum}' OR "superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`
      const timeEntryQuery = `SELECT id FROM "timeentries" WHERE "payPeriodId"='${ppID}' AND "needsApproved" = true AND ("name" not in ('Unpaid Lunch') OR "name" is null) AND ("employeeNumber"='${empNum}' OR "superNumber1"='${empNum}' OR "superNumber2"='${empNum}' OR "superNumber3"='${empNum}')`

      await REQ(`/api/raw_query/timeEntries`, "POST", {query: timeEntryQuery} )
      .then( timeEntries => {
        //console.log('approvalCount -> ', timeEntries)
        timeEntryBadgeCount = timeEntries.length
      })
      .catch(err => { 
        console.log('badge count error -> ', err)
      })
    }
    setApprovalCount(shiftBadgeCount + timeEntryBadgeCount)
  }

  const _setApprovals = _.debounce(setApprovals, 1000, { trailing: true, leading: false })
  //const _setNotications = _.debounce(setNotifications, 1000, { trailing: true, leading: false })


  const refreshCache = async () => {
    // const seeded = reSeedData()b  
    // if (!!seeded) message.success("Data Refreshed")
  }

  useEffect(() => {
    mounted && _setApprovals()
    Emitter.on("ChangedPayPeriod", () => {
      //console.log("Emitter.on('ChangedPayPeriod')")
      mounted && _setApprovals()
    })

    //mounted && _setNotications()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, mounted])
  
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      setView({ ...view, page: props.view, visible: false, title: `${props.view}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.view, mounted])

  const siderProps = {
    width: 80,
    className: "SideBarNav",
  }

  const active = linkTo => {
    const { pathname } = location
    const { basePath } = linkTo().state
    if (pathname.includes(basePath)) return true
    else return false
  }

  return (
    <Sider {...siderProps}>
      <Modal {...view}>
        <PageHelp view={view.page} />
      </Modal>
      <ul className="nav_items upper">
        <li className='logo'>
        {/* <Can I="visit:AdminNav">
          <Tooltip  title={ "Give Feedback"}>
            <Button type="link" shape="circle" icon={"sound"} href="https://submission.bromb.co/bwright/PSRS"/>
          </Tooltip>
        </Can> */}
        <Tooltip  title={ "Notifications (future)"}>
          <div style={{paddingLeft: '10px', textAlign: "left"}}>
            <Badge data-cy="new-approvals-count" count={notificationCount} className="approvalBadge" />
            <Button size="small" type="link" shape="circle" icon={"bell"} />
          </div>

        </Tooltip>

        <Can I="see:psrsIcon">
            <Icon component={PSRS} onClick={refreshCache} href="/"/>
        </Can>

        </li>

        {/* ADMIN NAV */}
        <Can I="visit:AdminNav">
          <li>
            <NavLink data-cy="nav-navigation" className="link" to={`/nav`}>
              <Icon component={NavSvg}  /> <span className="itemName">Navigation</span>
            </NavLink>
          </li>
        </Can>

        {/* SHIFTS */}
        <Can I="visit:Shifts">
          <li>
            <NavLink data-cy="nav-shifts" className="link" to={shiftsLinkTo} isActive={() => active(shiftsLinkTo)} strict>
              <Icon component={ShiftsSvg} /> <span className="itemName">{shiftsLabel}</span>
            </NavLink>
          </li>
        </Can>

        {/* TIME ENTRIES */}
        <Can I="visit:TimeEntries">
          <li>
            <NavLink data-cy="nav-time-entries" className="link" to={timeLinkTo} isActive={() => active(timeLinkTo)} strict>
              <Icon component={TimeSvg} /> <span className="itemName">{timeLabel}</span>
            </NavLink>
          </li>
        </Can>

        {/* APPROVALS */}
        <Can I="visit:Approvals">
         {/*  <li>
            <NavLink
              data-cy="nav-approvals"
              className="link"
              to={approvalsLinkTo}
              isActive={() => active(approvalsLinkTo)}
              strict
            >
              <Icon component={ApprovalsSvg} /> <span className="itemName">{approvalLabel}</span>
              <Badge data-cy="new-approvals-count" count={approvalCount} className="approvalBadge" />
            </NavLink>
            </li> */}
            {role === 'employee' ? 
            <li>
              <NavLink
                data-cy="nav-approvals"
                className="link"
                to={approvalsLinkTo}
                isActive={() => active(approvalsLinkTo)}
                strict
              >
                <Icon component={ApprovalsSvg} /> <span className="itemName">{approvalLabel}</span>
                <Badge data-cy="new-approvals-count" count={approvalCount} className="approvalBadge" />
              </NavLink>
            </li> :
            <li>
              <NavLink
                data-cy="nav-approvals"
                className="link"
                to={approvalsLinkToV2}
                isActive={() => active(approvalsLinkToV2)}
                strict
              >
                <Icon component={ApprovalsSvg} /> <span className="itemName">{approvalLabelV2}</span>
                <Badge data-cy="new-approvals-count" count={approvalCount} className="approvalBadge" />
              </NavLink>
            </li> }
          </Can>
        {/* PAY PERIOD STATS */}
        {/*} <Can I="visit:PayPeriodStats">*/}
          <li>
            <NavLink data-cy="nav-timeline" className="link" to="/payperiodstats">
              <Icon component={DashboardSvg} /> <span className="itemName">Pay Period Stats</span>
            </NavLink>
          </li>
        {/*</Can>*/}
        {/* TIMELINE */}
        <Can I="visit:TimelineSchedule">
          <li>
            <NavLink data-cy="nav-timeline" className="link" to="/schedule/timeline">
              <Icon component={TimelineSvg} /> <span className="itemName">Timeline</span>
            </NavLink>
          </li>
        </Can>

        {/* TEAMS */}
        <Can I="visit:Teams">
          <li>
            <NavLink data-cy="nav-my-teams" className="link" to="/teams/mine">
              <Icon component={TeamsSvg} /> <span className="itemName">My Teams</span>
            </NavLink>
          </li>
        </Can>

        {/* PRINT SCHEDULE */}
         <Can I="visit:PrintSchedule">
          <li>
            <NavLink data-cy="nav-print-schedule" className="link" to="/reports">
              <Icon type="printer" /> <span className="itemName">Reports</span>
            </NavLink>
          </li>
        </Can> 

       {/*} <li>
          <NavLink data-cy="nav-print-schedule" className="link" to="/reports">
            <Icon type="printer" /> <span className="itemName">Reports</span>
          </NavLink>
      </li> */} 

        {/* RECORDS VIEWER */}
        <Can I="visit:RecordsViewer">
          <li>
            <NavLink data-cy="nav-records" className="link" to="/records">
              <Icon component={RecordsSvg} />
              <span className="itemName">Records Power View</span>
            </NavLink>
          </li>
        </Can>
      </ul>

      <ul className="nav_items lower">
        <Can I="visit:Settings">
          <li></li>
        </Can>

        {/* HELP CRISP CHAT APP
        <li>
          <span className="link" onClick={ (event) => {
            event.preventDefault() 
            if (window.$crisp.is("chat:hidden")) {
              console.log('**** blw -- hidden, going to open... ')
              window.$crisp.push(['do', 'chat:show'])
              window.$crisp.push(['do', 'chat:open'])
             
            } else if (window.$crisp.is("chat:visible")) {
              console.log('**** blw -- visible , going to hiding... ')
              window.$crisp.push(['do', 'chat:hide']);  
            }
           
            
          }}>
            <Icon component={psrsHelp} /> 
            <span className="itemName">Help</span>
          </span>
        </li>*/}
        {/* WIP - wiki iframe <li>
          <NavLink data-cy="nav-wiki" className="link" to={`/wiki`}>
            <Icon component={NodeRed} />  <span className="itemName">Rules Engine</span>
          </NavLink>
        </li>
        */}
        <li>
          <span className="link" onClick={(event) => { 
              event.preventDefault() 
              window.open(FaqPdf,'wikiTab') //, 'noopener,noreferrer') 
            }}>
              <Icon component={FaqSvg} /> 
              <span className="itemName">Help/FAQ  Wiki</span>
          </span>
        </li>
        <li>
          <NavLink className="link" to={`/logout`}>
            <Icon component={LogoutSvg} /> <span className="itemName">Log Out</span>
          </NavLink>
        </li>

        <li>
          <NavLink data-cy="nav-settings" className="link" to={`/settings/${user.number}`}>
            <Icon component={ProfileSvg} /> <span className="itemName">My Settings - {user.firstName} {user.lastName} / {user.jobTitle}</span>
          </NavLink>
        </li>
        <li>
          <Tag className="roleBadge">{store.session.get("role")}</Tag>
          <span className="itemName">{`${user.jobTitle} ${user.firstName} ${user.lastName}`}</span>
        </li>
      </ul>
    </Sider>
  )
}

export default SideNavigation
