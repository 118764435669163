import React, { useState, useEffect } from "react"
import { store } from "../../store/psrs"
import uniqid from "uniqid"
import { Select } from "antd"
import { renderSelectDropdown, filterOption } from "./_selectDefaults"

const Option = Select.Option

export const EquipmentSelect = props => {
  const { value, handleChange } = props

  //console.log("EquipmentSelect", value)

  const [state, setState] = useState({
    selected: [],
    gizmos: [],
  })

  const [gizmoIds, setGizmoIds] = useState(value && value.length ? value.map(g => g.id ? g.id : g) : [])

  const getGizmoIds = async () => {
    const gizmos = store.get("Gizmos")
    try {
      const ids = value ? value.map(g => g.id ? g.id : g) : []
      setGizmoIds(ids)
      setState({ ...state, gizmos, selected: gizmoIds ? gizmoIds : [] })
    } catch (e) {
      console.log('error -> ', e)
    }
  }

  const options = () =>
    state.gizmos.map(group => (
      <Option data-cy={`option-${group.id}`} key={uniqid()} value={group.id}>
        {group.name}
      </Option>
    ))

  const selectOptions = {
    isDisabled: props.disabled,
    disabled: props.disabled,
    className: "GroupsSelector",
    dropdownClassName: "GroupsSelectorDropdown",
    allowClear: true,
    showSearch: true,
    mode: "multiple",
    onChange: e => {
      //console.log(e)
      setGizmoIds(e)
      setState({ ...state, gizmoIds: e })
      handleChange({ field: "gizmoIds", value: e })
      // handleChange
    },
    value: gizmoIds,
    filterOption,
    dropdownRender: menu => renderSelectDropdown(menu, props["data-cy"]),
  }

  useEffect(() => {
    getGizmoIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select data-cy="Select" {...selectOptions}>
      {options()}
    </Select>
  )
}

export default EquipmentSelect
