import React from "react"
import { Tag, Tooltip, Icon, Checkbox } from "antd"
import uid from "uniqid"
import moment from "moment-timezone"
import { CheckBox } from "grommet"

const renderStatus = shift => {
  const { available, requestedOff, approved, imbalanced, rejected, isSick, isOT, isCollision, submit, assignmentId, assignment,paidLunch,locked} = shift
  // const processed = shift.process
  // const paid = { className: `stat ${processed ? "on processed" : ""}`, type: "dollar-circle" }

  const submitted = { className: `stat ${submit ? "on submitted" : ""}`, type: "check-circle" }
  const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, type: "clock-circle" }
  const bid = { className: `stat ${available ? "on bid" : ""}`, type: "user-add" }
  const pto = { className: `stat ${requestedOff ? "on pto" : ""}`, type: "message" }
  const sick = { className: `stat ${isSick ? "on sick" : ""}`, type: "medicine-box" }
  const good = { className: `stat ${approved ? "on approved" : ""}`, type: "like" }
  const bad = { className: `stat ${rejected ? "on rejected" : ""}`, type: "dislike" }
  const wrongTime = { className: `stat ${imbalanced ? "on imbalanced" : ""}`, type: "stop" }
  const collision = { className: `stat ${isCollision ? "on collision" : ""}`, type: "issues-close" }
  const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, type: "safety-certificate" }
  const paidlunch = { className: `stat ${paidLunch ? "on paidLunch": "" }`, type: "rest" }
  const isPaidLunch = paidLunch
  const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, type: "lock" }

  return (
    <div className="StatusIcons">
      <Tooltip mouseEnterDelay={isSick ? 0 : 99} title="Sick">
        <span className="statusIcon">
          <Icon {...sick} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={isCollision ? 0 : 99} title="Collision">
        <span className="statusIcon">
          <Icon {...collision} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={requestedOff ? 0 : 99} title="Time Off">
        <span className="statusIcon">
          <Icon {...pto} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={available ? 0 : 99} title="Available">
        <span className="statusIcon">
          <Icon {...bid} />
        </span>
      </Tooltip>
      <Tooltip title="Submitted">
        <span className="statusIcon">
          <Icon {...submitted} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={isOT ? 0 : 99} title="Has Overtime">
        <span className="statusIcon">
          <Icon {...ot} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={imbalanced ? 0 : 99} title="Imbalanced Time Entries">
        <span className="statusIcon">
          <Icon {...wrongTime} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={assignmentId ? 0 : 99} title={assignment ? assignment.name : "Acting Role"}>
        <span className="statusIcon">
          <Icon {...acting} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={rejected ? 0 : 99} title="Rejected">
        <span className="statusIcon">
          <Icon {...bad} />
        </span>
      </Tooltip>
      <Tooltip mouseEnterDelay={approved ? 0 : 99} title="Approved">
        <span className="statusIcon">
          <Icon {...good} />
        </span>
      </Tooltip>
      {<Tooltip mouseEnterDelay={paidlunch ? 0 : 99} title={ isPaidLunch ? "Paid Lunch" : "Un Paid Lunch" }>
          <span className="statusIcon">
            <Icon {...paidlunch} />
          </span>
      </Tooltip>}
      <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ locked? "Locked" : "Not Locked" }>
        <span className="statusIcon">
          <Icon {...isLocked} />
        </span>
      </Tooltip>
    </div>
  )
}
const ShiftColumns = ({ 
  excluded, setExcluded, 
  selectAll, setSelectAll, 
  indeterminate, setIndeterminate,
  selectAllShifts, setSelectAllShifts,  
  excludedShifts, setExcludedShifts, 
  indeterminateShift, setIndeterminateShift, 
  records,
  paranoid
}) => {

  // excluded, setExcluded, selectAll, setSelectAll, indeterminate, setIndeterminate, records: state.records
  /*
  const checkAll = {
    onChange: evt => {
      setExcluded(new Set(Array.from(excluded.values())))
    }
  }
  */

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()

  const onSelectAll = (e) => {

    let ids = records.map(r => r.id)
    //console.log("ids are -> ", ids)
     //console.log(state.records.map(d => d.id))
    
    // remove unselected before sending to backend
    //-//const excludedIds = Array.from(excluded.values())
    const excludedIds = Array.from(excludedShifts.values())

    ids = ids.filter( function( el ) {
      return !excludedIds.includes( el );
    } );
   
    
    if (!e.target.checked) {
      for (let id of ids) {
        excluded.add(id)
        excludedShifts.add(id)
      }
      setExcluded(new Set(Array.from(excluded.values())))
      setExcludedShifts(new Set(Array.from(excludedShifts.values())))
      //setIndeterminate(false)
    }
    
    setSelectAll(e.target.checked)
    setSelectAllShifts(e.target.checked)

    //setIndeterminate(e.target.checked)
    if(!e.target.checked && excluded.size > 0 ) setIndeterminate(false)
    if(!e.target.checked && excludedShifts.size > 0 ) setIndeterminateShift(false)
    
    // empty out excluded if select All so all are processed
    if (e.target.checked) {
      setExcluded(new Set(Array.from([])))
      setExcludedShifts(new Set(Array.from([])))
      setIndeterminate(false)
      setIndeterminateShift(false)
    }
  }

  return [
    {
      //Header: <Checkbox {...checkAll}>All</Checkbox>,
      Header:  <Tooltip title={ `Select ${selectAllShifts ? 'None':'All'}`}><Checkbox { ...{ indeterminate: indeterminateShift, checked: selectAllShifts, onChange: onSelectAll }}> </Checkbox></Tooltip>,
      accessor: "id",
      //sortable: false,
      Cell: row => {
        const isExcluded = excluded.has(row.value)
        const excludedProps = {
          checked: !isExcluded,
          onChange: evt => {
              if (selectAllShifts && excludedShifts.size === 0 && evt.target.checked) {
                excludedProps.checked = true
                excluded.add(row.value)
                excludedShifts.add(row.value)
                setIndeterminateShift(evt.target.checked)
              } else if (selectAllShifts && !evt.target.checked) {
                //setSelectAll(false)
                setIndeterminateShift(true)
                excludedProps.clecked = false
                excluded.add(row.value)
                excludedShifts.add(row.value)
              } else {
                if (isExcluded) {
                  excluded.delete(row.value)
                  excludedShifts.delete(row.value)
                  excludedProps.checked = false
                  if(excludedShifts.size > 0) setIndeterminateShift(true)
                  else setIndeterminateShift(false)
                } else {
                  excluded.add(row.value)
                  excludedShifts.add(row.value)
                  excludedProps.checked = true
                  setIndeterminateShift(false)
                } 
              }
              setExcluded(new Set(Array.from(excluded.values())))    
              setExcludedShifts(new Set(Array.from(excludedShifts.values())))    
          },
        }
        return <Checkbox {...excludedProps}> </Checkbox>
      },
    },
    {
      Header: "Status",
      width: 260,
      accessor: "id",
      sortable: false,
      Cell: row => renderStatus(row.original),
    },
    {
      Header: "Deleted On",
      width: 260,
      accessor: "deletedAt",
      sortable: true,
      show: !paranoid,
      Cell: row => (
         moment(row.value).format('MM/DD/YYYY HH:mm')
      ),
    },
    {
      Header: "Employee",
      align: "center",
      width: 200,
      accessor: "employee.fullName",
      sortable: true,
      //Cell: row => (row.value ? row.value : "Unassigned"),
      Cell: row => (
        <Tooltip title={row.value ? row.original.employee.number : "none"}>
          <Tag>{row.value ? row.value : "Unassigned"}</Tag>
        </Tooltip>
      ),
    },
    {
      Header: "Date",
      width: 140,
      accessor: "start",
      sortable: true,
      Cell: row => moment(row.value).format("ddd MMM Do"),
    },
    {
      Header: "Time",
      width: 140,
      accessor: "id",
      sortable: false,
      Cell: row => `${moment(row.original.start).format("HH:mm")} - ${moment(row.original.end).format("HH:mm")}`,
    },
    {
      Header: "Hours",
      accessor: "duration",
      sortable: true,
      Cell: row => parseFloat(row.value).toPrecision(3),
    },
    {
      Header: "Hours OT",
      accessor: "durationOt",
      sortable: true,
      show: false,
      Cell: row => parseFloat(row.value).toPrecision(3),
    },
    {
      Header: "Hours OG",
      accessor: "durationOg",
      sortable: true,
      show: false,
      Cell: row => parseFloat(row.value).toPrecision(3),
    },
    {
      Header: "Hours Dive",
      accessor: "diveHours",
      sortable: true,
      show: false,
      Cell: row => parseFloat(row.value).toPrecision(3),
    },
    {
      Header: "Hours FTO",
      accessor: "ftoHours",
      sortable: true,
      show: false,
      Cell: row => parseFloat(row.value).toPrecision(3),
    },
    {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      width: 120,
      show: true,
      sortable: true,
      Cell: row => <> <CheckBox disabled={true} checked={!row.value}></CheckBox></>,
    },
    {
      Header: "Operational Assignment",
      width: 240,
      accessor: "operationalAssignment",
      sortable: true,
      Cell: cell => cell.value ? <><Tag>{cell.value}</Tag> </> : ''
    },
    {
      Header: "Acting Role",
      width: 240,
      accessor: "assignment.name",
      sortable: true,
      Cell: cell => cell.value ? <><Tag>{cell.value}</Tag> </> : ''
    },
    {
      Header: "Business Unit",
      accessor: "businessUnit.code",
      width: 120,
      sortable: true,
      Cell: row => (
        <Tooltip title={row.value ? row.original.businessUnit.name : "none"}>
          <Tag>{row.value ? row.value : "none"}</Tag>
        </Tooltip>
      ),
    },
    {
      Header: "Chargeout",
      accessor: "costCenterChargeOut.code",
      sortable: true,
      Cell: row => (
        <Tooltip title={row.value ? row.original.costCenterChargeOut.name : "none"}>
          <Tag>{row.value ? row.value : ""}</Tag>
        </Tooltip>
      ),
    },
    {
      Header: "Sub Ledger",
      accessor: "subLedger.code",
      sortable: true,
    },
    {
      Header: "caseNumber",
      width: 240,
      accessor: "caseNumber",
      sortable: true,
    },
    {
      Header: "notes",
      width: 240,
      accessor: "notes",
      sortable: true,
    },
    {
      Header: "Supervisor",
      width: 200,
      accessor: "supervisor1.fullName",
      sortable: true,
      Cell: row => row.value || "?????",
    },
    {
      Header: "Approved By",
      accessor: "approvedBy.fullName",
      width: 200,
      sortable: true,
      Cell: row => (row.value ? row.original.approvedBy.fullName : ""),
    },
    {
      Header: "Sick",
      accessor: "isSick",
      sortable: true,
      Cell: row => (row.value ? <span>SICK</span> : <span>----</span>),
    },
    {
      Header: "Groups",
      width: 200,
      accessor: "tags",
      sortable: false,
      Cell: row => (row.value ? row.value.map(tag => <Tag key={uid()}>{tag.name}</Tag>) : <span />),
    },
    {
      Header: "Date Created",
      width: 150,
      accessor: "createdAt",
      show: true,
      sortable: true,
      Cell: cell => {
        return moment(cell.value).tz(userTimeZone).format('MM-DD-YYYY HH:mm') // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    {
      Header: "Date Updated",
      width: 150,
      accessor: "updatedAt",
      show: true,
      sortable: true,
      Cell: cell => {
        return moment(cell.value).tz(userTimeZone).format('MM-DD-YYYY HH:mm') // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    {
      Header: "Last Update User",
      width: 250,
      accessor: 'userId',
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => {
        return cell.value // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
  ]
}

export default ShiftColumns
