import React from "react"
// import useMyContext from "../../store/useMyContext"
import { Link } from "react-router-dom"
import { ReactComponent as AssignmentSvg } from "../svg/V2/CLIPBOARD-CHECK.svg"
import { ReactComponent as BusinessUnitSvg } from "../svg/V3/businessUnit.svg"
import { ReactComponent as EmployeesSvg } from "../svg/V1/contacts3.svg"
import { ReactComponent as EquipmentSvg } from "../svg/V1/equipment.svg"
import { ReactComponent as GroupsSvg } from "../svg/V3/groups.svg"
import { ReactComponent as PayTypeSvg } from "../svg/V2/PAY-TYPES.svg"
import { ReactComponent as PayTypesSvg } from "../svg/V2/PAY-TYPES-2.svg"
import { ReactComponent as ReasonsSvg } from "../svg/V3/reasons.svg"
import { ReactComponent as SpecialtySvg } from "../svg/V3/swissArmy1.svg"
// import {ReactComponent as TimeSvg} from "../svg/V3/time.svg"
 import {ReactComponent as ShiftsSvg} from "../svg/V3/shifts.svg"
// import {ReactComponent as ApprovalsSvg} from "../svg/V3/approvals.svg"
import { ReactComponent as UserSession } from "../svg/V4/DATABASE.svg"
import { ReactComponent as Monitoring } from "../svg/V4/DASHBOARD.svg"
import {ReactComponent as PicklistSvg } from "../svg/V4/CLIPBOARD-ITEMS.svg"
import { ReactComponent as LocationSvg } from "../svg/V3/navigation.svg"


import { Can } from "../store/psrs"

export const AppNav = props => {
  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">App Navigation</h1>
      </div>

      <div className="view_content">
        <div className="AppNav">
          <section className="svgButtons">
            <React.Fragment>
              <ul>
                <li>
                  <Link data-cy="nav-assignments" to="/admin/assignments">
                    <span className="icon">
                      <AssignmentSvg />
                    </span>
                    <span className="title">assignments</span>
                  </Link>
                </li>
                <li>
                <Can I="view:businessunits">
                    <Link data-cy="nav-businessunits" to="/admin/b_units">
                      <span className="icon">
                        <BusinessUnitSvg />
                      </span>
                      <span className="title">business units</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Link data-cy="nav-employees" to="/admin/employees">
                    <span className="icon">
                      <EmployeesSvg />
                    </span>
                    <span className="title">employees</span>
                  </Link>
                </li>
                <li>
                  <Link data-cy="nav-equipment" to="/admin/equipment">
                    <span className="icon">
                      <EquipmentSvg />
                    </span>
                    <span className="title">equipment</span>
                  </Link>
                </li>
                <li>
                  <Can I="view:groups">
                    <Link data-cy="nav-groups" to="/admin/groups">
                      <span className="icon">
                        <GroupsSvg />
                      </span>
                      <span className="title">groups</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Can I="view:groups">
                    <Link data-cy="nav-groups" to="/admin/locations">
                      <span className="icon">
                        <LocationSvg />
                      </span>
                      <span className="title">locations</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Can I="view:paytypes">
                    <Link data-cy="nav-paytypes" to="/admin/paytypes">
                      <span className="icon">
                        <PayTypeSvg />
                      </span>
                      <span className="title">pay types</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Can I="view:shifttemplates">
                    <Link data-cy="nav-usersessions" to="/admin/picklists">
                      <span className="icon">
                        <PicklistSvg/>
                      </span>
                      <span className="title">Picklists</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Link data-cy="nav-reasons" to="/admin/reasons">
                    <span className="icon">
                      <ReasonsSvg />
                    </span>
                    <span className="title">reasons</span>
                  </Link>
                </li>
                <li>
                  <Link data-cy="nav-specialties" to="/admin/specialties">
                    <span className="icon">
                      <SpecialtySvg />
                    </span>
                    <span className="title">specialties</span>
                  </Link>
                </li>
                <li>
                  <Can I="view:shifttemplates">
                    <Link data-cy="nav-usersessions" to="/admin/shift_templates">
                      <span className="icon">
                        <ShiftsSvg/>
                      </span>
                      <span className="title">Shift Templates</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Can I="view:subledgers">
                    <Link data-cy="nav-subledgers" to="/admin/sub_ledgers">
                      <span className="icon">
                        <PayTypesSvg />
                      </span>
                      <span className="title">sub ledgers</span>
                    </Link>
                  </Can>
                </li>
                <li>
                  <Link data-cy="nav-usersessions" to="/admin/sessions">
                    <span className="icon">
                      <UserSession/>
                    </span>
                    <span className="title">user sessions</span>
                  </Link>
                </li>
                <li>
                  <Link data-cy="nav-monitor" to="/admin/monitor">
                    <span className="icon">
                      <Monitoring />
                    </span>
                    <span className="title">Monitor</span>
                  </Link>
                </li>
              </ul>
            </React.Fragment>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppNav
