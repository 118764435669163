import React, {useState} from 'react'
import { SwatchesPicker } from 'react-color'
import { REQ } from '../../store/psrs'

export const ColorPickButtonF = (props) => {
    
  const model = props.model ? props.model : 'shiftTemplate'   

  const {
        //id,
        //displayColorPicker,
        color,
        category,
        setColor,
        data,
        //shiftJson,
        url,
        mutate,
        //value,
        //closeHandler,
        categoryColor,
    } = props

    const [state, setState] = useState(
         {
            //id: props.id,
            displayColorPicker: false,
            //color: props.color,
            //category: props.category,
            //setColor: props.setColor,
            //data: props.data,
            //shiftJson: props.shiftJson,
            //url: props.url,
            //mutate: props.mutate,
            //value: props.value,
            //categoryColor: props.categoryColor,
        }
    )
    
    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const handleClick = () => {
      setState({ displayColorPicker: !state.displayColorPicker })
      setColor(color)

    };

    const handleClose = (props) => {
   
        const value = props.hex

        if (category) {  
            
            // we need to update all categories when one is changed....
            const updateCategories  = data ? data.filter(element => element.category === category) : []
            
            //shiftJson["categoryColor"] = color
            const field = 'categoryColor'
            //const model = "shiftTemplate"

            //const value = color //.hex
            //const id = shiftJson.id


            if (updateCategories.length > 0 ){
                updateCategories.forEach( async t => {
                    const id = t.id
                    const update = {}
                    update[field] = value                
                    await REQ(`/api/resources/${model}/${id}`, "PATCH", update)
                })
            }
            mutate(url)
            
        }
        setState({ displayColorPicker: false })
    }
    
   // useEffect(() => {
   //     setState({...state})
   // }, [props.data, state])
    
    return (
        <span className="row_actions" style={{textAlign: 'center'}}>
        <button
            className="cat-color-box" 
            style={{textAlign: "center", backgroundColor: categoryColor,  border: categoryColor}}
            onClick={ handleClick }></button>
        { state.displayColorPicker ? <div style={ popover }>
            <div style={ cover } onClick={handleClose }/>
            <SwatchesPicker color={categoryColor} onChange={handleClose } onBlur={handleClose} />
        </div> : null }
        </span>
    )
}

export default ColorPickButtonF